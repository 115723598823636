import moment from "moment";
import {  ShopifyLocationModel, UUIDField } from ".";
import { MomentToTimeStr, TimeStrToMoment } from "../decorators";
import { XBaseModel } from "./base.model";

export class LocationModel extends XBaseModel {
  consumer_uuid!: UUIDField;
  route_uuid!: UUIDField;
  name!: string;
  description?: string;
  @TimeStrToMoment()
  pickup_time!: moment.Moment;
  note?: string;
  shopify_location!: ShopifyLocationModel;
  customer_tag?: string;
}

export class CreateLocationModel {
  name!: string;
  description?:string;
  @MomentToTimeStr()
  pickup_time!: string;
  note?: string;
  customer_tag!: string;
}
export class UpdateLocationModel {
  name?: string;
  description?: string;
  @MomentToTimeStr()
  pickup_time?: string;
  note?: string;
  customer_tag?:string;
}