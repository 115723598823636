import { CalendarOutlined,  ShopOutlined,   CarOutlined, ScheduleOutlined, CopyOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/es/components/Icon';
import { CSSProperties } from 'react';
import { LocationPin } from '../components/svg/LocationPin';
import DeliveryDates from './pages/DeliveryDates';
import Locations from './pages/Locations';
import OrderSchedules from './pages/OrderSchedules';
import Vendors from './pages/Vendors';
import Routes from './pages/Routes';
import Deliveries from './pages/Deliveries';
import Discrepancies from './pages/Discrepancies';
import OrderScheduleProducts from './pages/OrderScheduleProducts';
import Reports from './pages/Reports';
import { UserType } from '../models';


export const RouteBasePath = {
  Schedules: 'Schedules',
  Dates: 'Dates',
  Vendors: 'Vendors',
  Locations: 'Locations',
  Routes: 'Routes',
  Deliveries: 'Deliveries',
  Discrepancies: 'Discrepancies',
  Reports: 'Reports',
  // Notifications: 'Notifications',
} as const

export type RouteBasePathKeys = keyof typeof RouteBasePath & string;
export type IRouteBasePaths = {
  [name in RouteBasePathKeys]: {
    path: string,
    icon: JSX.Element,
    label: string,
    component: JSX.Element,
    allowedForUsers: UserType[],
    exact?: boolean,
    resourceId?:string,    
    children?: { path: string; component: JSX.Element }[]
  }
}

const defaultStyle:CSSProperties = {
  fontSize: '22px',
  width: '30px',
  height: '30px',
  padding: '5px 0',
}


export const RouteBasePaths:IRouteBasePaths = {
  [RouteBasePath.Schedules]: {
    path: '/schedules',
    icon: <ScheduleOutlined style={defaultStyle} />,
    component: <OrderSchedules />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Schedule',
    exact: false,
    resourceId: ':orderScheduleId',
    children: [
      {
        path: 'variants',
        component: <OrderScheduleProducts />,
      }
    ]
  },
  [RouteBasePath.Dates]: {
    path: '/dates',
    icon: <CalendarOutlined style={defaultStyle} />,
    component: <DeliveryDates />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Dates',
    exact: false,
  },
  [RouteBasePath.Vendors]: {
    path: '/vendors',
    icon: <ShopOutlined style={defaultStyle} />,
    component: <Vendors />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Vendors',
  },
  [RouteBasePath.Locations]: {
    path: '/locations',
    icon: <Icon component={LocationPin} style={defaultStyle} />,
    component: <Locations />, // <LazyLocationsPage />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Locations',
  },
  [RouteBasePath.Routes]: {
    path: '/routes',
    icon: <CarOutlined style={defaultStyle} />,
    component: <Routes />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Routes',
  },
  [RouteBasePath.Deliveries]: {
    path: '/deliveries',
    icon: <CalendarOutlined style={defaultStyle} />,
    component: <Deliveries />,
    allowedForUsers: [UserType.ADMIN, UserType.DRIVER],
    label: 'Deliveries',
  }, 
  [RouteBasePath.Discrepancies]: {
    path: '/discrepancies',
    icon: <CalendarOutlined style={defaultStyle} />,
    component: <Discrepancies />,
    allowedForUsers: [UserType.ADMIN, UserType.DRIVER],
    label: 'Discrepancies',
  }, 
  [RouteBasePath.Reports]: {
    path: '/reports',
    icon: <CopyOutlined style={defaultStyle} />,
    component: <Reports />,
    allowedForUsers: [UserType.ADMIN],
    label: 'Reports',
  },      
}
