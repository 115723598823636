import { useState, useCallback } from 'react';

export type PickOne<T> = { [P in keyof T]: Record<P, T[P]> & Partial<Record<Exclude<keyof T, P>, undefined>> }[keyof T];

export type UseStateEnhancedManagement<T> = [
  T,
  {
    setState: React.Dispatch<React.SetStateAction<T>>;
    setProperty: (property: PickOne<T>) => void;
    setProperties: (properties: Partial<T>) => void;
  },
];

export const useStateEnhanced = <T>(initialState: T): UseStateEnhancedManagement<T> => {
  const [state, setState] = useState<T>(initialState);

  const setProperty = useCallback((property: PickOne<T>) => setState((prevState) => ({ ...prevState, ...property })), []);

  const setProperties = useCallback((properties: Partial<T>) => setState((prevState) => ({ ...prevState, ...properties })), []);

  return [state, { setState, setProperty, setProperties }];
};
