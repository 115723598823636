import { useContext, useEffect, useState } from "react";
import { LocationModel } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import { SupportedRequestQueryParams } from "../../services/api/api.types";

export interface UseLocationsProps {
  load?: SupportedRequestQueryParams<LocationModel>["GetMany"]
}
export const useLocations = (props?: UseLocationsProps) => {
  const { api } = useContext(AppContext);
  const [data,setData] = useState<LocationModel[]>([]);
  const [isLoading,setIsLoading] = useState(Boolean(props?.load));

  const loadData = async (requestParams: SupportedRequestQueryParams<LocationModel>["GetMany"]) => {
    const response = await api.locations.getMany(requestParams);
    setIsLoading(false);
    setData(response.data)
  }

  useEffect(() => {
    if (!props?.load) return;
    
    // Preload some vendors.
    loadData(props.load)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return {
    isLoading,
    data,
  }
};
