import { useContext, useEffect, useState } from "react";
import { DeliveryItemModel, UpdateDeliveryItemModel, DeliveryModel, LocationModel, DiscrepancyModel, DeliveryItemStatus, DiscrepancyStatus, DiscrepancyOrderStatus } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import { useStateEnhanced } from './enhanced-state.hook';
import { plainToInstance } from "class-transformer";
import { SupportedRequestQueryParams, QueryFilter } from "../../services/api/api.types";
import { message } from "antd";
import moment from 'moment';

export interface UseDiscrepaciesProps {  
  load?: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"]
}

export interface IDiscrepaciesState {  
  deliveriesOptions?: DeliveryModel[];
  orderStatusOptions?: string[];
  unresolvedDiscrepancyItems: DiscrepancyModel[];
  resolvedDiscrepancyItems: DiscrepancyModel[];
  locationOptions?: LocationModel[];
  customerOptions?: string[];

  updateDrawerItem: DeliveryItemModel;
  updateDrawerDiscrepancy?: DiscrepancyModel;

  isLoadingFilters?: boolean;
  isLoadingTable?: boolean;
  isSavingItems?: boolean;  

  isUpdateDrawerOpen?: boolean;
  markDrawerIssueClosed?: boolean;
  emailCustomerDiscrepancy?: boolean;
  emailCustomerNote?: string;

  openTab?: DiscrepancyStatus;
  activeDelivery?: string;
  customerFilterDelivery?: string;
  activeLocation?: string;
  activeCustomer?: string;  
  activeDeliveryItemStatus?: DeliveryItemStatus;
}

export type DiscrepanciesAction =
  | { type: 'set-open-tab'; payload: { tab: DiscrepancyStatus } }
  | { type: 'set-active-delivery'; payload: { delivery: string } }
  | { type: 'set-active-location'; payload: { location: string } }
  | { type: 'set-active-customer'; payload: { customer: string } }
  | { type: 'set-active-delivery-item-status'; payload: { status: DeliveryItemStatus } }
  | { type: "set-close-drawer-issue", payload: { close: boolean } }
  | { type: "set-email-customer", payload: { close: boolean } }
  | { type: "set-email-customer-note", payload: { note: string } }
  | { type: 'open-update-drawer'; payload: { item: DiscrepancyModel } }
  | { type: 'set-drawer-item', payload: { item: DeliveryItemModel } }
  | { type: 'set-new-drawer-delivery', payload: { item: string } }
  | { type: 'save-drawer-item', payload: {} }
  | { type: 'close-update-drawer', payload: {} };

export const useDiscrepencies = () => {
  const { api } = useContext(AppContext);

  const [state, { setProperty, setProperties }] = useStateEnhanced<IDiscrepaciesState>({
    openTab: DiscrepancyStatus.UNRESOLVED,
    updateDrawerItem: {} as DeliveryItemModel,
    unresolvedDiscrepancyItems: [],
    resolvedDiscrepancyItems: [],
  });

  const setOpenTab = (tab: DiscrepancyStatus) => {
    setProperty({ openTab: tab });  
  }

  const setActiveDelivery = (delivery: string) => {
    setProperty({ activeDelivery: delivery });    
  }

  const setActiveLocation = (location: string) => {
    setProperty({ activeLocation: location });
  }

  const setActiveCustomer = (customer: string) => {
    setProperty({ activeCustomer: customer });
  }

  const setActiveDeliveryItemStatus = (deliveryItemStatus: DeliveryItemStatus) => {
    setProperty({ activeDeliveryItemStatus: deliveryItemStatus });
  } 

  const setDrawerItem = (item: DeliveryItemModel) => {
    setProperty({ updateDrawerItem: item });
  }

  const setCloseDrawerIssue = (close: boolean) => {
    setProperty({ markDrawerIssueClosed: close });
  }

  const setEmailCustomer = (send_email: boolean) => {
    setProperty({ emailCustomerDiscrepancy: send_email });
  } 

  const setEmailCustomerNote = (note: string) => {
    setProperty({ emailCustomerNote: note });
  }   

  const openUpdateDrawer = (item: DiscrepancyModel) => {    
    setProperties({ isUpdateDrawerOpen: true, updateDrawerItem: item.delivery_item, updateDrawerDiscrepancy: item, markDrawerIssueClosed: false, emailCustomerDiscrepancy: false, emailCustomerNote: "" });  
  }  

  const closeUpdateDrawer = () => {
    setProperty({ isUpdateDrawerOpen: false });  
  }   

  const saveDrawerItem = async () => {
    setProperty({ isSavingItems: true });

    const updatedDiscrepancy = state.updateDrawerDiscrepancy;
    if(updatedDiscrepancy){
      updatedDiscrepancy.delivery_item = state.updateDrawerItem;
      if(state.markDrawerIssueClosed == true){
        updatedDiscrepancy.issue_status = DiscrepancyStatus.RESOLVED;
      }
      setProperty({ updateDrawerDiscrepancy: updatedDiscrepancy });

      const updatedDeliveryItem = {
        delivery: state.updateDrawerItem?.delivery.uuid,
        short: state.updateDrawerItem?.short,
        delivered: state.updateDrawerItem?.delivered,
        left_at_location: state.updateDrawerItem?.left_at_location,
        no_show: state.updateDrawerItem?.no_show,
        back_order: state.updateDrawerItem?.back_order,
        notes: state.updateDrawerItem?.notes,
        rescheduled: state.updateDrawerItem?.rescheduled
      } as UpdateDeliveryItemModel;      

      try{
        await api.deliveryItems.updateOne(state.updateDrawerItem.uuid, updatedDeliveryItem);   
      }catch(e){
        console.log("failed to update drawer delivery item");
        setProperty({ isSavingItems: false });
        return; 
      }  

      try{
        updatedDiscrepancy.email_customer = state.emailCustomerDiscrepancy;
        updatedDiscrepancy.email_customer_email = state.updateDrawerItem.shopify_order.email;
        updatedDiscrepancy.email_customer_note = state.emailCustomerNote;
        updatedDiscrepancy.email_customer_name = state.updateDrawerItem.shopify_order.shopify_customer_name;
        await api.discrepancies.updateOne(updatedDiscrepancy.uuid, updatedDiscrepancy);  
      }catch(e){
        console.log("failed to update drawer item");
      }  

      setProperties({ isUpdateDrawerOpen: false, isSavingItems: false });

      loadDiscrepancyItems();

    }
  }

  const loadDiscrepancyItems = async () => {
    setProperty({ isLoadingTable: true });

    //build item request based on 'active' state properties

    let tableItems: DiscrepancyModel[] = []; 
    let filteredItems: DiscrepancyModel[] = [];

    let requestFilters: QueryFilter<DiscrepancyModel>[] = [];

    const requestParams: SupportedRequestQueryParams<DiscrepancyModel>["GetMany"] = {
      page: 1,
      limit: 25,
      filter: requestFilters,
    };    

    const response = await api.discrepancies.getMany(requestParams);
    tableItems = response.data;

    if(state.activeDelivery){
      filteredItems = tableItems.filter((item) => {
        if(item.delivery_item.delivery.uuid === state.activeDelivery){
          return item;
        }
      });
      tableItems = filteredItems;
    }

    if(state.activeLocation){
      filteredItems = tableItems.filter((item) => {
        if(item.delivery_item.location.uuid === state.activeLocation){
          return item;
        }
      });
      tableItems = filteredItems;
    }        

    if(state.activeCustomer){
      filteredItems = tableItems.filter((item) => {
        if(item.delivery_item.shopify_order.shopify_customer_name === state.activeCustomer){
          return item;
        }
      });
      tableItems = filteredItems;
    }   

    if(state.activeDeliveryItemStatus){
      filteredItems = tableItems.filter((item) => {
        if(item.delivery_item.status === state.activeDeliveryItemStatus){
          return item;
        }
      });
      tableItems = filteredItems;
    }    

    const unresolvedItems = tableItems.filter((item) => {
      if(item.issue_status === DiscrepancyStatus.UNRESOLVED){
        return item;
      }
    }); 

    const resolvedItems = tableItems.filter((item) => {
      if(item.issue_status === DiscrepancyStatus.RESOLVED){
        return item;
      }
    });        


    setProperties({ isLoadingTable: false, isLoadingFilters: false, unresolvedDiscrepancyItems: unresolvedItems, resolvedDiscrepancyItems: resolvedItems });
  }  

  const loadLocationFilter = async () => {

    // load distinct locations for given delivery id
    setProperty({ isLoadingFilters: true });

    const selectedDeliveryRoute = state?.deliveriesOptions?.find((delivery) => {
      if(delivery.uuid === state.activeDelivery){
        return delivery;
      }
    }, null);

    if(!selectedDeliveryRoute){
      console.log("Unable to find active delivery");
      setProperties({ isLoadingFilters: false });
      return;
    }

    const requestParams: SupportedRequestQueryParams<LocationModel>["GetMany"] = {
      page: 1,
      limit: 25,
      filter: [{
        field: "route_uuid",
        operator: "$eq",
        value: selectedDeliveryRoute.route.uuid,
      }],
    };  

    const response = await api.locations.getMany(requestParams);

    setProperties({ isLoadingFilters: false, locationOptions: response.data });
  }

  const loadCustomerFilter = async (newDelivery?: boolean) => {

    if(!state.activeDelivery){
      setProperties({ customerOptions: [], activeCustomer: undefined });
    }

    setProperty({ isLoadingFilters: true });

    const allItems = [...state.unresolvedDiscrepancyItems, ...state.resolvedDiscrepancyItems];

    const customerNames = allItems.map((item) => {
      return item?.delivery_item.shopify_order?.shopify_customer_name;
    });

    const uniqueCustomers = customerNames.filter((v, i, a) => a.indexOf(v) === i);

    if(!uniqueCustomers){
      setProperties({ isLoadingFilters: false, customerOptions: [], activeCustomer: undefined });
      return;
    }  

    setProperties({ isLoadingFilters: false, customerOptions: uniqueCustomers });

  }     

  const loadDeliveries = async (requestParams: SupportedRequestQueryParams<DeliveryModel>["GetMany"]) => {
    //load deliveries for 60 days either side of today
    setProperty({ isLoadingFilters: true });
    const response = await api.deliveryDates.getMany(requestParams);
    const sortedItems = response.data.sort((a: DeliveryModel, b: DeliveryModel) => {
      const dateA = moment(a.delivery_date);
      const dateB = moment(b.delivery_date);
      if (moment(a.delivery_date).isBefore(b.delivery_date)) {
        return -1;
      }
      if (moment(a.delivery_date).isAfter(b.delivery_date)) {
        return 1;
      }
      return 0;    
    });

    //iterate through dates
    let today = moment();
    let latest = moment().subtract(60, 'days');
    let closestDateIndex = 0;
    sortedItems.forEach((item, index) => {
      const itemDeliveryDate = moment(item.delivery_date);
      if(moment(today).isAfter(itemDeliveryDate) && moment(latest).isBefore(itemDeliveryDate)){
        closestDateIndex = index;
      }
    })

    setProperties({ isLoadingFilters: false, deliveriesOptions: sortedItems });
  }    

  const dispatch = (action: DiscrepanciesAction) => {
    switch (action.type) {
      case 'set-open-tab':
        return setOpenTab(action.payload.tab);
      case 'set-active-delivery':
        return setActiveDelivery(action.payload.delivery); 
      case 'set-active-location':
        return setActiveLocation(action.payload.location);
      case 'set-active-customer':
        return setActiveCustomer(action.payload.customer); 
      case 'set-active-delivery-item-status':
        return setActiveDeliveryItemStatus(action.payload.status);
      case 'set-email-customer':
        return setEmailCustomer(action.payload.close);
      case 'set-email-customer-note':
        return setEmailCustomerNote(action.payload.note);          
      case 'set-close-drawer-issue':
        return setCloseDrawerIssue(action.payload.close);
      case 'open-update-drawer':
        return openUpdateDrawer(action.payload.item);
      case "set-drawer-item":
        return setDrawerItem(action.payload.item);
      case "save-drawer-item":
        return saveDrawerItem();        
      case "close-update-drawer":
        return closeUpdateDrawer();
    }
  };  

  //initial render only
  useEffect(() => {

    loadDeliveries({
      filter: [
        {
          field: "delivery_date",
          operator: "$lte",
          value: moment().add(60, 'days').format("YYYY-MM-DD"),
        },
        {
          field: "delivery_date",
          operator: "$gte",
          value: moment().subtract(60, 'days').format("YYYY-MM-DD"),
        }        
      ],      
    }); 

    loadDiscrepancyItems();
    loadLocationFilter();
    loadCustomerFilter();    

  }, []);

  useEffect(() => {

    loadDiscrepancyItems();
    loadLocationFilter();
    loadCustomerFilter();

  }, [state.activeDelivery, state.activeCustomer, state.activeLocation, state.activeDeliveryItemStatus]);  

  return {
    state,
    dispatch,
  }
};
