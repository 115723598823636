import React from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Button, Input, Space, TableColumnsType } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { TableRowData } from "../../utils";
//
// TODO: KEEP CLEANING THIS
//

export interface ColumnSearchOptions {
  searchInput: React.RefObject<Input>;
  setSearchValue: (
    dataIndex: string,
    selectedKeys: React.Key[] | null,
    done?: () => void
  ) => void;
  clearSearchValue: (dataIndex: string, done?: () => void) => void;
  searchedColumns: { [columnKey: string]: string };
}

// export type GetColumnSearchProps = <T>(dataIndex:string, options: ColumnSearchOptions) =>
export type ColumnSearchProps<TEntity> = Pick<
  TableColumnsType<TableRowData<TEntity>>[0],
  | "filterDropdown"
  | "filterIcon"
  | "onFilter"
  | "onFilterDropdownVisibleChange"
  | "render"
>;
export type GetColumnSearchProps<TEntity> = (
  dataIndex: string,
  options: ColumnSearchOptions
) => ColumnSearchProps<TEntity>;

export function getColumnSearchProps<TEntity>(
  dataIndex: string,
  {
    searchInput,
    setSearchValue,
    clearSearchValue,
    searchedColumns,
  }: ColumnSearchOptions
): ColumnSearchProps<TEntity> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>{
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            // setSearchValue(dataIndex, e.target.value ? [e.target.value] : []);
          }
          }
          onPressEnter={() => setSearchValue(dataIndex, selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => setSearchValue(dataIndex, selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearSearchValue(dataIndex, () => {
              if(clearFilters){
                clearFilters();
              }
              setSearchValue(dataIndex,null, confirm);
            })}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#b1816e" : undefined }} />
    ),
    onFilter: (value, record) => {
      /** Filter on the server */
      return true;
    },
    onFilterDropdownVisibleChange: (visible) => {
      /**
       * Draw focus to open search input
       */
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  };
}


