import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { ClassConstructor } from "class-transformer";
import { APIConfig, APICreateModelTypes, APIModelTypes, APIUpdateModelTypes, PathKeys } from "./api.config";
import { GetManyResponse, SupportedRequestQueryParams } from "./api.types";
import { AuthService } from "./auth.service";
import { getManyBodyParser, requestHandler } from "./response-handler";
import { switchMap, tap, map, catchError, of,filter, defer, from } from 'rxjs';
import { fromFetch } from 'rxjs/fetch'
import { downloadThroughAnchorLink, getFileNameFromResponseHeader } from "../../utils/file.util";

export class CrudApi<ModelType extends APIModelTypes, CreateModelType extends APICreateModelTypes, UpdateModelType extends APIUpdateModelTypes> {
  pathKey: PathKeys;
  ModelClass: ClassConstructor<ModelType>;

  constructor(
    public readonly config: APIConfig,
    public readonly auth: AuthService,
    pathKey: PathKeys
  ) {
    this.config = config;
    this.auth = auth;
    this.pathKey = pathKey;
    const ModelClass = this.config.endpoints[this.pathKey].model
    if(!ModelClass){
      throw new Error(`API config incomplete for ${pathKey}`);
    }
    this.ModelClass = ModelClass as unknown as ClassConstructor<ModelType>;
  }

  importDataEnvelope = (requestURL?:string) => {
    requestURL = requestURL ? requestURL : `${this.config.endpoints[this.pathKey].path}/import`;
    const { authorization } = this.auth.authHeader()
    return {
      name: 'file',
      action: requestURL,
      headers: {
        authorization,
      },
    }
  }

  downloadExport = (requestURL?:string, filename?: string) => {
    requestURL = requestURL ? requestURL : `${this.config.endpoints[this.pathKey].path}/export`;
    const requestOptions: RequestInit = {
      headers: {
        ...this.auth.authHeader(),
      },
      method: "GET",
    };
    const downloadableFilename = `${filename ?? "export"}.csv`;
    const response$ = fromFetch(requestURL, requestOptions).pipe(
      switchMap(response => {
        if (response.ok) {
          const contentDisposition = response.headers.get('Content-Disposition');
          const fileName = getFileNameFromResponseHeader(contentDisposition) as string;
          const fileType = response.headers.get('Content-Type');
          return defer(() => from(response.blob())).pipe(
            map(blob => new File([blob], fileName, { type: fileType ?? undefined }))
          )
        } else {
          // Server is returning a status requiring the client to try something else.
          return of({ error: true, message: `Error ${response.status}` });
        }
      }),
      catchError(err => {
        // Network or other error, handle appropriately
        console.error(err);
        return of({ error: true, message: err.message })
      }),
     ).pipe(
      filter(data => !('error' in data)),
      map(data => {
        if(('error' in data)) return
        
        const url = window.URL.createObjectURL(data)
        return {url, data};
      }
      ),
      tap((_data) => {
        if(!_data) return;
        const {url, data} = _data;
        downloadThroughAnchorLink(url, downloadableFilename)
        window.URL.revokeObjectURL(url);
      }),
     )
      
     return response$;
  }

  async createOne(request: CreateModelType): Promise<ModelType> {
    const body = JSON.stringify(request);
    const requestURL = this.config.endpoints[this.pathKey].path;

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "POST",
      body: body,
    };
    const data = await requestHandler(requestURL, requestOptions);

    return data;
  }

  async updateOne(
    id: string | number,
    request: UpdateModelType
  ): Promise<ModelType> {
    const body = JSON.stringify(request);
    const requestURL = `${this.config.endpoints[this.pathKey].path}/${id}`;

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "PATCH",
      body: body,
    };
    const data = await requestHandler(requestURL, requestOptions);

    return data;
  }

  async getAllPages(
    queryParams: SupportedRequestQueryParams<ModelType>["GetMany"],
  ): Promise<ModelType[]> {
    const { page:_page,...restOfQuery } = queryParams
    let page = _page ?? 1;
    let totalPages = -1;
    let responses: ModelType[] = [];
    do {
          const response = await this.getMany({ page, ...restOfQuery  });
          totalPages = response.pageCount
          responses = [
            ...responses,
            ...response.data
          ]
          page += 1;
      } while(page < totalPages);

    return responses;
  }
  
  async getMany(
    queryParams?: SupportedRequestQueryParams<ModelType>["GetMany"],
  ): Promise<GetManyResponse<ModelType>> {
    const query = RequestQueryBuilder.create(queryParams).query();
    const requestURL =
      this.config.endpoints[this.pathKey].path + (query ? `?${query}` : "");
    
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "GET",
    };

    const rawData = await requestHandler(requestURL, requestOptions);
    const parsedData = getManyBodyParser(rawData, this.ModelClass);
    return parsedData;
  }

  async getOne(id: string): Promise<ModelType> {
    const requestURL = this.config.endpoints[this.pathKey].path + `/${id}`;
    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "GET",
    };

    const data = await requestHandler(requestURL, requestOptions);

    return data;
  }

  async deleteOne(id: string): Promise<ModelType> {
    const requestURL = this.config.endpoints[this.pathKey].path + `/${id}`;

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "DELETE",
    };

    const data = await requestHandler(requestURL, requestOptions);

    return data;
  }
}
