export interface AddressCellProps {
  address1?:string;
  address2?:string;
  city?:string;
  province_code?:string;
  zip?:string;
}
export const AddressCell = ({address1, address2, city, province_code, zip }: AddressCellProps) => {
    const address1And2 = [address1 ?? '',address2].filter(Boolean).join(',')
    const cityStateZip = [city ?? '', province_code ?? '', zip ?? '' ].join(' ')
    return (
      <span>
        {address1And2} {cityStateZip}
      </span>
    )
  }