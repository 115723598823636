import {
  useContext,
  useEffect,
  useReducer,
} from "react";
import { OrderScheduleModel } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import { SupportedRequestQueryParams } from "../../services/api/api.types";
import {
  APIHookActions,
  APIHookProps,
  APIHookState,
  apiReducer,
} from "./hook.types";
import { API } from "../../services/api/api";

export type GetOrderScheduleParams =
  SupportedRequestQueryParams<OrderScheduleModel>["GetMany"];

export interface OrderScheduleProps extends APIHookProps<OrderScheduleModel> {
  queryParams?: GetOrderScheduleParams;
  shouldLoad?: boolean;
}
export interface IOrderScheduleState {
  api: APIHookState<OrderScheduleModel>;
  filterData: { text: string; value: string; key: string }[];
  singleOrderSchedule?: {
    item: OrderScheduleModel;
    vendorName: string;
    routeName: string;
    deliveryDate: string;
    dateOpen: string;
    dateClose: string;
    deliveryRouteString: string;
    dateRangeString: string;
  };
}

export type OrderScheduleActions = APIHookActions<OrderScheduleModel>;

const reducer = (
  state: IOrderScheduleState,
  action: OrderScheduleActions
): IOrderScheduleState => {
  const { api: apiState, ...restOfState } = state;
  const nextState = {
    ...restOfState,
    api: apiReducer<OrderScheduleModel>(
      apiState,
      action as APIHookActions<OrderScheduleModel>
    ),
  };

  switch (action.type) {
    case "set-raw-response-data":
      const openDateSet = new Set<string>();
      const closeDateSet = new Set<string>();
      (nextState.api?.rawResponseData?.data ?? []).forEach(
        ({ date_open, date_close }) => {
          const dateOpenFormatted = date_open.format();
          const dateCloseFormatted = date_close.format();
          openDateSet.add(dateOpenFormatted);
          closeDateSet.add(dateCloseFormatted);
        }
      );

      if (nextState.api.rawResponseData?.data?.length === 1) {
        const item = nextState.api.rawResponseData?.data[0];
        const routeName = item?.route?.name;
        const deliveryDate = item?.delivery?.delivery_date_formatted ?? "TBD";
        const dateOpen = item?.date_open?.format("MM-DD-YYYY");
        const dateClose = item?.date_close?.format("MM-DD-YYYY");
        nextState.singleOrderSchedule = {
          item,
          vendorName: item?.vendor?.name,
          routeName,
          deliveryDate,
          dateOpen,
          dateClose,
          dateRangeString: [dateOpen, dateClose].join(" - "),
          deliveryRouteString: [routeName, deliveryDate].join(" "),
        };
      }

      break;
  }
  return nextState;
};

const loadData = async (
  api: API,
  dispatch: React.Dispatch<OrderScheduleActions>,
  requestParams?: GetOrderScheduleParams
) => {
  dispatch({ type: "lock-loading" });

  const response = await api.orderSchedules.getMany(requestParams);

  dispatch({ type: "set-raw-response-data", payload: response });
};

const exportData = (
  api: API,
  dispatch: React.Dispatch<OrderScheduleActions>
) => {
  dispatch({ type: "lock-export" });

  api.orderSchedules.downloadExport(undefined, "order-schedule-export").subscribe({
    next: (result) => {
      // Do something if needed
    },
    complete: () => dispatch({ type: "unlock-export" }),
  });
};

export const useOrderSchedules = (props?: OrderScheduleProps) => {
  const { api } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, {
    api: {
      queryParams: props?.queryParams,
      shouldLoadData: props?.shouldLoad,
      importParams: api.orderSchedules.importDataEnvelope(),
    },
    filterData: [],
  });
  const {
    api: { isLoading, queryParams, shouldExport,shouldLoadData, isExporting },
  } = state;

  /**
   * React to exports
   */
  useEffect(() => {
    if (!shouldExport || isExporting) {
      return;
    }
    exportData(api, dispatch);
  }, [api, isExporting, shouldExport]);

  /**
   * React to changing query params
   */
  useEffect(() => {
    if (!shouldLoadData || isLoading) {
      return;
    }
    loadData(api, dispatch, queryParams);
  }, [api, isLoading, shouldLoadData, queryParams]);

  return [state, dispatch] as const;
};
