import { Button, ButtonProps } from "antd"

export interface ActionProps extends ButtonProps {

}

export const Action = (props:ActionProps) => {
  return <Button
    {...{
      type:"link",
      size: "large",
    }}
    {...props}
    className={`action base-action ${props.className}`}
  />
}

export const PageAction = (props:ActionProps) => {
  return <Button
    {...{
      type: "primary",
      size: "large",
    }}
    {...props}
    className={`action page-action ${props.className}`}
  />
}
