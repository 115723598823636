import { useContext, useEffect, useReducer } from "react";
import { SupportedRequestQueryParams } from "../../services/api/api.types";
import { ShopifyProductVariantModel } from "../../models";
import { API } from "../../services/api/api";
import { AppContext } from "../hoc/app.hoc";
import {
  APIHookActions,
  APIHookProps,
  APIHookState,
  apiReducer,
} from "./hook.types";

export type GetVariantParams =
  SupportedRequestQueryParams<ShopifyProductVariantModel>["GetMany"];

export interface VariantsProps
  extends APIHookProps<ShopifyProductVariantModel> {
  queryParams?: GetVariantParams;
  shouldLoad?: boolean;
  orderScheduleUUID?:string;
}
export interface IVariantState {
  api: APIHookState<ShopifyProductVariantModel>;
}

export type VariantActions = APIHookActions<ShopifyProductVariantModel>;

const reducer = (
  state: IVariantState,
  action: VariantActions
): IVariantState => {
  const { api: apiState, ...restOfState } = state;
  const nextState = {
    ...restOfState,
    api: apiReducer<ShopifyProductVariantModel>(
      apiState,
      action as APIHookActions<ShopifyProductVariantModel>
    ),
  };

  switch (action.type) {
    case "set-raw-response-data":
      break;
  }
  return nextState;
};

const exportData = (api: API, dispatch: React.Dispatch<VariantActions>, orderScheduleUUID:string) => {
  dispatch({ type: "lock-export" });

  api.orderSchedules.downloadVariantsDataExport(orderScheduleUUID).subscribe({
    next: (result) => {
      // Do something if needed
    },
    complete: () => dispatch({ type: "unlock-export" }),
  });
};

const loadData = async (
  api: API,
  dispatch: React.Dispatch<VariantActions>,
  requestParams?: GetVariantParams
) => {
  dispatch({ type: "lock-loading" });

  const response = await api.variants.getMany(requestParams);

  dispatch({ type: "set-raw-response-data", payload: response });
};

export const useVariants = (props?: VariantsProps) => {
  const { api } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, {
    api: {
      queryParams: props?.queryParams,
      shouldLoadData: props?.shouldLoad,
    },
  });

  const {
    api: { isLoading, shouldLoadData, queryParams, shouldExport, isExporting },
  } = state;
  
  /**
   * Sets import params based on order schedule id
   */
  useEffect(() => {
    const importParams = props?.orderScheduleUUID ? api.orderSchedules.importVariantsDataEnvelope(props?.orderScheduleUUID) : undefined;
    dispatch({ type: 'set-import-params', payload: importParams })
  }, [api.orderSchedules, props?.orderScheduleUUID])

  /**
   * React to exports
   */
  useEffect(() => {
    if (!shouldExport || isExporting || !props?.orderScheduleUUID) {
      return;
    }
    exportData(api, dispatch, props.orderScheduleUUID );
  }, [api, isExporting, shouldExport, props?.orderScheduleUUID]);

  /**
   * React to changing query params
   */
  useEffect(() => {
    if (!shouldLoadData || isLoading) {
      return;
    }
    loadData(api, dispatch, queryParams);
  }, [api, isLoading, shouldLoadData, queryParams]);

  return [state, dispatch] as const;
};
