export const LocationPin = () => (
  <svg
    className="custom-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
  >
    <path
      id="location-icon"
      d="M827.63,735.891a5.9,5.9,0,1,1,11.794,0c0,3.931-5.9,9.828-5.9,9.828s-5.9-5.9-5.9-9.828m3.44,0a2.457,2.457,0,1,0,2.457-2.457A2.457,2.457,0,0,0,831.07,735.891Z"
      transform="translate(-819.00 -729.344)"
      fill="none"
      stroke="#555"
      strokeWidth="1.8"
    />
  </svg>
);
