import { Upload } from "antd";
import { plainToInstance } from "class-transformer";
import { useContext } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Action } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageFields } from "../../components/PageFields";
import { PageTitle } from "../../components/PageTitle";
import {
  OrderScheduleShopifyProductVariantModel,
  UpdateOrderScheduleShopifyProductVariantModel,
} from "../../models/order-schedule-product-variants.model";
import { AppContext } from "../hoc/app.hoc";
import { useEditableTable } from "../hooks/editable-table.hook";
import { useOrderSchedules } from "../hooks/order-schedules.hook";
import { PageTemplate } from "./PageTemplate";
import { useVariants } from "../hooks/variants.hook";
import { onUpload } from "../../components/utils/upload-handler";

const OrderScheduleProducts = () => {
  const { api } = useContext(AppContext);
  const { orderScheduleId } = useParams<{ orderScheduleId: string }>();

  const [orderScheduleState] = useOrderSchedules({
    shouldLoad: true,
    queryParams: {
      filter: {
        field: "uuid",
        operator: "$eq",
        value: orderScheduleId,
      },
    },
  });

  const [variantsState, variantsDispatch] = useVariants({
    orderScheduleUUID: orderScheduleId,
  });

  const editableTableCtx = useEditableTable({
    rowKeyValueField: "shopify_id",
    createEmptyRecord: () => {
      const record = new OrderScheduleShopifyProductVariantModel();
      return record;
    },
    defaultQuery: {
      page: 1,
      limit: 10,
      sort: [{ field: "sku", order: "DESC" }],
    },
    loadRecords: async ({ page, limit, sort, filter }) => {
      const response = await api.orderSchedules.getVariants(orderScheduleId, {
        page,
        limit,
        sort,
        filter,
      });
      return response;
    },
    createRecord: async (record, tableRow) => {
      console.log("Not implemented");
    },
    updateRecord: async (record, tableRow) => {
      const data = plainToInstance(
        UpdateOrderScheduleShopifyProductVariantModel,
        record
      );
      return api.orderSchedules.updateVariant(
        orderScheduleId,
        tableRow.shopify_id,
        data
      );
    },
    deleteRecord: async (record, tableRow) => {
      console.log("Not implemented");
    },
    isEditable: true,
    isSelectable: true,

    columns: [
      {
        title: "SKU",
        key: "sku",
        dataIndex: "sku",
      },
      {
        title: "Product Name",
        dataIndex: "shopify_product.title",
        key: "shopify_product.title",
        render: (v, r) => r?.shopify_product?.title,
      },
      {
        title: "Variant",
        key: "title",
        dataIndex: "title",
      },
      {
        title: "Availability",
        key: "is_unavailable",
        dataIndex: "is_unavailable",
        render: (v, r) => (r.is_unavailable ? "Unavailable" : "Available"),
        editable: true,
        inputType: "select",
        select: {
          options: [
            { label: "Unavailable", value: true },
            { label: "Available", value: false },
          ],
          fieldNames: {
            label: "label",
            value: "value",
          },
        },
      },
    ],
  });
  const { dispatch } = editableTableCtx;
  const {
    api: { isLoading },
    singleOrderSchedule,
  } = orderScheduleState;
  const { vendorName, deliveryRouteString, dateRangeString } =
    singleOrderSchedule ?? {};

  const {
    api: { isExporting, importParams },
  } = variantsState;

  return (
    <PageTemplate>
      {{
        header: {
          breadCrumbs: <Link to={"/schedules/"}>Back to Dates</Link>,
          additional: (
            <PageFields>
              {[
                { label: "Vendor", text: vendorName },
                { label: "Delivery Route", text: deliveryRouteString },
                { label: "Date Range", text: dateRangeString },
              ]}
            </PageFields>
          ),
          actions: [
            <Upload
              disabled={isExporting}
              {...importParams}
              showUploadList={false}
              onChange={(info) =>
                onUpload(info, () => dispatch({ type: "load-data" }))
              }
            >
              <Action>Import</Action>
            </Upload>,
            <Action
              loading={isExporting}
              onClick={() => variantsDispatch({ type: "load-export" })}
            >
              Export
            </Action>,
          ],
        },
        title: <PageTitle>Products</PageTitle>,
        content: (
          <EditableTable
            ctx={editableTableCtx}
            isLoading={isLoading || isExporting}
          />
        ),
      }}
    </PageTemplate>
  );
};
export default OrderScheduleProducts;
