import { Button, Input, message, Form, Modal } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React, { Component, useCallback, useContext, useEffect, useState } from 'react';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { Redirect, RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import { AppContext, useAppHook } from '../hoc/app.hoc';
import { useStateEnhanced } from '../hooks/enhanced-state.hook';
import { IAppProps } from "../App";

export interface ILoginPageState {
  isLoading: boolean;
}

interface LoginFormFields {
  identifier: string;
  password: string;
}

export const LoginPage = () => {
  const appCtx = useContext(AppContext);

  const onFinish = useCallback(
    async (values: LoginFormFields) => {
      const consumer = await appCtx.login(values.identifier, values.password);
      if (consumer) {
        return;
      }
      message.error('Unable to authenticate. Please try again.');
    },
    [],
  );

  const onFinishFailed = useCallback((errorInfo: ValidateErrorEntity<LoginFormFields>) => {
    console.log('Failed:', errorInfo);
  }, []);


  return (
    <ErrorBoundary>
      <div className="login-page">
        <Modal closable={false} centered title="Login" visible={true} footer={false}>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item labelCol={{ span: 4 }} label="ID" name="identifier" rules={[{ required: true, message: 'Please input your ID!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 4 }}
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </ErrorBoundary>
  );
};
