import { RouteModel, LocationModel, DeliveryModel, VendorModel, OrderScheduleModel, ShopifyOrderItemModel, ShopifyOrderModel, UUIDField, XBaseModel } from ".";
import { DateStrToMoment, MomentToDateStr, ExtractUUIDOrNull } from "../decorators";
import {  Type } from "class-transformer";
import moment from 'moment';

export enum DeliveryItemStatus {
  "Delivered" = "DELIVERED",
  "Rescheduled" = "RESCHEDULED"
}

export class DeliveryItemModel extends XBaseModel {
  consumer_uuid!: string;

  @Type(() => RouteModel)
  route!: RouteModel;

  @Type(() => DeliveryModel)
  delivery!: DeliveryModel;

  @Type(() => LocationModel)
  location!: LocationModel;

  @Type(() => OrderScheduleModel)
  order_schedule?: OrderScheduleModel;    

  @Type(() => ShopifyOrderModel)
  shopify_order!: ShopifyOrderModel;  

  @Type(() => ShopifyOrderItemModel)
  shopify_order_item!: ShopifyOrderItemModel;  

  @Type(() => VendorModel)
  vendor!: VendorModel;

  quantity!: number;

  short: number = 0;

  delivered?: boolean = false;

  left_at_location?: boolean = false;

  no_show?: boolean = false;  

  back_order?: boolean = false;  

  notes?: string;

  rescheduled?: string;

  status?: DeliveryItemStatus

  customer_confirmed?: boolean = false;

  //for react only
  key?: string | number;

  altered?: boolean = false;
}

export class UpdateDeliveryItemModel {

  @ExtractUUIDOrNull()
  delivery?: string | null;

  short?: number;

  delivered?: boolean;

  left_at_location?: boolean;

  no_show?: boolean;  

  back_order?: boolean;  

  notes?: string;

  rescheduled?: string;

  customer_confirmed?: boolean;

}
