import { DeliveryModel, RouteModel, UUIDField } from ".";
import { XBaseModel } from "./base.model";
import { VendorModel } from "./vendor.model";
import { Moment } from 'moment';
import { DateStrToMoment, ExtractUUID, ExtractUUIDOrNull, MomentToDateStr } from "../decorators";
import {  Type } from "class-transformer";
export class OrderScheduleModel extends XBaseModel {
  consumer_uuid!: UUIDField;
  
  @DateStrToMoment()
  date_open!: Moment;
  
  @DateStrToMoment()
  date_close!: Moment;
  
  @Type(() => DeliveryModel)
  delivery?: DeliveryModel;
  
  @Type(() => VendorModel)
  vendor!: VendorModel;
  
  @Type(() => RouteModel)
  route!: RouteModel;
}


export class UpdateOrderScheduleModel {
  @MomentToDateStr()
  date_open?: string;
  
  @MomentToDateStr()
  date_close?: string;
  
  @ExtractUUIDOrNull()
  delivery?: string | null;
}


export class CreateOrderScheduleModel {
    
  @MomentToDateStr()
  date_open!: string;
  
  @MomentToDateStr()
  date_close!: string;
  
  @ExtractUUID()
  delivery?: string;
  
  @ExtractUUID()
  vendor!: VendorModel;
  
  @ExtractUUID()
  route!: RouteModel;
}
