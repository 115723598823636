import { createContext, useMemo, useCallback, useState } from "react";
import Cookies, { CookieAttributes } from "js-cookie";
import qs from "query-string";
import { IAppProps } from "../App";
import { UserModel } from "../../models/user.model"

export const useAppHook = (props: IAppProps) => {

  const [me, setMe] = useState<UserModel>();
   
  const { cookieData, shouldShowGuides } = useMemo(() => {
    
    const query = qs.parse(window.location.search);
    // console.log('asdf');
    // console.log(
    //     (query.host as string) ??
    //     Cookies.get("host") ??
    //     process.env.REACT_APP_SHOPIFY_PROVIDER_HOST ??
    //     undefined             
    // );

    const cookieAttributes: CookieAttributes | undefined =
      process.env.REACT_APP_DONT_USE_SAME_SITE_COOKIES
        ? undefined
        : { sameSite: "None", secure: true };
    const cookieData = {
      shopOrigin: process.env.REACT_APP_SHOP_ORIGIN,
      token:
        Cookies.get("token") ??
        undefined,
      appApiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
      host: process.env.REACT_APP_SHOPIFY_PROVIDER_HOST
    } as const;
    console.log(cookieData);
  
    Object.keys(cookieData).forEach((key: string) => {
      const cookie = key as keyof typeof cookieData;
      const cookieValue = cookieData[cookie];
      if (cookieValue) {
        Cookies.set(cookie, cookieValue, cookieAttributes);
        return;
      }
      Cookies.remove(cookie);
    });


    const shouldShowGuides = Boolean(query["guides"]);
    return { cookieData, shouldShowGuides }
  },[])

  const login = async (identifer: string, password: string) => {
    let consumer: any = undefined;
    try {
      consumer = await props.api.auth.login(identifer, password);   
      const me: UserModel = consumer.consumer.users[0];
      Cookies.set("token", consumer.token, { expires: 1 });    
      setMe(me);   
      return me;      
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };  

  const logout = async () => {
    Cookies.remove("token");
    setMe(undefined);
  }

  return {
    api: props.api,
    shouldShowGuides,
    cookieData,
    login,
    logout,
    me
  };
};

export type IAppContext = ReturnType<typeof useAppHook>;
export const AppContext = createContext<IAppContext>({} as IAppContext);
