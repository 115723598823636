import { APIErrorResponse } from "../services/api/api.types";


export class APIError extends Error {
  status!: number;
  url!: string;
  method?: string;
  traceId: string | null;
  errors?: string | string[];

  constructor(requestURL:string,requestOptions:RequestInit, response: Response, responseBody:APIErrorResponse){
    super()
    this.name = responseBody.name ?? 'API Error';
    this.errors = responseBody.error;
    this.message = responseBody.message;
    this.status = response.status;
    this.url = requestURL;
    this.method = requestOptions.method
    this.traceId = response.headers.get('X-Request-Id')
  }
}