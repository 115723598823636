import { PageAction } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { RouteModel,UpdateRouteModel, CreateRouteModel } from "../../models/";
import { PageTemplate } from "./PageTemplate";
import { useContext } from "react";
import { AppContext } from "../hoc/app.hoc";
import { useEditableTable } from "../hooks/editable-table.hook";
import { plainToInstance } from "class-transformer";
import { useLocations } from "../hooks/locations.hook";
import { PageTag } from "../../components/PageTag";
import { withUUID } from "../../services/api/factories";
import { QueryParams } from "../../services/api/api.types";

const defaultRoutesQuery: QueryParams<RouteModel> = {
  page: 1,
  limit: 25,
  sort: [
    { field: 'name', order: "ASC" },
    { field: 'customer_tag', order: "ASC" }
  ],
}

const Routes = () => {
  const { api } = useContext(AppContext);

  const locations = useLocations({
    load: {
      page: 1,
      limit: 25,
      sort: { field: "name", order: "ASC" },
    }
  });

  const editableTableCtx = useEditableTable({
    rowKeyValueField: 'uuid',
    createEmptyRecord: () => {
      const record = withUUID(() => new RouteModel());
      return record;
    },
    defaultQuery: defaultRoutesQuery,
    loadRecords: async ({page,limit, sort}) => {
      const response = await api.routes.getMany({
        page,
        limit,
        sort,
      });
      
      response.data = response.data
      .map(x => {
        (x as any)._locations = x.locations ?? [];// Hack so we can get locations editable but keep the data.
        (x as any).locations = (x.locations ?? [])?.map(l => l.uuid); 
        return x
      });
      return response;
    },
    updateRecord: async (record, tableRow) => {
      const data = plainToInstance(UpdateRouteModel, record)
      return api.routes.updateOne(tableRow.uuid, data);
    },
    createRecord: async (record, tableRow) => {
      const data = plainToInstance(CreateRouteModel, record)
      return api.routes.createOne(data);
    },
    deleteRecord: async (record, tableRow) => {
      return api.routes.deleteOne(tableRow.uuid);
    },
    isEditable: true,
    isDeletable: false,
    columns: [
      {
        title: "Route",
        dataIndex: "name",
        key: "routes-name",
        editable: true,
        isEditableOnlyWhenNew: true,
        width: 180,
      },
      {
        title: "Customer Tag",
        dataIndex: "customer_tag",
        key: "routes-customer_tag",
        editable: true,
        isEditableOnlyWhenNew: true,
        width: 180,
      },
      {
        title: "Locations",
        dataIndex: "locations",
        key: "locations",
        editable: true,
        inputType: 'multi-select',
        select: {
          options: locations.data,
          fieldNames: {
            label: 'name',
            value: 'uuid',
            key: 'uuid',
          }
        },
        render: (v,record) => <>{((record as any)._locations ?? []).map((l:any) => <PageTag>{l.name}</PageTag>)}</>,
        width: 600,
      },
    ],
  });
  
  const { dispatch } = editableTableCtx;

  return (
    <PageTemplate>
      {{
        title: <PageTitle>Routes</PageTitle>,
        header: {
          mainAction: <PageAction onClick={() => dispatch({type: 'add-row' })}>Add New</PageAction>,
        },
        content: (
          <EditableTable ctx={editableTableCtx} isLoading={locations.isLoading} scrollX={960}  />
        ),
      }}
    </PageTemplate>
  );
};
export default Routes;
