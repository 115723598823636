import { Action, PageAction } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { PageTemplate } from "./PageTemplate";
import {
  CreateOrderScheduleModel,
  OrderScheduleModel,
  UpdateOrderScheduleModel,
} from "../../models";
import { useEditableTable } from "../hooks/editable-table.hook";
import { useContext } from "react";
import { AppContext } from "../hoc/app.hoc";
import { plainToInstance } from "class-transformer";
import { withUUID } from "../../services/api/factories";
import { useHistory } from "react-router";
import { useDeliveryDates } from "../hooks/delivery-dates.hook";
import { useRoutes } from "../hooks/routes.hook";
import { useVendors } from "../hooks/vendors.hook";
import { getColumnSearchDatesProps } from "../../components/table/get-column-date-search-props";
import { Upload } from "antd";
import { useOrderSchedules } from "../hooks/order-schedules.hook";
import { onUpload } from "../../components/utils/upload-handler";
import moment from 'moment';

const OrderSchedules = () => {
  const { api } = useContext(AppContext);
  const history = useHistory();

  const [orderSchedulesState, orderSchedulesDispatch] = useOrderSchedules();
  const [deliveryDatesState, deliveryDatesDispatch] = useDeliveryDates({
    shouldLoad: true,
    queryParams: {
      page: 1,
      limit: 50,
      filter: [{
        field: "delivery_date",
        operator: "$gte",
        value: moment().format("YYYY-MM-DD")
      }],
      sort: { field: "delivery_date", order: "DESC" },
    },
  });
  const {
    selectOptionsData: deliveryDatesSelectOptionsData,
    filterData: deliveryDatesFilterData,
    api: { isLoading: isLoadingDeliveryDates },
  } = deliveryDatesState;

  const [routesState] = useRoutes({
    shouldLoad: true,
    queryParams: {
      page: 1,
      limit: 25,
      sort: { field: "name", order: "ASC" },
    },
  });

  const {
    selectOptionsData: routesSelectOptionsData,
    filterData: routeFilterData,
    api: { isLoading: isLoadingRoutes },
  } = routesState;

  const [vendorsState] = useVendors({
    shouldLoad: true,
    queryParams: {
      limit: 1000,
      sort: { field: "name", order: "ASC" },
      fields: ["name", "uuid"],
    },
  });

  const {
    filterData: vendorFilterData,
    selectOptionsData: vendorsSelectOptionsData,
    api: { isLoading: isLoadingVendors },
  } = vendorsState;

  const editableTableCtx = useEditableTable({
    rowKeyValueField: "uuid",
    createEmptyRecord: () => {
      const record = withUUID(() => new OrderScheduleModel());
      return record;
    },
    defaultQuery: {
      page: 1,
      limit: 20,
      sort: [{ field: "date_created", order: "DESC" }],
    },
    loadRecords: async ({ page, limit, sort, filter }) => {
      const response = await api.orderSchedules.getMany({
        page,
        limit,
        sort,
        filter,
      });
      return response;
    },
    updateRecord: async (record, tableRow) => {
      const data = plainToInstance(UpdateOrderScheduleModel, record);
      return api.orderSchedules.updateOne(tableRow.uuid, data);
    },
    createRecord: async (record, tableRow) => {
      const data = plainToInstance(CreateOrderScheduleModel, record);
      return api.orderSchedules.createOne(data);
    },
    deleteRecord: async (record, tableRow) => {
      return api.orderSchedules.deleteOne(record.uuid);
    },
    isEditable: true,
    isDeletable: true,
    onEditingRowValuesChange: (changedValues, formValues) => {
      if (!("route" in changedValues)) return;
      const routeUUID = changedValues.route as any;
      deliveryDatesDispatch({
        type: "set-route-filter",
        payload: { routeUUID },
      });
    },
    onStartEditingRow: (record) => {
      const routeUUID = record.route.uuid;
      deliveryDatesDispatch({
        type: "set-route-filter",
        payload: { routeUUID },
      });
    },
    rowToFormValues: (record) => {
      const copyOfRecord = {
        ...record,
        delivery: record?.delivery?.uuid,
      };
      return copyOfRecord as any;
    },
    columns: [
      {
        title: "Vendor",
        key: "vendor.name",
        dataIndex: "vendor",
        render: (v, r) => r.vendor?.name,
        editable: true,
        isEditableOnlyWhenNew: true,

        inputType: "select",
        select: {
          options: vendorsSelectOptionsData,
          allowClear: true,
        },

        isSortable: true,
        filterKey: "vendor.uuid",
        filters: vendorFilterData,
        filterMultiple: true,
        filterSearch: true,
      },
      {
        title: "Delivery Route",
        children: [
          {
            title: "Route",
            key: "route.uuid",
            dataIndex: "route",
            render: (v, r) => r.route?.name,
            editable: true,
            isEditableOnlyWhenNew: true,
            inputType: "select",
            select: {
              options: routesSelectOptionsData,
              allowClear: true,
            },
            isSortable: true,
            sortKey: "route.name",
            filters: routeFilterData,
            filterMultiple: true,
          },
          {
            title: "Delivery Date",
            key: "delivery.delivery_date",
            dataIndex: "delivery",
            editable: true,
            inputType: "select",
            render: (v, r) => r.delivery?.delivery_date_formatted ?? "TBD",
            disabled: (tableRow, formValues, editMode) => {
              if (editMode === "update") return false;
              // This gets called alot?
              const isDisabled = !Boolean(formValues?.route);
              return isDisabled;
            },
            select: {
              options: deliveryDatesSelectOptionsData,
              allowClear: true,
            },
            isSortable: true,
            filterSearch: true,
            filters: deliveryDatesFilterData,
            filterMultiple: true,
          },
        ],
      },
      {
        title: "Date Range",
        key: "date_open_close",
        dataIndex: ["date_open", "date_close"],
        ...getColumnSearchDatesProps("date_open_close"),
        children: [
          {
            title: "Open",
            key: "date_open",
            dataIndex: "date_open",
            editable: true,
            inputType: "date",
            render: (v, r) => r.date_open?.format("MM-DD-YYYY"),
            isSortable: true,
          },
          {
            title: "Close",
            key: "date_close",
            dataIndex: "date_close",
            editable: true,
            inputType: "date",
            render: (v, r) => r.date_close?.format("MM-DD-YYYY"),
            isSortable: true,
          },
        ],
      },
    ],
  });
  const { dispatch } = editableTableCtx;

  const {
    api: { isExporting, importParams },
  } = orderSchedulesState;

  return (
    <PageTemplate>
      {{
        title: <PageTitle>Schedule</PageTitle>,
        header: {
          mainAction: (
            <PageAction onClick={() => dispatch({ type: "add-row" })}>
              Add New
            </PageAction>
          ),
          actions: [
            <Upload
              disabled={isExporting}
              {...importParams}
              showUploadList={false}
              onChange={(info) =>
                onUpload(info, () => dispatch({ type: "load-data" }))
              }
            >
              <Action>Import</Action>
            </Upload>,
            <Action
              loading={isExporting}
              onClick={() => orderSchedulesDispatch({ type: "load-export" })}
            >
              Export
            </Action>,
          ],
        },
        content: (
          <EditableTable
            ctx={editableTableCtx}
            isLoading={
              isLoadingRoutes ||
              isLoadingDeliveryDates ||
              isLoadingVendors ||
              isExporting
            }
          />
        ),
      }}
    </PageTemplate>
  );
};

export default OrderSchedules;
