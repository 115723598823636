import { useContext, useEffect, useState } from "react";
import { DeliveryItemModel, UpdateDeliveryItemModel, DeliveryModel, LocationModel, DiscrepancyModel, CreateDiscrepancyModel, DiscrepancyStatus, DiscrepancyIssuer, DiscrepancyReason } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import { useStateEnhanced } from './enhanced-state.hook';
import { plainToInstance } from "class-transformer";
import { SupportedRequestQueryParams, QueryFilter } from "../../services/api/api.types";
import { message } from "antd";
import moment from 'moment';

export interface UseDeliveryItemsProps {  
  load?: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"]
}

export interface IDeliveryItemsState {
  deliveryItems: DeliveryItemModel[]; 
  deliveriesOptions?: DeliveryModel[];
  rescheduleDeliveriesOptions?: DeliveryModel[];
  locationOptions?: LocationModel[];
  customerOptions?: string[];
  vendorOptions?: string[];
  productOptions?: string[];
  selectedRowKeys?: React.Key[];
  noteText?: string;

  activeDelivery?: string;
  customerFilterDelivery?: string;
  activeLocation?: string;
  activeCustomers: string[];
  activeVendor?: string;
  activeProduct?: string;
  activeRescheduleDelivery?: string;
  drawerItem?: DeliveryItemModel;

  isLoadingTable?: boolean;
  isSavingItems?: boolean;
  isSavingDrawerItem?: boolean;
  isLoadingFilters: boolean;
  editingTableEnabled: boolean;
  itemDrawerOpen?: boolean;
  isRescheduleOpen?: boolean;
  isRescheduleItemsOpen?: boolean;
  
  notifyOnReschedule?: boolean;
  deliveryOptionSelected: boolean;
}

export type DeliveryItemsAction =
  | { type: 'load-deliveries'; payload: { delivery_id: string, location_id?: string, customer_id?: string } }
  | { type: 'set-active-delivery'; payload: { delivery: string } }
  | { type: 'set-active-location'; payload: { location: string } }
  | { type: 'set-active-customers'; payload: { customers: string[] } }
  | { type: 'set-active-vendor'; payload: { vendor: string } }
  | { type: 'set-active-product'; payload: { product: string } }
  | { type: 'set-delivery-item'; payload: { updatedItem: DeliveryItemModel } }
  | { type: 'toggle-editable-table'; payload: {} }
  | { type: 'update-delivery-items'; payload: {} }
  | { type: 'set-reschedule-delivery'; payload: { delivery: string } }
  | { type: 'toggle-notify-on-reschedule'; payload: { state: boolean } }
  | { type: 'toggle-reschedule-modal'; payload: { state: boolean } }
  | { type: 'reschedule-delivery'; payload: {} }
  | { type: 'open-notes-drawer'; payload: { item: DeliveryItemModel } }
  | { type: 'close-notes-drawer'; payload: {} }
  | { type: "set-selected-row-keys"; payload: { selectedRowKeys?: React.Key[] } }
  | { type: "toggle-reschedule-items-modal"; payload: { state: boolean } }
  | { type: "reschedule-selected-items"; payload: { selectedRowKeys?: React.Key[] } }
  | { type: 'add-note-to-item'; payload: { text: string } }
  | { type: 'set-note-textarea'; payload: { text: string }};


export const useDeliveryItems = () => {
  const { api } = useContext(AppContext);

  const [state, { setProperty, setProperties }] = useStateEnhanced<IDeliveryItemsState>({
    editingTableEnabled: false,
    deliveryOptionSelected: false,
    isLoadingFilters: true,
    deliveryItems: [],
    activeCustomers: [],
  });

  const setActiveDelivery = (delivery: string) => {
    setProperties({ activeDelivery: delivery });    
  }

  const setActiveLocation = (location: string) => {
    setProperty({ activeLocation: location });
  }

  const setActiveCustomers = (customer: string[]) => {
    setProperty({ activeCustomers: customer });
  }   

  const setActiveVendor = (vendor: string) => {
    setProperty({ activeVendor: vendor });
  }   

  const setActiveProduct = (product: string) => {
    setProperty({ activeProduct: product });
  }       

  const setDeliveryItem = (updatedItem: DeliveryItemModel) => {
    const updatedItems = state.deliveryItems.map((item) => {
      if(item.uuid === updatedItem.uuid){
        updatedItem.altered = true;
        return updatedItem;
      }
      return item;
    });

    setProperty({ deliveryItems: updatedItems });
  } 

  const setRescheduleDelivery = (delivery: string) => {
    setProperty({ activeRescheduleDelivery: delivery });
  }

  const setRescheduleDeliveryOptions = () => {
    //set reschedule to only show deliveries for current route

    const activeDelivery = state?.deliveriesOptions?.find((item) => {
      if(item.uuid === state?.activeDelivery){
        return item;
      }
    }, null);

    if(!activeDelivery){
      setProperty({ rescheduleDeliveriesOptions: [] });  
      return;
    }

    const routeSpecificDeliveries = state?.deliveriesOptions?.filter((item) => {
      if(item?.route.uuid === activeDelivery?.route.uuid){
        return item;
      }
    });

    if(!routeSpecificDeliveries){
      setProperty({ rescheduleDeliveriesOptions: [] });  
      return;      
    }

    setProperty({ rescheduleDeliveriesOptions: routeSpecificDeliveries });    
  }

  const setSelectedRowKeys = (newSelectedRowKeys?: React.Key[]) => {
    setProperty({ selectedRowKeys: newSelectedRowKeys });
  }  

  const setNoteText = (text: string) => {
    setProperty({ noteText: text });
  }

  const toggleNotifyOnReschedule = (state: boolean) => {
    setProperty({ notifyOnReschedule: state });
  }

  const toggleEditableTable = () => {
    if(state.editingTableEnabled){
      setProperty({ editingTableEnabled: false });
    }else{
      setProperty({ editingTableEnabled: true });
    }
  } 

  const toggleRecheduleModal = (state: boolean) => {
    setProperty({ isRescheduleOpen: state });
  } 

  const toggleRecheduleItemsModal = (state: boolean) => {
    setProperty({ isRescheduleItemsOpen: state });
  }   

  const openNotesDrawer = (item: DeliveryItemModel) => {
    setProperties({ itemDrawerOpen: true, drawerItem: item });  
  }  

  const closeNotesDrawer = () => {
    setProperty({ itemDrawerOpen: false });  
  }    

  const addNoteToDrawerItem = async (text: string) => {
    setProperty({ isSavingDrawerItem: true });

    if(!state.drawerItem){
      setProperty({ isSavingDrawerItem: false });
      return;
    }    

    const newNote = `${moment().format("MM/DD/YYYY")}:${state.noteText};`;    

    const updatedItem = {
      delivery: state.drawerItem?.delivery.uuid,
      short: state.drawerItem?.short,
      delivered: state.drawerItem?.delivered,
      left_at_location: state.drawerItem?.left_at_location,
      no_show: state.drawerItem?.no_show,
      back_order: state.drawerItem?.back_order,
      notes: `${newNote}${state.drawerItem?.notes}`,
      rescheduled: state.drawerItem?.rescheduled,
    } as UpdateDeliveryItemModel;
    try {
      await api.deliveryItems.updateOne(state.drawerItem.uuid, updatedItem);
    } catch (e) {
      console.error(e);
      console.log('error', 'An error occured while updating delivery items');    
    } 

    const updatedDrawerItem = state.drawerItem;
    updatedDrawerItem.notes = `${newNote}${state.drawerItem?.notes}`;

    message.info('Note added');

    setProperties({ isSavingDrawerItem: false, drawerItem: updatedDrawerItem, noteText: "" });
  }  

  const loadDeliveries = async (requestParams: SupportedRequestQueryParams<DeliveryModel>["GetMany"]) => {
    //load deliveries for today and the next week
    setProperty({ isLoadingTable: true });
    const response = await api.deliveryDates.getMany(requestParams);
    const sortedItems = response.data.sort((a: DeliveryModel, b: DeliveryModel) => {
      const dateA = moment(a.delivery_date);
      const dateB = moment(b.delivery_date);
      if (moment(a.delivery_date).isBefore(b.delivery_date)) {
        return -1;
      }
      if (moment(a.delivery_date).isAfter(b.delivery_date)) {
        return 1;
      }
      return 0;    
    });

    //find delivery date closest to today
    let today = moment();
    let latest = moment().subtract(60, 'days');
    let closestDateIndex = 0;
    sortedItems.forEach((item, index) => {
      const itemDeliveryDate = moment(item.delivery_date);
      if(moment(today).isAfter(itemDeliveryDate) && moment(latest).isBefore(itemDeliveryDate)){
        closestDateIndex = index;
      }
    })

    setProperties({ isLoadingFilters: false, deliveriesOptions: sortedItems, activeDelivery: sortedItems[closestDateIndex].uuid });
  }  

  const loadLocationFilter = async () => {

    // load distinct locations for given delivery id
    setProperties({ isLoadingTable: true, isLoadingFilters: true });

    const selectedDeliveryRoute = state?.deliveriesOptions?.find((delivery) => {
      if(delivery.uuid === state.activeDelivery){
        return delivery;
      }
    }, null);

    if(!selectedDeliveryRoute){
      console.log("Unable to find active delivery");
      setProperties({ isLoadingTable: false, isLoadingFilters: false });
      return;
    }

    const requestParams: SupportedRequestQueryParams<LocationModel>["GetMany"] = {
      page: 1,
      limit: 25,
      filter: [{
        field: "route_uuid",
        operator: "$eq",
        value: selectedDeliveryRoute.route.uuid,
      }],
    };    

    const response = await api.locations.getMany(requestParams);

    setProperties({ isLoadingTable: false, isLoadingFilters: false, locationOptions: response.data });
  }

  const loadCustomerFilter = async (newDelivery?: boolean) => {

    if(!state.customerFilterDelivery || state.customerFilterDelivery !== state.activeDelivery){

      setProperties({ isLoadingTable: true, isLoadingFilters: true });

      const customerNames = state?.deliveryItems?.map((item) => {
        return item?.shopify_order?.shopify_customer_name;
      });

      const uniqueCustomers = customerNames.filter((v, i, a) => a.indexOf(v) === i);

      if(!uniqueCustomers){
        setProperties({ isLoadingTable: false, isLoadingFilters: false, customerOptions: [], activeCustomers: [] });
        return;
      }  

      setProperties({ isLoadingTable: false, isLoadingFilters: false, customerOptions: uniqueCustomers, customerFilterDelivery: state.activeDelivery, activeCustomers: [] });

    }
  }   

  const loadVendorFilter = () => {
    setProperties({ isLoadingTable: true, isLoadingFilters: true });

    const vendorNames = state?.deliveryItems?.map((item) => {
      return item?.vendor?.name;
    });

    const uniqueVendors = vendorNames.filter((v, i, a) => a.indexOf(v) === i);

    if(!uniqueVendors){
      setProperties({ isLoadingTable: false, isLoadingFilters: false, vendorOptions: [] });
      return;
    }  

    setProperties({ isLoadingTable: false, isLoadingFilters: false, vendorOptions: uniqueVendors });
  }  

  const loadProductFilter = () => {
    setProperties({ isLoadingTable: true, isLoadingFilters: true });

    const productNames = state?.deliveryItems?.map((item) => {
      return `${item?.shopify_order_item?.title} ${item?.shopify_order_item?.variant_title}`;
    });

    const uniqueProducts = productNames.filter((v, i, a) => a.indexOf(v) === i);

    if(!uniqueProducts){
      setProperties({ isLoadingTable: false, isLoadingFilters: false, productOptions: [] });
      return;
    }  

    setProperties({ isLoadingTable: false, isLoadingFilters: false, productOptions: uniqueProducts });    
  }        

  const loadDeliveryItems = async () => {
    setProperty({ isLoadingTable: true });

    //build item request based on 'active' state properties

    let tableItems: DeliveryItemModel[] = [];

    let requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
        page: 1,
        limit: 25,
        filter: []
    };    

    if(state.activeDelivery && state.activeLocation){

      const requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
          page: 1,
          limit: 25,
          filter: [
            {
              field: "delivery",
              operator: "$eq",
              value: state.activeDelivery,
            },
            {
              field: "location",
              operator: "$eq",
              value: state.activeLocation,
            }                   
          ],
      };
      
      const response = await api.deliveryItems.getMany(requestParams);
      tableItems = response.data.map((item) => {
        item.key = item.uuid;
        return item;
      });
      setProperties({ isLoadingTable: false, deliveryItems: tableItems });

    }else if(state.activeDelivery){

      const requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
          page: 1,
          limit: 25,
          filter: [
            {
              field: "delivery",
              operator: "$eq",
              value: state.activeDelivery,
            },                
          ],
      };
      
      const response = await api.deliveryItems.getMany(requestParams);
      tableItems = response.data.map((item) => {
        item.key = item.uuid;
        return item;
      });
      setProperties({ isLoadingTable: false, deliveryItems: tableItems });

    }else{
      const requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
          page: 1,
          limit: 25,
      };
      
      const response = await api.deliveryItems.getMany(requestParams);
      tableItems = response.data.map((item) => {
        item.key = item.uuid;
        return item;
      });
      
    }

    let filteredItems = tableItems;

    if(state.activeCustomers){
      if(state.activeCustomers.length > 0){
        const customerItems = filteredItems.filter((item) => {
          if(state.activeCustomers.includes(item.shopify_order.shopify_customer_name)){
            return item;
          }
        });
        filteredItems = customerItems;
      }
    }

    if(state.activeVendor){
      const vendorItems = filteredItems.filter((item) => {
        if(item.shopify_order_item.vendor === state.activeVendor){
          return item;
        }
      });
      filteredItems = vendorItems;
    }  

    if(state.activeProduct){
      const productItems = filteredItems.filter((item) => {
        if(`${item?.shopify_order_item?.title} ${item?.shopify_order_item?.variant_title}` === state.activeProduct){
          return item;
        }
      });
      filteredItems = productItems;
    }      


    setProperties({ selectedRowKeys: [], isLoadingTable: false, deliveryItems: filteredItems });

  }

  // TODO: Make these execute in parallel
  const updateDeliveryItems = async () => {
    setProperty({ isSavingItems: true });
    if(state.deliveryItems.length < 1){
      return;
    }   

    for(const deliveryItem of state?.deliveryItems){
      if(!deliveryItem){
        continue;
      }

      if(deliveryItem.altered === false){
        continue;
      }

      const updatedItem = {
        delivery: deliveryItem?.delivery.uuid,
        short: deliveryItem?.short,
        delivered: deliveryItem?.delivered,
        left_at_location: deliveryItem?.left_at_location,
        no_show: deliveryItem?.no_show,
        back_order: deliveryItem?.back_order,
        customer_confirmed: deliveryItem?.customer_confirmed,
        notes: deliveryItem?.notes,
        rescheduled: deliveryItem?.rescheduled,
      } as UpdateDeliveryItemModel;

      try {
        await api.deliveryItems.updateOne(deliveryItem.uuid, updatedItem);
      } catch (e) {
        console.error(e);
        console.log('error', 'An error occured while updating delivery items');    
      }  

      //create short discrepency
      if(deliveryItem?.short && deliveryItem?.short > 0){
        const discrepancy = {
          delivery_item: deliveryItem,
          issue_status: DiscrepancyStatus.UNRESOLVED,
          issuer: DiscrepancyIssuer.ADMIN,
          issue_reason: DiscrepancyReason.SHORT
        } as CreateDiscrepancyModel;

        try{
          await api.discrepancies.createOne(discrepancy);
        }catch(e){
          console.log(`error ${JSON.stringify(e)} occured during discrepancy creation`);
        }        
      }  
    }    

    setProperties({ isSavingItems: false, editingTableEnabled: false });

    loadDeliveryItems();       
    setRescheduleDeliveryOptions();    
  }

  // TODO: Make these execute in parallel, track down bug in classtransformer so we aren't making our own object
  const rescheduleDelivery = async () => {

    setProperty({ isSavingItems: true });

    //update delivery on each active delivery item
    for(const deliveryItem of state?.deliveryItems){
      const updatedItem = {
        delivery: state?.activeRescheduleDelivery,
        short: deliveryItem?.short,
        delivered: false,
        left_at_location: false,
        no_show: false,
        back_order: false,
        notes: deliveryItem?.notes,
        rescheduled: moment().format("MM-DD-YYYY"),
      } as UpdateDeliveryItemModel;
      try {
        await api.deliveryItems.updateOne(deliveryItem.uuid, updatedItem);
      } catch (e) {
        console.error(e);
        console.log('error', 'An error occured while rescheduling delivery items');    
      }    
    }

    message.info('Items rescheduled');

    setProperties({ isSavingItems: false, editingTableEnabled: false, isRescheduleOpen: false });

    loadDeliveryItems();
  }

  // TODO: Make these execute in parallel, track down bug in classtransformer so we aren't making our own object
  const rescheduleSelectedItems = async () => {

    setProperty({ isSavingItems: true });

    if(!state.selectedRowKeys){
      setProperty({ isSavingItems: false });
      return;
    }

    const filteredItems = state?.deliveryItems.filter((item) => {
      if(state.selectedRowKeys && state?.selectedRowKeys.includes(item.uuid)){
        return item;
      }
    });

    //update delivery on each active delivery item
    for(const deliveryItem of filteredItems){

      const updatedItem = {
        delivery: state?.activeRescheduleDelivery,
        short: deliveryItem?.short,
        delivered: false,
        left_at_location: false,
        no_show: false,
        back_order: false,
        notes: deliveryItem?.notes,
        rescheduled: moment().format("MM-DD-YYYY"),
      } as UpdateDeliveryItemModel;
      try {
        await api.deliveryItems.updateOne(deliveryItem.uuid, updatedItem);
      } catch (e) {
        console.error(e);
        console.log('error', 'An error occured while rescheduling delivery items');    
      }    
    }

    message.info('Items rescheduled');

    setProperties({ isSavingItems: false, editingTableEnabled: false, isRescheduleItemsOpen: false });

    loadDeliveryItems();
  }  

  const dispatch = (action: DeliveryItemsAction) => {
    switch (action.type) {
      case 'load-deliveries':
        return loadDeliveryItems();
      case 'set-active-delivery':
        return setActiveDelivery(action.payload.delivery); 
      case 'set-active-location':
        return setActiveLocation(action.payload.location);
      case 'set-active-customers':
        return setActiveCustomers(action.payload.customers); 
      case 'set-active-vendor':
        return setActiveVendor(action.payload.vendor); 
      case 'set-active-product':
        return setActiveProduct(action.payload.product);
      case 'toggle-editable-table':
        return toggleEditableTable(); 
      case 'set-delivery-item':
        return setDeliveryItem(action.payload.updatedItem); 
      case 'update-delivery-items':
        return updateDeliveryItems(); 
      case 'set-reschedule-delivery':
        return setRescheduleDelivery(action.payload.delivery); 
      case 'toggle-notify-on-reschedule':
        return toggleNotifyOnReschedule(action.payload.state); 
      case 'toggle-reschedule-modal':
        return toggleRecheduleModal(action.payload.state);   
      case 'reschedule-delivery':
        return rescheduleDelivery();  
      case 'open-notes-drawer':
        return openNotesDrawer(action.payload.item);  
      case 'close-notes-drawer':
        return closeNotesDrawer();           
      case 'add-note-to-item':
        return addNoteToDrawerItem(action.payload.text);   
      case 'set-selected-row-keys':
        return setSelectedRowKeys(action.payload.selectedRowKeys); 
      case 'toggle-reschedule-items-modal':
        return toggleRecheduleItemsModal(action.payload.state); 
      case 'reschedule-selected-items':
        return rescheduleSelectedItems();            
      case 'set-note-textarea':
        return setNoteText(action.payload.text);              
    }
  };  

  //initial render only
  useEffect(() => {

    loadDeliveries({
      filter: [
        {
          field: "delivery_date",
          operator: "$lte",
          value: moment().add(60, 'days').format("YYYY-MM-DD"),
        },
        {
          field: "delivery_date",
          operator: "$gte",
          value: moment().subtract(60, 'days').format("YYYY-MM-DD"),
        }        
      ],      
    });

    setRescheduleDeliveryOptions();

  }, []);

  useEffect(() => {

    if(state.activeDelivery){
      loadDeliveryItems();       
      setRescheduleDeliveryOptions();
    }        

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.activeDelivery])

  useEffect(() => {

    if(state.activeDelivery){
      loadDeliveryItems();
    }    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.activeCustomers, state.activeLocation, state.activeVendor, state.activeProduct])  

  useEffect(() => {

    loadLocationFilter();
    loadVendorFilter();
    loadCustomerFilter();
    loadProductFilter();
    setProperty({ selectedRowKeys: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.deliveryItems])  



  return {
    state,
    dispatch,
  }
};
