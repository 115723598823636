import { hot } from "react-hot-loader/root";
import { BrowserRouter, Route } from "react-router-dom";
import { AppContext, useAppHook } from "./hoc/app.hoc";
import { AppTemplate } from "./AppTemplate";
import { API } from "../services/api/api";
import { Provider } from "@shopify/app-bridge-react";
import { LoginPage } from "./pages/Login"
import { ConsumerModel } from "../models/consumer.model"

export interface IAppProps {
  api: API;
}
const App = (props: IAppProps) => {
  const ctx = useAppHook(props);

  return (
    <Provider
      config={{
        apiKey: ctx.cookieData.appApiKey ?? "",
        host: ctx.cookieData.host ?? "",
        forceRedirect: false,
      }}
    >
      <AppContext.Provider value={ctx}>
        <BrowserRouter basename="/admin/">
          <AppTemplate />
        </BrowserRouter>        
      </AppContext.Provider>
    </Provider>
  );
};

export default hot(App);
