import { useContext, useEffect, useReducer } from "react";
import { SupportedRequestQueryParams } from "../../services/api/api.types";
import { LocationModel, RouteModel } from "../../models";
import { API } from "../../services/api/api";
import { AppContext } from "../hoc/app.hoc";
import { APIHookActions, APIHookProps, APIHookState, apiReducer } from "./hook.types";

export type GetRouteParams =
  SupportedRequestQueryParams<RouteModel>["GetMany"];

export interface RouteProps extends APIHookProps<RouteModel> {
  queryParams?: GetRouteParams;
  shouldLoad?: boolean;
}
export interface IRouteState {
  api: APIHookState<RouteModel>;
  filterData: { text:string; value: string; key: string }[];
  selectOptionsData: { label:string; value: string; key: string }[];
  tableData: ({ _locations: LocationModel[]; locations: string[] } & Omit<RouteModel,"locations">)[]
}

export type RouteActions = APIHookActions<RouteModel>
  

const reducer = (
  state: IRouteState,
  action: RouteActions
): IRouteState => {

  const { api: apiState, ...restOfState } = state;
  const nextState = {
    ...restOfState,
    api: apiReducer<RouteModel>(apiState, action as APIHookActions<RouteModel>)
  };

  switch(action.type){
    case 'set-raw-response-data':
      nextState.selectOptionsData = (nextState.api?.rawResponseData?.data ?? []).map(({ uuid, name }) => ({ label:name, value: uuid, key: uuid,  }))
      nextState.filterData = (nextState.api?.rawResponseData?.data ?? []).map(({ uuid, name }) => ({ text:name, value: uuid, key: uuid,  }))
        nextState.tableData = (nextState.api?.rawResponseData?.data ?? []).map(({locations,...restOfRow}) => {
          const xx: IRouteState["tableData"][0] = {
            ...restOfRow,
            _locations: locations ?? [],// Hack so we can get locations editable but keep the data.
            locations: (locations ?? [])?.map(l => l.uuid), 
          }
          return xx
        });
        break;
  }
  return nextState;
};

const loadData = async (
  api: API,
  dispatch: React.Dispatch<RouteActions>,
  requestParams?: GetRouteParams
) => {
  dispatch({ type: "lock-loading" });

  const response = await api.routes.getMany(requestParams);
  
  dispatch({ type: "set-raw-response-data", payload: response });
};

export const useRoutes = (props?: RouteProps) => {
  const { api } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, {
    api: {
      queryParams: props?.queryParams,
      shouldLoadData: props?.shouldLoad,
    },
    filterData: [],
    tableData: [],
    selectOptionsData: [],
  });
  const { api: { isLoading, shouldLoadData, queryParams } } = state;

  /**
   * React to changing query params
   */
  useEffect(() => {
    if (!shouldLoadData || isLoading) {
      return;
    }
    loadData(api, dispatch, queryParams);
  }, [api, isLoading, shouldLoadData, queryParams]);

  return [state, dispatch] as const;
};
