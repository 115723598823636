import { TableProps, Form, FormInstance, TableColumnType, Input, Table } from "antd";
import React, { useReducer, createContext, HTMLAttributes, useRef, useCallback, useMemo, useEffect } from "react";
import { GetManyResponse, QueryFilter, QueryParams, QuerySort } from "../../services/api/api.types";
import { handleError, safelyFireCallback } from "../../services/error-handler";
import { EditableCellProps } from "../../components/EditableTableCell";
import { LabeledSwitchFactory } from "../../components/LabeledSwitch";
import { EditableTableRowControls } from "../../components/EditableTableRowControls";
import { FilterDropdownProps, FilterValue,  SortOrder, TableRowSelection, } from "antd/lib/table/interface";

import { getColumnSearchProps } from "../../components/table/get-column-search-props";
import { SearchOutlined } from "@ant-design/icons";

// TODO: Move the types out of here.
export type TableRowData<TRecord> = TRecord & { key: React.Key };
export interface EditableTableState<TRecord> {
  data: TableRowData<TRecord>[];
  shouldLoadData?: boolean;
  isLoading?: boolean;
  editingRowKey?: React.Key;
  editingRowIndex?: number;
  isEditingNewRow?: boolean;
  isSavingRow?: boolean;
  isDeletingRow?: boolean;

  
  searchedColumns: { [columnKey: string]: string };
  filteredColumns: { [columnKey:string]: FilterValue | null };
  sortedColumns: { [columnKey: string]: SortOrder };

  callbacks?: (()=>void)[],

  page: number;
  limit: number;
  total?: number;

  sort?: QuerySort<TRecord>;
  filter?: QueryFilter<TRecord>[];

  selectedRowKeys?: React.Key[];
  allDataSelected?: boolean;
}

export type EditableTableActions<TRecord> =
  | { type: "load-data" }
  | { type: "load-data-success"; payload: { data: TableRowData<TRecord>[]; total: number } } //page: number; count: number; pageCount:number } }
  | { type: "load-data-error" }
  | { type: "lock-loading" }
  | { type: "unlock-loading" }
  | { type: "start-edit-row"; payload: { key: React.Key } }
  | { type: "cancel-edit-row" }
  | { type: "add-row" }
  | { type: "saving-edit-row" }
  | { type: "create-row" }
  | { type: "update-row" }
  | { type: "saving-edit-row-success" }
  | { type: "saving-edit-row-failure" }
  | { type: "delete-row" }
  | { type: "delete-row-success" }
  | { type: "delete-row-failure" }
  
  | { type: "set-pagination"; payload: { page: number; limit: number} }
  | { type: "set-filters"; payload: { query: QueryFilter<TRecord>[] | undefined; columns: { [columnKey:string]: FilterValue | null } }  }
  | { type: "set-sort"; payload: { query: QuerySort<TRecord> | undefined; columns: { [dataIndex:string]: SortOrder } }  }
  | { type: "set-selected-row-keys"; payload: { selectedRowKeys?: React.Key[] } }
  | { type: 'set-all-data-selected' }
  

export interface EditableTableColumnType<TRecord, TOption = any>
  extends TableColumnType<TableRowData<TRecord>> {
  editable?: boolean;
  isEditableOnlyWhenNew?: boolean;
  inputType?: EditableCellProps<TRecord>["inputType"];
  mapSelectOptionValues?: any;
  isRequired?: boolean;
  isSearchable?: boolean;
  isSortable?:boolean;
  filterValues?: { text: string, value: string }[],
  filterKey?: string;
  filterSearchOperator?:  QueryFilter<TRecord>['operator'];
  sortKey?: string;
  
  filterDropdownWrapper?: (filterProps: FilterDropdownProps) => React.ReactNode;

  select?: {
    allowClear?: boolean;
    options: TOption[];
    fieldNames?: {
      label: string;
      value: string;
      key?: string;
    };
  };
  disabled?: (tableRow: TableRowData<TRecord>, formValues: TRecord, editMode: 'update' | 'create') => boolean;
}
export interface EditableTableColumnGroupType<TRecord>
  extends Omit<TableColumnType<TableRowData<TRecord>>, "dataIndex" | "render"> {
  children: EditableTableColumnsType<TRecord>[];
}
export type EditableTableColumnsType<TRecord> =
  | EditableTableColumnGroupType<TRecord>
  | EditableTableColumnType<TRecord>;

export interface TableState<TRecord> {
  page: number;
  limit: number;
  sort?: QuerySort<TRecord>,
  filter?: QueryFilter<TRecord>[],
}

export interface UseEditableTableProps<TRecord> {
  createEmptyRecord: () => TRecord;
  defaultQuery?: QueryParams<TRecord>,
  getRowKey?: (record: TRecord) => React.Key;
  rowKeyValueField?: keyof TRecord & string;
  loadRecords?: ((tableState:TableState<TRecord>) => Promise<GetManyResponse<TRecord>>) | (() => Promise<GetManyResponse<TRecord>>);
  updateRecord: (
    record: TRecord,
    tableRow: TableRowData<TRecord>
  ) => Promise<unknown>;
  createRecord: (
    record: TRecord,
    tableRow: TableRowData<TRecord>
  ) => Promise<unknown>;
  deleteRecord: (
    record: TRecord,
    tableRow: TableRowData<TRecord>
  ) => Promise<unknown>;
  additionalAction?: (record: TRecord) => void;
  columns: EditableTableColumnsType<TRecord>[];
  isEditable?: boolean;
  isDeletable?: boolean;
  isSelectable?: boolean;
  onEditingRowValuesChange?: ((changedValues: Partial<TRecord>, formValues: TRecord) => void);
  onStartEditingRow?: (record:TRecord) => void,
  rowToFormValues?: (record: TRecord) => TRecord;
}

export interface IEditableTableContext<TRecord> {
  dispatch: React.Dispatch<EditableTableActions<TRecord>>;
  state: EditableTableState<TRecord>;
  form: FormInstance<TRecord>;
  helpers: {
    isEditing: (record: TRecord) => boolean;
    getRowKey: (record: TRecord) => React.Key;
  };
  handlers: {
    loadRecords: () => Promise<void>;
    updateRecord: (
      record: TRecord,
      tableRow: TableRowData<TRecord>
    ) => Promise<void>;
    createRecord: (
      record: TRecord,
      tableRow: TableRowData<TRecord>
    ) => Promise<void>;
    deleteRecord: (selectedTableRow: TableRowData<TRecord>) => Promise<void>;
    saveRecord: (selectedTableRow: TableRowData<TRecord>) => Promise<void>;
    startEditRecord: (record: TRecord) => void;
    cancelEditRecord: () => void;
    onEditingRowValuesChange?: ((changedValues: Partial<TRecord>, formValues: TRecord) => void)
    onTableChange: TableProps<TableRowData<TRecord>>["onChange"]
    setSearchTerm: (dataIndex:string, selectedKeys: React.Key[], done:() => void) => void,
    clearSearchTerm:  (dataIndex: string, done?: () => void) => void,
  };
  props: {
    columns: EditableTableColumnsType<TRecord>[];
    isEditable?: boolean;
    rowSelection?: TableRowSelection<TableRowData<TRecord>>;
    rowKey?: any,
  };
}

const findColumn = <TRecord>(columns: EditableTableColumnsType<TRecord>[], value: any, fieldKey='key'): EditableTableColumnType<TRecord> | undefined => {
  let foundColumn: EditableTableColumnType<TRecord> | undefined;
  for(let col of columns){
    if( fieldKey in col && (col as any)[fieldKey] === value ){
      foundColumn = col; 
    } else if('children' in col){
      foundColumn = findColumn<TRecord>(col.children, value, fieldKey);
    } 
    if(foundColumn) break;
  }
    
  return foundColumn;
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 25;
// ASSUMES Key is not a property alreaduy
export const useEditableTable = <TRecord>(
  props: UseEditableTableProps<TRecord>
) => {
  const {
    defaultQuery,
    loadRecords: _loadRecords,
    updateRecord: _updateRecord,
    createRecord: _createRecord,
    deleteRecord: _deleteRecord,
    createEmptyRecord,
    getRowKey: _getRowKey,
    columns,
    isEditable,
    isDeletable,
    isSelectable,
    rowKeyValueField: _rowKeyValueField,
    onEditingRowValuesChange,
    onStartEditingRow,
    rowToFormValues,
  } = props;


  const defaultGetRowKey = useCallback((record: TRecord) => {
    const recordWithKey: TRecord & { key: React.Key } = record as any;
    const keyValue = (recordWithKey as any)[_rowKeyValueField];
    return keyValue;
  },[_rowKeyValueField]);

  const getRowKey = useCallback((record: TRecord) => (_getRowKey ?? defaultGetRowKey)(record), [_getRowKey, defaultGetRowKey]);

  // TODO: REFACTOR THIS
  const reducer = useRef((
    _currentState: EditableTableState<TRecord>,
    action: EditableTableActions<TRecord>
  ): EditableTableState<TRecord> => {
    console.log({action: action.type, state: _currentState});
    const {shouldLoadData, ...currentState} = _currentState;
    
    switch (action.type) {
      case "load-data":
        return { ...currentState, shouldLoadData: true };
      case "load-data-success":
        return reducer.current({ 
          ...currentState, 
          data: action.payload.data, 
          total: action.payload.total, 
        },{ type: 'unlock-loading'});
      case "load-data-error":
        return reducer.current({ ...currentState, isLoading: false }, { type: 'unlock-loading'});
      case "lock-loading": {
        return {...currentState, isLoading: true }
      }
      case "unlock-loading": {
        return {
          ...currentState, 
          isLoading: false,
          selectedRowKeys: !currentState.allDataSelected ? currentState.selectedRowKeys : currentState.data.map(x => x.key),
        }
      }
      case "start-edit-row":
        let editingRowKey = action.payload.key;
        const editingRowIndex = currentState.data.findIndex(
          (item) => editingRowKey === item.key
        );
        if (editingRowIndex < 0) return currentState;
        return {
          ...currentState,
          editingRowIndex,
          editingRowKey,
          selectedRowKeys: [],
          allDataSelected: false,
        };
      case "cancel-edit-row":
        const copyOfLocations = [...currentState.data];
        if (currentState.isEditingNewRow) {
          copyOfLocations.shift();
        }
        return {
          ...currentState,
          data: copyOfLocations,
          editingRowIndex: undefined,
          editingRowKey: undefined,
          isEditingNewRow: undefined,
        };
      case "add-row":
        if (currentState.isEditingNewRow) {
          return currentState;
        }
        const newRecord = createEmptyRecord();
        const rowKey = getRowKey(newRecord);
        const newRow: TableRowData<TRecord> = {
          ...newRecord,
          key: rowKey,
        };
        return {
          ...currentState,
          data: [newRow, ...currentState.data],
          editingRowKey: rowKey,
          editingRowIndex: 0,
          isEditingNewRow: true,
        };

      case "saving-edit-row":
        return { ...currentState, isSavingRow: true };
      case "create-row":
        return currentState;
      case "update-row":
        return currentState;
      case "saving-edit-row-success":
        return { 
          data: currentState.data,
          limit: currentState.limit, 
          total: currentState.total,
          page: currentState.page, 
          filter: currentState.filter, 
          sort: currentState.sort,
          searchedColumns: currentState.searchedColumns,
          filteredColumns: currentState.filteredColumns,
          sortedColumns: currentState.sortedColumns,
          selectedRowKeys: currentState.selectedRowKeys,
          allDataSelected: currentState.allDataSelected
        };
      case "saving-edit-row-failure":
        return { ...currentState, isSavingRow: false };
      case "delete-row":
        return { ...currentState, isDeletingRow: true };
      case "delete-row-success":
        const intermediateState = reducer.current({ 
          ...currentState, 
          isDeletingRow: false,
          editingRowIndex: undefined,
          editingRowKey: undefined,
        }, { type: 'saving-edit-row-success' });
        return reducer.current(intermediateState, { type: 'load-data' });
      case "delete-row-failure":
        return { ...currentState, isDeletingRow: false };
      case "set-pagination":
        return { 
          ...currentState, 
          page: action.payload.page ?? defaultQuery?.page ?? DEFAULT_PAGE, 
          limit: action.payload.limit ?? defaultQuery?.page ?? DEFAULT_LIMIT,
          shouldLoadData:true,
        };
      case "set-filters":
        return {
          ...currentState,
          page: 1, 
          filter: action.payload.query,
          filteredColumns: action.payload.columns,
          // limit: defaultQuery?.limit, // Limit can stay what it is
          shouldLoadData:true,
        }
      case "set-sort":
        return {
          ...currentState,
          page: 1,
          sort: action.payload.query,
          sortedColumns: action.payload.columns,
          shouldLoadData:true,
        }
      case 'set-selected-row-keys': {
        return {
          ...currentState,
          allDataSelected: false,
          selectedRowKeys: action.payload.selectedRowKeys,
        }
      }
      case 'set-all-data-selected': {
        return {
          ...currentState,
          selectedRowKeys: currentState.data.map(x => x.key),
          allDataSelected: true,
        }
      }
    }
    
  });


  const [form] = Form.useForm<TRecord>();
  const [state, dispatch] = useReducer(reducer.current, { 
    data: [], 
    page: props.defaultQuery?.page ?? DEFAULT_PAGE, 
    limit: props.defaultQuery?.limit ?? DEFAULT_LIMIT, 
    sort:  Array.isArray(props.defaultQuery?.sort) ? props.defaultQuery?.sort[0] : props.defaultQuery?.sort,
    filter: !props.defaultQuery?.filter 
      ? undefined 
      : Array.isArray(props.defaultQuery?.filter) 
        ? props.defaultQuery?.filter 
        : [props.defaultQuery?.filter],
    searchedColumns: {},
    filteredColumns: {},
    sortedColumns: {},
    shouldLoadData: true,
  });

  const searchInput = useRef<Input>(null);

  const isEditingRow = useCallback((record: TRecord) =>
    getRowKey(record) === state.editingRowKey,[getRowKey, state.editingRowKey])

  const loadRecords = useCallback(async (tableState: TableState<TRecord>) => {
    if(!_loadRecords) return;
    if(state.isLoading) return;
    console.log({ source: 'loadRecords', ...tableState})

    dispatch({ type: "lock-loading" });
    try {
      const { data, total } = await _loadRecords(tableState);
      const responseDataWithKeys: TableRowData<TRecord>[] = data.map(
        (record) => ({
          ...record,
          key: getRowKey(record),
        })
      );
      dispatch({
        type: "load-data-success",
        payload: { data: responseDataWithKeys, total },
      });
    } catch (e) {
      dispatch({ type: "load-data-error" });
      handleError(e);
    }
  },[_loadRecords, getRowKey, state.isLoading]);

  const updateRecord = useCallback(async (
    record: TRecord,
    tableRow: TableRowData<TRecord>,
    cb?: () => void
  ) => {
    dispatch({ type: "update-row" });
    try {
      await _updateRecord(record, tableRow);
      dispatch({
        type: "saving-edit-row-success",
      });
      form.resetFields();
      safelyFireCallback(cb);
    } catch (e) {
      dispatch({ type: "saving-edit-row-failure" });
      handleError(e);
    }
  },[_updateRecord, form]);

  const createRecord = useCallback(async (
    record: TRecord,
    tableRow: TableRowData<TRecord>,
    cb?: () => void
  ) => {
    dispatch({ type: "create-row" });
    try {
      await _createRecord(record, tableRow);
      dispatch({
        type: "saving-edit-row-success",
      });
      form.resetFields();
      safelyFireCallback(cb);
    } catch (e) {
      dispatch({ type: "saving-edit-row-failure" });
      handleError(e);
    }
  },[_createRecord, form]);

  const saveRecord = useCallback(async (
    selectedTableRow: TableRowData<TRecord>,
    cb?: () => void
  ) => {
    if (
      state.editingRowIndex === undefined ||
      state.editingRowIndex === null ||
      !state.editingRowKey
    ) {
      throw new Error("Something is wrong with our state.");
    }

    const tableRow = state.data[state.editingRowIndex];
    if (selectedTableRow.key !== tableRow.key) {
      throw new Error("Attempting to save incorrect row.");
    }
    try {
      const payload = (await form.validateFields()) as TRecord;

      dispatch({ type: "saving-edit-row" });
      if (state.isEditingNewRow) {
        await createRecord(payload, tableRow, cb);
      } else {
        await updateRecord(payload, tableRow, cb);
      }
      await loadRecords({
         page: state.page, 
         limit: state.limit, 
         sort: state.sort, 
         filter: state.filter,
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  },[createRecord, form, loadRecords, state.data, state.editingRowIndex, state.editingRowKey, state.filter, state.isEditingNewRow, state.limit, state.page, state.sort, updateRecord]);

  const deleteRecord = useCallback(async (
    selectedTableRow: TableRowData<TRecord>,
    cb?: () => void
  ) => {
    if (
      state.editingRowIndex === undefined ||
      state.editingRowIndex === null ||
      !state.editingRowKey
    ) {
      console.error("Something is wrong with our state.");
      return;
    }

    const tableRow = state.data[state.editingRowIndex];
    if (selectedTableRow.key !== tableRow.key) {
      throw new Error("Attempting to save incorrect row.");
    }

    dispatch({ type: "delete-row" });
    try {
      const { key, ...restOfTableRow } = tableRow;
      const record: TRecord = restOfTableRow as any;

      await _deleteRecord(record, tableRow);
      form.resetFields();
      dispatch({
        type: "delete-row-success",
      });
      safelyFireCallback(cb);
    } catch (e) {
      dispatch({ type: "delete-row-failure" });
      handleError(e);
    }
  },[_deleteRecord, form, state.data, state.editingRowIndex, state.editingRowKey]);


  // TODO: Deprecate these
  const setSearchTerm = useCallback((dataIndex:string, selectedKeys: React.Key[] | null, done?: () => void) => {
    if(done){
      done()
    }
    // dispatch({ type: 'set-search-column', payload: { dataIndex, selectedKeys, callback: done} })
  },[]);

  const clearSearchTerm = useCallback((dataIndex: string, done?: () => void) => {
    if(done){
      done()
    }
    // dispatch({ type: 'set-search-column', payload: { dataIndex, selectedKeys: null, callback: done} })
    // dispatch({ type: 'clear-search-column', payload: { dataIndex, callback: done} })
  }, []);


  const onTableChange:TableProps<TableRowData<TRecord>>["onChange"] = (
    pagination, filters, sorter, extra,
  ) => {
    console.log({source: 'onTableChange', pagination, filters, sorter, extra,});
    switch(extra.action) {
      case 'sort': {
        if (!Array.isArray(sorter)){
          sorter = [sorter]
        }
        
        const validSort = sorter.filter(x => Boolean(x.column?.dataIndex)).pop()
        const validSorts = validSort ? [validSort] : [];

        const sortTransformed = validSorts.map(x => {
          
          const fieldKey = (x.column as EditableTableColumnType<TRecord>).sortKey ?? x.columnKey ;
          
          const sort: QuerySort<TableRowData<TRecord>> = {
            field: fieldKey as any,
            order: x.order === 'ascend' ? 'ASC' : 'DESC',
          }
          return sort
        })
        const firstSort = sortTransformed.pop()
        


        const sortedColumns = validSorts.reduce((agg, current) => {
          if(!current.columnKey) return agg;
          if(!current.order) return agg;
          agg[current.columnKey] = current.order
          return agg
        }, {} as EditableTableState<TRecord>['sortedColumns'] )
        
        return dispatch({ type: 'set-sort', payload: { query: firstSort, columns: sortedColumns } })
      }
      case 'filter': {
        const qFilters: QueryFilter<TRecord>[] = []
        
        Object.keys(filters)
        .filter(fKey => filters[fKey] ) 
        .forEach(fKey => {
          const filterValues = filters[fKey];
          if(!filterValues) return;
          
          const col = findColumn(columns, fKey, 'key');

          const fieldKey = (col as any)?.filterKey ?? fKey;
          
          let value: FilterValue | React.Key | boolean = filterValues
          let filterSearchOperator = col?.filterSearchOperator;
          
          if(col?.key === 'date_open_close'){
            // TODO: This is just a special case of between.
            if( !(Array.isArray(col?.dataIndex) && Array.isArray(value) && value[0] && value[1])){
              return
            }
            const lowField = col?.dataIndex[0];
            const lowValue = value[0];
            const highField = col?.dataIndex[1];
            const highValue = value[1];
            qFilters.push({
              field:  lowField,
              operator: '$gte',
              value: lowValue,
            });
            qFilters.push({
              field:  highField,
              operator: '$lte',
              value: highValue,
            });
            return
          } 

          if(!filterSearchOperator && col?.isSearchable){
            filterSearchOperator = '$startsL'
          } else if (!filterSearchOperator) {
            filterSearchOperator = '$in'
          }

          if( !['$in', '$between'].includes(filterSearchOperator) ) {
            value = value[0]
          }

          qFilters.push({
              field: fieldKey,
              operator: filterSearchOperator,
              value: value as any,
            });
          
        })
        
        return dispatch({ type: 'set-filters', payload: { query: qFilters, columns: filters } })
      }
      case 'paginate':
        const paginationTransformed = {
          page: pagination.current ?? defaultQuery?.page ??  DEFAULT_PAGE,
          limit: pagination.pageSize ?? defaultQuery?.limit ??  DEFAULT_LIMIT,
        }    
        return dispatch({ type: 'set-pagination', payload: paginationTransformed })
    }
  }

  const startEditRecord = useCallback((record: TRecord) => {
    
    let transformedRecord = !rowToFormValues ? record : rowToFormValues(record);

    form.setFieldsValue({ ...transformedRecord });
    const editingKey = getRowKey(record);
    dispatch({ type: "start-edit-row", payload: { key: editingKey } });
    if(onStartEditingRow){
      onStartEditingRow(record)
    }
  },[form, getRowKey, onStartEditingRow, rowToFormValues]);

  const cancelEditRecord = useCallback(() => {
    dispatch({ type: "cancel-edit-row" });
    form.resetFields();
  },[form]);

  const enhanceColumnData = useCallback((
    col: EditableTableColumnsType<TRecord>
  ): EditableTableColumnsType<TRecord> => {
    if ("children" in col) {
      const { children, ...restOfCol } = col;
      const enhancedCol = enhanceColumnData(restOfCol);
      return {
        ...enhancedCol,
        children: children.map((newChild) => enhanceColumnData(newChild)),
      };
    }

    
    let columnSearchProps;
    let columnFilterProps;
    let columnSortProps;
    if(col.key && typeof col.key === 'string'){
      if(col.isSearchable){
        columnSearchProps = getColumnSearchProps<TRecord>(col.key, {
            searchInput,
            setSearchValue: setSearchTerm,
            clearSearchValue: clearSearchTerm,
            searchedColumns: state.searchedColumns,
          });

        (columnSearchProps as any).filteredValue = state.filteredColumns[col.key] ? [state.filteredColumns[col.key]] : null;

      } else if (col.filters?.length || col.filterDropdown){
        columnFilterProps = {
          filteredValue: state.filteredColumns[col.key] ?? null,
          onFilter: (value:any, record:any) => {
            /** Filter on the server */
            return true;
          },
          filterIcon: (filtered?:boolean) => React.createFactory(SearchOutlined)({ color: filtered ? "#b1816e" : undefined })
          ,
        }
      } 

      if(col.isSortable){
        columnSortProps= {
          sorter: true,
          sortOrder: state.sortedColumns[col?.key as string] ?? null,
        }
      }
    }



    if (col.inputType === "time" && !col.render) {
      col.render = (v: any, record: TableRowData<TRecord>, i: number) =>
        col?.dataIndex &&
        (col.dataIndex as string) in record &&
        (record as any)[col.dataIndex as string]?.format("HH:mm:ss");
    }
    if (col.inputType === "switch" && !col.render) {
      col.render = (v: any, record: TableRowData<TRecord>, i: number) =>
        LabeledSwitchFactory({
          disabled: true,
          before: "off",
          after: "on",
          checked: (record as any)[col.dataIndex as string],
          loading: state.isLoading,
        });
    }
    if (!col.editable) {
      return {
        ...col,

        ...columnSearchProps,
        ...columnFilterProps,
        ...columnSortProps,

      };
    }

    const isCellEditable = (
      record: TableRowData<TRecord>,
      isEditingCurrentRow: boolean,
      isEditingOnlyAllowedOnNewRows?: boolean,
      isEditingNewRow?: boolean
    ) => {
      // Were not even editing the row so we can leave
      if (!isEditingCurrentRow) return false;
      // we can only edit the first row but its not the selected one so we out
      if (isEditingOnlyAllowedOnNewRows && !isEditingNewRow) return false;
      // There may be a manual override blocking this row from being editable
      if (
        typeof col?.disabled === "function" &&
        col.disabled(record, form.getFieldsValue(), isEditingNewRow ? 'create' : 'update')
      ) {
        return false;
      }

      // It doesnt matter what row we are on we can edit
      return true;
    };
    

    return {
      ...col,
      ...columnSearchProps,
      ...columnFilterProps,
      ...columnSortProps,
      onCell: (record: TableRowData<TRecord>) =>
        ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col?.title,
          editing: isCellEditable(
            record,
            isEditingRow(record),
            col.isEditableOnlyWhenNew,
            state.isEditingNewRow
          ),
          required: col?.isRequired ?? true,
          select: col.select,
        } as HTMLAttributes<HTMLElement>), //HACK
    };
  },[clearSearchTerm, form, isEditingRow, setSearchTerm, state.filteredColumns, state.isEditingNewRow, state.isLoading, state.searchedColumns, state.sortedColumns]);

  const mergedColumns: EditableTableColumnType<TRecord>[] = useMemo(() =>
    !isEditable
    ? columns
    : [
        ...columns.map(enhanceColumnData),
        {
          title: "",
          dataIndex: "operation",
          key: "operation",
          width: 150,
          fixed: "right",
          render: (value, record) =>
            EditableTableRowControls({
              isEditingRecord: isEditingRow,
              saveRecord,
              cancelEditRecord,
              deleteRecord,
              startEditRecord,
              isDeletable,
              isEditingARow: Boolean(state.editingRowKey),
              additionalAction: props.additionalAction,
            })(value, record),
        },
    ],[cancelEditRecord, columns, deleteRecord, enhanceColumnData, isDeletable, isEditable, isEditingRow, props.additionalAction, saveRecord, startEditRecord, state.editingRowKey])


    const { shouldLoadData, isLoading, filter, limit, page, sort, selectedRowKeys } = state
      // TODO: Cleanup
    const mergedRowSelection = useMemo(() => {
      if (!isSelectable) return;

      const rowSelection: TableRowSelection<TableRowData<TRecord>> = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TRecord[]) => {
          console.log({
            selectedRowKeys: selectedRowKeys.map((x) => x?.toString()).join(", "),
            selectedRows,
          });
          dispatch({
            type: "set-selected-row-keys",
            payload: { selectedRowKeys },
          });
        },
        type: "checkbox",
        selectedRowKeys: state.selectedRowKeys,
        selections: [
          {
            key: Table.SELECTION_ALL,
            text: "Select all Data",
            onSelect: (changableRowKeys) => {
              dispatch({ type: "set-all-data-selected" });
            },
          },

          Table.SELECTION_NONE,
        ],
      };
      return rowSelection;
    }, [isSelectable, state.selectedRowKeys]);


    /**
   * React to changing query params
   */
     useEffect(() => {
      if (!shouldLoadData || isLoading) {
        return;
      }
      loadRecords({
        page,
        limit,
        sort,
        filter,
      });
    }, [isLoading, shouldLoadData, loadRecords, page, limit, sort, filter]);
  
  
  return {
    dispatch,
    state,
    form,
    helpers: {
      getRowKey,
      isEditing: isEditingRow,
    },
    handlers: {
      loadRecords: (() => undefined) as any, //TODO: Deprecate
      updateRecord,
      createRecord,
      deleteRecord,
      saveRecord,
      onTableChange,
      startEditRecord,
      cancelEditRecord,
      onEditingRowValuesChange,
      setSearchTerm,
      clearSearchTerm,
    },
    props: {
      columns: mergedColumns,
      isEditable: isEditable,
      rowSelection: mergedRowSelection,
      rowKey: _rowKeyValueField,
    },
  };
};

export const EditableTableContext = createContext<IEditableTableContext<any>>(
  {} as IEditableTableContext<any>
);
