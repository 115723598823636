import { useContext, useEffect, useReducer } from "react";
import { SupportedRequestQueryParams } from "../../services/api/api.types";
import { AppContext } from "../hoc/app.hoc";
import { DeliveryModel } from "../../models";
import { API } from "../../services/api/api";
import { APIHookActions, APIHookState, apiReducer } from "./hook.types";

export type GetDeliveryDatesParams =
  SupportedRequestQueryParams<DeliveryModel>["GetMany"];

export interface DeliveryDatesProps {
  queryParams?: GetDeliveryDatesParams;
  shouldLoad?: boolean;
}
export interface IDeliveryDatesState {
  selectOptionsData: { label: string; key: string; value: string }[];
  filterData: { text: string; key: string; value: string }[];
  api: APIHookState<DeliveryModel>;
}

export type DeliveryDatesActions =
  | APIHookActions<DeliveryModel>
  | {
      type: "set-route-filter";
      payload: { routeUUID?: string; shouldLoad?: boolean };
    };

export const deliveryDatesReducer = (
  state: IDeliveryDatesState,
  action: DeliveryDatesActions
): IDeliveryDatesState => {
  const { api: apiState, ...restOfState } = state;
  const updatedAPIState = apiReducer<DeliveryModel>(
    apiState,
    action as APIHookActions<DeliveryModel>
  );
  let nextState: IDeliveryDatesState = { ...restOfState, api: updatedAPIState };

  switch (action.type) {
    case "set-route-filter": {
      const nextParams = { ...nextState.api.queryParams };
      const shouldLoad = action.payload.shouldLoad ?? true;
      nextParams.filter = !action.payload.routeUUID
        ? undefined
        : {
            field: "route",
            operator: "$eq",
            value: action.payload.routeUUID,
          };
      nextState.api = apiReducer<DeliveryModel>(apiState, {
        type: "set-params",
        payload: { queryParams: nextParams, shouldLoad },
      });
      break;
    }
    case "set-raw-response-data": {
      const seen = new Set();
      nextState.filterData = [];
      nextState.selectOptionsData = [];

      action.payload.data.forEach((x) => {
        if (!x.delivery_date_formatted) return;

        nextState.selectOptionsData.push({
          label: x.delivery_date_formatted,
          value: x.uuid,
          key: x.uuid,
        });

        if (seen.has(x.delivery_date_formatted)) return;

        seen.add(x.delivery_date_formatted);

        const formattedDBDate = x.delivery_date.format("YYYY-MM-DD");
        nextState.filterData.push({
          text: x.delivery_date_formatted,
          value: formattedDBDate,
          key: formattedDBDate,
        });
      });
      break;
    }
  }
  return nextState;
};

const loadData = async (
  api: API,
  dispatch: React.Dispatch<DeliveryDatesActions>,
  requestParams?: GetDeliveryDatesParams
) => {
  dispatch({ type: "lock-loading" });

  const response = await api.deliveryDates.getMany(requestParams);

  dispatch({ type: "set-raw-response-data", payload: response });
};

const exportData = (
  api: API,
  dispatch: React.Dispatch<DeliveryDatesActions>
) => {
  dispatch({ type: "lock-export" });

  api.deliveryDates.downloadExport(undefined, "dates-export").subscribe({
    next: (result) => {
      // Do something if needed
    },
    complete: () => dispatch({ type: "unlock-export" }),
  });
};

export const useDeliveryDates = (props?: DeliveryDatesProps) => {
  const { api } = useContext(AppContext);

  const [state, dispatch] = useReducer(deliveryDatesReducer, {
    selectOptionsData: [],
    filterData: [],
    api: {
      queryParams: props?.queryParams,
      shouldLoadData: props?.shouldLoad,
      importParams: api.deliveryDates.importDataEnvelope(),
    },
  });

  const {
    api: {
      isLoading,
      shouldLoadData,
      queryParams,
      shouldExport,
      isExporting,
    },
  } = state;

  /**
   * React to exports
   */
  useEffect(() => {
    if (!shouldExport || isExporting) {
      return;
    }
    exportData(api, dispatch);
  }, [api, isExporting, shouldExport]);
  /**
   * React to changing query params
   */
  useEffect(() => {
    if (!shouldLoadData || isLoading) {
      return;
    }
    loadData(api, dispatch, queryParams);
  }, [api, isLoading, shouldLoadData, queryParams]);

  return [state, dispatch] as const;
};
