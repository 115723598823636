import { PageAction } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { DeliveryItemModel, UpdateDeliveryItemModel, DeliveryModel, LocationModel, DiscrepancyModel, DeliveryItemStatus, DiscrepancyStatus, DiscrepancyReason, DiscrepancyIssuer, DiscrepancyOrderStatus } from "../../models/";
import { PageTemplate } from "./PageTemplate";
import { useContext } from "react";
import { AppContext } from "../hoc/app.hoc";
import { plainToInstance } from "class-transformer";
import { useDiscrepencies } from "../hooks/discrepancies.hook";
import { PageTag } from "../../components/PageTag";
import { withUUID } from "../../services/api/factories";
import { QueryParams } from "../../services/api/api.types";
import { Table, Checkbox, Select, Button, Modal, Input, Drawer, Divider, Row, Col, InputNumber, Tabs } from "antd";
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const DeliveryItems = () => {

  const ctx = useDiscrepencies();
  const { state, dispatch } = ctx;

  const {
    deliveriesOptions,
    orderStatusOptions,
    unresolvedDiscrepancyItems,
    resolvedDiscrepancyItems,
    updateDrawerItem,
    isLoadingFilters,
    isLoadingTable,
    isSavingItems,
    isUpdateDrawerOpen,
    openTab,
    activeDelivery,
    activeLocation,
    activeCustomer,
    activeDeliveryItemStatus,
    locationOptions,
    customerOptions,
    markDrawerIssueClosed,
    emailCustomerDiscrepancy,
    emailCustomerNote,
  } = state;

  const columns = [
    {
      title: "Delivery",
      key: "delivery",
      dataIndex: "delivery",
      render: (v: string, r: DiscrepancyModel) => `${moment(r?.delivery_item?.delivery?.delivery_date).format("MM/DD/YYYY")} ${r?.delivery_item?.route?.name}`,
    },  
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      render: (v: string, r: DiscrepancyModel) => r.delivery_item.location?.name,
    },
    {
      title: "Member",
      key: "member",
      dataIndex: "member",
      render: (v: string, r: DiscrepancyModel) => 
        <>
          {r.issuer === DiscrepancyIssuer.CUSTOMER ?  
          <>
            <span>{`${r.delivery_item.shopify_order?.shopify_customer_name}`}</span>
            <span className="dot-purple"></span>
          </> 
          : `${r.delivery_item.shopify_order?.shopify_customer_name}`}      
        </>   
    },  
    {
      title: "Order",
      key: "order",
      dataIndex: "order",
      render: (v: string, r: DiscrepancyModel) => `${r.delivery_item.shopify_order?.name} ${moment(r.delivery_item.shopify_order?.date_created).format("MM/DD/YYYY")}`,     
      sorter: (a: DiscrepancyModel, b: DiscrepancyModel) => {
        const nameA = a.delivery_item.shopify_order?.name;
        const nameB = b.delivery_item.shopify_order?.name;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },      
    },       
    {
      title: "Vendor",
      key: "vendor",
      dataIndex: "vendor",
      render: (v: string, r: DiscrepancyModel) => r.delivery_item.shopify_order_item?.vendor,
    },
    {
      title: "Qty",
      key: "quantity",
      dataIndex: "quantity",
      render: (v: string, r: DiscrepancyModel) => r.delivery_item.quantity,
    },
    {
      title: "Product",
      key: "product",
      dataIndex: "product",
      render: (v: string, r: DiscrepancyModel) => `${r.delivery_item.shopify_order_item?.title} ${r.delivery_item.shopify_order_item?.variant_title}`,
      sorter: (a: DiscrepancyModel, b: DiscrepancyModel) => {
        const nameA = (`${a.delivery_item.shopify_order_item?.title} ${a.delivery_item.shopify_order_item?.variant_title}`).toUpperCase();
        const nameB = (`${b.delivery_item.shopify_order_item?.title} ${b.delivery_item.shopify_order_item?.variant_title}`).toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },      
    },  
    {
      title: "Lbs",
      key: "weight",
      dataIndex: "weight",
      render: (v: string, r: DiscrepancyModel) => "0.25",
    },      
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (v: string, r: DiscrepancyModel) => r.delivery_item.short > 0 ? "Partially Delivered" : "Delivered",     
    },
    {
      title: "Member Issue",
      key: "issue_reason",
      dataIndex: "issue_reason",
      render: (v: string, r: DiscrepancyModel) => DiscrepancyReason[r.issue_reason],     
    },    
    {
      title: "Delivered",
      key: "delivered",
      dataIndex: "delivered",
      render: (v: string, r: DiscrepancyModel) => r?.delivery_item?.quantity - r?.delivery_item?.short,      
    },     
    {
      title: "Short",
      key: "short",
      dataIndex: "short",
      render: (v: string, r: DiscrepancyModel) => r.delivery_item?.short,      
    }, 
    {
      title: "Rescheduled",
      key: "rescheduled",
      dataIndex: "rescheduled",
      render: (v: string, r: DiscrepancyModel) => 
      <>
        <div>
          <p style={{marginBottom: "0px"}}>{r.delivery_item.rescheduled}</p>
        </div> 
      </>
      ,      
    }, 
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (v: string, r: DiscrepancyModel) => 
        <Button 
        onClick={(e) => {dispatch({type: "open-update-drawer", payload: { item: r }})}} 
        style={{
          border: "none",
          background: "none",
          boxShadow: "none",
        }}
        ><EditOutlined/></Button>                
      ,      
    },             
  ];
/*
  const activeDeliveryFull = deliveriesOptions?.find((delivery: DeliveryModel) => {
    if(delivery.uuid === activeDelivery){
      return delivery;
    }
  }, null);
*/
  return (
    <>
      <PageTitle>Discrepencies & Reported Issues</PageTitle>   
      <div className={'filter-header'}
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <div>
          {
            <Select
              onChange={(value) => dispatch({type: "set-active-delivery", payload: {delivery: value} })}
              loading={isLoadingFilters ? true : false}
              value={activeDelivery}
              style={{
                width: 200,
                marginRight: 50
              }}
              placeholder={"Choose Delivery"}
              showSearch={true}
              optionLabelProp={"label"}
              optionFilterProp={"label"}              
            >
              {deliveriesOptions?.map((delivery: DeliveryModel) => (
                <Option 
                  value={delivery.uuid} 
                  key={`filter-${delivery.uuid}`} 
                  label={`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                >
                  {`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                </Option>
              ))}            
            </Select>         
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-location", payload: {location: value} })}
                loading={isLoadingFilters ? true : false}
                disabled={activeDelivery ? false : true}
                defaultValue={activeLocation}
                value={activeLocation}
                style={{
                  width: 200,
                  marginRight: 50
                }}
                placeholder={activeDelivery ? "Choose Location" : "Choose Delivery"}
              >           
                {locationOptions?.map((location: LocationModel) => (
                  <Option value={location.uuid} key={`filter-${location.uuid}`} >{location.name}</Option>
                ))}            
              </Select>        
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-customer", payload: {customer: value} })}
                loading={isLoadingFilters ? true : false}
                disabled={activeDelivery ? false : true}
                value={activeCustomer}
                style={{
                  width: 200,
                  marginRight: 50
                }}
                placeholder={activeDelivery ? "Choose Customer" : "Choose Delivery"}
              >           
                {customerOptions?.map((customer: string) => (
                  <Option value={customer} key={`filter-${customer}`} >{customer}</Option>
                ))}            
              </Select>         
          }        
        </div>
      </div> 
      <Tabs defaultActiveKey="unresolved"
      style={{
        marginTop: "20px",
        marginBottom: "0"
      }}>
        <TabPane tab="Unresolved" key="unresolved">
          <div className="items-table"
          style={{
            background: "white",
            padding: "1rem",
            marginTop: "2rem"
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <h3>{"Orders"}</h3>
            </div>
            <Table 
            columns={columns} 
            dataSource={unresolvedDiscrepancyItems} 
            loading={isLoadingTable}
            key={"unresolved-items-list"}
            rowKey={(record) => record.uuid}
            scroll={{
              x: true,
            }}
            style={{
              marginTop: "1rem"
            }}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.note}
                </p>
              ),
              rowExpandable: (record) => record.note !== null && record.note !== "",
            }}        
            /> 
          </div> 
        </TabPane>
        <TabPane tab="Resolved" key="resolved">
          <div className="items-table"
          style={{
            background: "white",
            padding: "1rem",
            marginTop: "2rem"
          }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <h3>{"Orders"}</h3>
            </div>
            <Table 
            columns={columns} 
            dataSource={resolvedDiscrepancyItems} 
            loading={isLoadingTable}
            key={"resolved-items-list"}
            rowKey={(record) => record.uuid}
            scroll={{
              x: true,
            }}
            style={{
              marginTop: "1rem"
            }}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.note}
                </p>
              ),
              rowExpandable: (record) => record.note !== null && record.note !== "",
            }}        
            /> 
          </div> 
        </TabPane>     
      </Tabs> 
      <Drawer 
        title="Update Order" 
        placement="right" 
        onClose={() => dispatch({type: "close-update-drawer", payload: {}})}
        headerStyle={{
          fontSize: "22px",
        }}
        visible={isUpdateDrawerOpen}        
        footer={
          <>
          <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Button 
              className="btn btn-light"
              onClick={() => dispatch({type: "close-update-drawer", payload: {}})}
              loading={isSavingItems}
            >CANCEL</Button>
            <Button 
            className="btn btn-primary"
            onClick={() => dispatch({type: "save-drawer-item", payload: {}})}
            loading={isSavingItems}
            style={{
              marginLeft: "10px",               
            }}
            >UPDATE ORDER</Button>          
          </div>
          </>
        }
      >
        <div>
          <h3>Order Information</h3>
          <p><b>Delivery: </b><span>{`${moment(updateDrawerItem?.delivery?.delivery_date).format("MM/DD/YYYY")} ${updateDrawerItem?.route?.name}
          ${updateDrawerItem?.location?.name}`}</span></p>
          <p><b>Customer: </b><span>{updateDrawerItem?.shopify_order?.shopify_customer_name}</span></p>
          <p><b>Product: </b><span>{`${updateDrawerItem?.quantity} x ${updateDrawerItem?.shopify_order_item?.title} ${updateDrawerItem?.shopify_order_item?.variant_title}`}</span></p>
          { updateDrawerItem.status ? <p><b>Status: </b><span>{`${updateDrawerItem?.status}`}</span></p> : <></> }
        </div>
        <Divider />
        <div>
          <h3>Order Notes</h3>
          {updateDrawerItem?.notes !== "" ? updateDrawerItem?.notes?.split(";").map((item) => {
            return(
              <div style={{ marginBottom: "1rem" }}>
                <p><b>{item.split(":")[0]}</b></p>
                <p>{item.split(":")[1]}</p>
              </div>
            )
          }) : <p>None</p>}            
        </div> 
        <Divider />
        <div>
          <h3>Update Order</h3>
          <div>
            <Row>
              <Col span={16}>
                <h4>Order Status</h4>
                <Select
                  value={updateDrawerItem.status}
                  onChange={(value: DeliveryItemStatus) => {
                    updateDrawerItem.status = value;
                    dispatch({type: "set-drawer-item", payload: { item: updateDrawerItem }})
                  }}
                  style={{
                    width: 200,
                  }}
                  placeholder={"Choose Status"}
                >           
                  {Object.keys(DeliveryItemStatus).map(key => (
                      <option
                          key={key}
                          value={key}
                      >
                          {key}
                      </option>
                      )
                  )}           
                </Select>    
              </Col>
              <Col span={8}>
                <h4>Short</h4>
                <InputNumber 
                  min={0} 
                  max={updateDrawerItem ? updateDrawerItem?.quantity : 0}
                  value={updateDrawerItem ? updateDrawerItem?.short : 0} 
                  onChange={(value) => {
                    updateDrawerItem.short = value;
                    dispatch({type: "set-drawer-item", payload: { item: updateDrawerItem }})
                  }} 
                />   
              </Col>                      
            </Row>
            <Row style={{marginTop: "10px"}}>
              <Col span={24}>
                <h4>Reschedule Delivery Date</h4>            
                <Select
                  value={updateDrawerItem?.delivery?.uuid}
                  onChange={(value) => {
                    const newDelivery = deliveriesOptions?.find((opt) => {if(value === opt.uuid) return opt});
                    if(newDelivery){
                      updateDrawerItem.delivery = newDelivery;
                      updateDrawerItem.rescheduled = `${moment().format("MM/DD/YYYY")}`;
                      dispatch({type: "set-drawer-item", payload: { item: updateDrawerItem }})
                    }
                  }}                
                  style={{
                    width: "100%",
                  }}
                  placeholder={"Choose Date"}
                >           
                {deliveriesOptions?.map((delivery: DeliveryModel) => (
                  <Option 
                    value={delivery.uuid} 
                    key={`drawer-item-${delivery.uuid}`} 
                    label={`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                  >
                    {`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                  </Option>
                ))}               
                </Select>             
              </Col>
            </Row>
            <Row style={{marginTop: "10px"}}>
              <Col span={24}>
                <Checkbox 
                  checked={markDrawerIssueClosed}
                  onChange={(e) => {      
                    dispatch({ type: "set-close-drawer-issue", payload: { close: e.target.checked } });
                  }}                
                  key={`resolve_drawer_issue_check`}
                >Mark Issue as Resolved</Checkbox>              
              </Col>
            </Row> 
            <Divider />            
            <Row style={{marginTop: "10px"}}>
              <Col span={24}>
                <h4>Customer Message</h4>
                <TextArea 
                  value={emailCustomerNote}
                  onChange={(e) => {      
                    dispatch({ type: "set-email-customer-note", payload: { note: e.target.value } });
                  }}                     
                  key={"drawer-note-text"} 
                  placeholder={"This note will be emailed to the customer"}
                  rows={4} 
                />       
                <Checkbox 
                  checked={emailCustomerDiscrepancy}
                  onChange={(e) => {      
                    dispatch({ type: "set-email-customer", payload: { close: e.target.checked } });
                  }}                  
                  key={`email_customer_drawer_check`}
                >Email Update to Customer</Checkbox>                       
              </Col>
            </Row>                        
          </div>
        </div>
      </Drawer> 
    </>
  );

};
export default DeliveryItems;  



          /*
          extra filter
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-delivery-item-status", payload: {status: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeDeliveryItemStatus}
                style={{
                  width: 200,
                }}
                placeholder={"Order Status"}
              >                         
                {(Object.keys(DiscrepancyOrderStatus) as Array<keyof typeof DiscrepancyOrderStatus>).map((status) => {
                  <Option value={status} key={`filter-${status}`} >{status}</Option>
                })}            
              </Select>         
          }
          */  