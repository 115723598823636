import { plainToInstance } from "class-transformer";
import { useContext } from "react";
import { AddressCell } from "../../components/AddressCell";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { CreateLocationModel, LocationModel, UpdateLocationModel } from "../../models";
import { TableRowData } from "../../utils/antd.types";
import { AppContext } from "../hoc/app.hoc";
import { useEditableTable } from "../hooks/editable-table.hook";
import { PageTemplate } from "./PageTemplate";

export const Locations = () => {
  const { api } = useContext(AppContext);
  const editableTableCtx = useEditableTable({
    rowKeyValueField: 'uuid',
    createEmptyRecord: () => {
      const record = new LocationModel();
      return record;
    },
    defaultQuery: {
      page: 1,
      limit: 10,
      sort: { field: 'name', order: "ASC" },
    },
    loadRecords: async ({ page, limit, sort, filter }) => {
      const response = await api.locations.getMany({
        page,
        limit,
        sort,
        filter,
      });
      return response;
    },
    updateRecord: async (record, tableRow) => {
      const data = plainToInstance(UpdateLocationModel, record)
      return api.locations.updateOne(tableRow.uuid, data);
    },
    createRecord: async (record, tableRow) => {
      const data = plainToInstance(CreateLocationModel, record)
      return api.locations.createOne(data);
    },
    deleteRecord: async (record, tableRow) => {
      console.log("Not implemented");
    },
    isEditable: true,
    columns: [
        {
          title: 'Location',
          key: "name",
          dataIndex: "name",
          editable: true,
          width: 150,
        },
        {
          title: "Customer Tag",
          dataIndex: "customer_tag",
          key: "locations-customer_tag",
          editable: true,
          width: 150,
          disabled: (tableRow,formValues,editMode) => {
            // Never disabled on create mode
            if (editMode === 'create' ) return false

            // If its empty in edit mode then we can enable it
            if(!tableRow.customer_tag) return false;

            return true
          }
          // isEditableOnlyWhenNew: true,
        },
        {
          title: "Address",
          key: "shopify_location_address",
          render: (value, record: TableRowData<LocationModel>) => {
            if(!record?.shopify_location) return <></>
            const { address1, address2, city, province_code, zip} = record?.shopify_location;
            return <AddressCell {...{address1, address2, city, province_code, zip}} />
          },
          width: 400,
        },
        {
          title: 'Time',
          key: 'pickup_time',
          dataIndex: 'pickup_time',
          editable: true,
          inputType: 'time',
          width: 200,
          render: (value, record) => record.pickup_time.format('hh:mm a')
        },
        {
          title: "Note",
          dataIndex: "note",
          key: "note",
          editable: true,
          width: 600,
        },
      ],
  });
  
  return (
    <PageTemplate>
      {{
        title: <PageTitle>Locations</PageTitle>,
      content: (
        <EditableTable ctx={editableTableCtx}  scrollX={1500} />
        ),
      }}
    </PageTemplate>
  );
};
export default Locations;
