import { ILabeledPageFieldProps, LabeledPageField } from "./LabeledPageField";
import { Grid2Col, IGrid2ColProps } from "./layout/Grid";

export interface IPageFieldsProps {
  children: ILabeledPageFieldProps[]
  grid?: IGrid2ColProps;
}

export const PageFields = (props:IPageFieldsProps) => {
  const { children, grid } = props;
  return(
    <Grid2Col {...grid}>
      {children.map((x,i) => <LabeledPageField {...x} key={`page-field-${i}-${x.text}`} />)}
    </Grid2Col>
  )
}