import { useContext, useEffect, useReducer } from "react";
import { SupportedRequestQueryParams } from "../../services/api/api.types";
import { AppContext } from "../hoc/app.hoc";
import { VendorModel } from "../../models";
import { APIHookActions, APIHookProps, APIHookState, apiReducer } from "./hook.types";
import { API } from "../../services/api/api";


export type GetVendorParams =
  SupportedRequestQueryParams<VendorModel>["GetMany"];

export interface VendorProps extends APIHookProps<VendorModel> {
  queryParams?: GetVendorParams;
}
export interface IVendorState {
  api: APIHookState<VendorModel>;
  filterData: { text:string; value: string; key: string }[];
  selectOptionsData: { label:string; value: string; key: string }[]
}


export type VendorActions = APIHookActions<VendorModel> 

const reducer = (
  state: IVendorState,
  action: VendorActions
): IVendorState => {

  const { api: apiState, ...restOfState } = state;
  const nextState = {
    ...restOfState,
    api: apiReducer<VendorModel>(apiState, action as APIHookActions<VendorModel>)
  };

  switch(action.type){
      case "set-raw-response-data":  {
        nextState.filterData = action.payload.data.map(({uuid, name}) => ({ text: name, value: uuid, key: uuid }))
        nextState.selectOptionsData = action.payload.data.map(({uuid, name}) => ({ label:name, value:uuid, key: uuid }))
        break;
      }

  }
  return nextState;
};


const loadData = async (
  api: API,
  dispatch: React.Dispatch<VendorActions>,
  requestParams?: GetVendorParams
) => {
  dispatch({ type: "lock-loading" });

  const data = await api.vendors.getMany(requestParams)
  
  dispatch({ type: "set-raw-response-data", payload: data });
};

/**
 * Vendors hook
 */
export const useVendors = (props?: VendorProps) => {
  const { api } = useContext(AppContext);

  const [state, dispatch] = useReducer(reducer, {
    api: {
      queryParams: props?.queryParams,
      shouldLoadData: props?.shouldLoad,
    },
    filterData: [],
    selectOptionsData: [],
  });
  const { api: { isLoading, shouldLoadData, queryParams } } = state;

  /**
   * React to changing query params
   */
  useEffect(() => {
    if (!shouldLoadData || isLoading) {
      return;
    }
    loadData(api, dispatch, queryParams);
  }, [api, isLoading, shouldLoadData, queryParams]);


  return [state, dispatch] as const;
};
