import styled from 'styled-components';

export const PoweredBy = styled.div`
  img {
    width: 100%;
    max-width: 90px;
  }
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  > div {
    max-width: 80%;
    margin: auto;
    padding-bottom: 15px;
  }
`;
