import React from "react";
import { APIModels } from "../../services/api/api";
import { GetManyResponse, SupportedRequestQueryParams } from "../../services/api/api.types";
import { TableState } from "./editable-table.hook";


export type ExportParams<TModel> = { selectedRowKeys: React.Key[] } | { tableState: TableState<TModel> };

export interface APIHookProps<TModel extends APIModels> {
  queryParams?: SupportedRequestQueryParams<TModel>["GetMany"];
  shouldLoad?: boolean;
}
export interface APIHookState<TModel extends APIModels> {
  rawResponseData?: GetManyResponse<TModel>;
  isLoading?: boolean;
  shouldLoadData?: boolean;
  queryParams?: SupportedRequestQueryParams<TModel>["GetMany"];
  shouldExport?: boolean;
  isExporting?: boolean;
  exportParams?: ExportParams<TModel>,
  exportTemplateParams?: ExportParams<TModel>,
  shouldLoadImport?: boolean;
  importParams?: { 
    name: string,
    action: string,
    headers: {
      authorization: string,
    },
    file?: any;
   }
  
}

export type APIHookActions<TModel extends APIModels> =
   { type: "load-data",  }
  | {
      type: "set-params";
      payload: { queryParams?: SupportedRequestQueryParams<TModel>["GetMany"]; shouldLoad?: boolean };
    }
  | { type: "lock-loading" }
  | { type: "unlock-loading" }
  | { type: "set-raw-response-data"; payload: GetManyResponse<TModel> }
  | { type: 'set-export-params', payload: { selectedRowKeys: React.Key[] } | { tableState: TableState<TModel> } }
  | { type: 'load-export' }
  | { type: 'lock-export' }
  | { type: 'unlock-export' }
  | { type: 'set-import-params', payload: APIHookState<TModel>['importParams'] }
  | { type: 'load-import' }


export type IAPIHookReducer<
  TModel extends APIModels,
  TState extends APIHookState<TModel> = APIHookState<TModel>,
  TAction extends APIHookActions<TModel> = APIHookActions<TModel>,
> = (currentState: TState, action: TAction) => TState


export const apiReducer = <
  TModel extends APIModels,
>(
  currentState: APIHookState<TModel>,
  action: APIHookActions<TModel>,
): APIHookState<TModel> => {
  const { shouldLoadData, shouldExport,shouldLoadImport, ...restOfState } = currentState;
  let nextState: APIHookState<TModel> = { ...restOfState };
  
  switch (action.type) {
    case "set-params": {
      nextState.queryParams = action.payload.queryParams;
      if (action.payload.shouldLoad) {
        return apiReducer<TModel>(nextState, { type: "load-data" });
      }
      break;
    }
    case "load-data": {
      nextState.shouldLoadData = true;
      break;
    }
    case "lock-loading": {
      nextState.isLoading = true;
      break;
    }
    case "unlock-loading": {
      nextState.isLoading = false;
      break;
    }
    case "set-raw-response-data": {
      nextState.rawResponseData = action.payload;
      return apiReducer<TModel>(nextState, { type: "unlock-loading" });
    }
    case 'set-export-params': {
      nextState.exportParams = action.payload;
      return apiReducer<TModel>(nextState, { type: "load-export" });
    }
    case 'load-export': {
      nextState.shouldExport = true
      break;
    }
    case 'lock-export': {
      nextState.isExporting = true;
      break;
    }
    case 'unlock-export': {
      nextState.isExporting = false;
      break;
    }
    case 'set-import-params': {
      nextState.importParams = action.payload;
      return apiReducer<TModel>(nextState, { type: "load-import" });
    }
    case 'load-import': {
      nextState.shouldLoadImport = true
      break;
    }
  }

  return nextState
}

