import { ClassConstructor, plainToInstance } from "class-transformer";
import { APIError } from "../../infrastructure/exceptions"
import { GetManyResponse } from "./api.types";

export const requestHandler = async (requestURL:string,requestOptions:RequestInit) => {
  
  const response = await fetch(requestURL, requestOptions);
  
  const data = await response.json();

  if (response.status >= 200 && response.status < 300) {
    return data;
  }
  throw new APIError(requestURL,requestOptions, response, data)
}

export const getManyBodyParser = <T = any>(rawData: GetManyResponse<T>, ModelClass: ClassConstructor<T> ) => {
  
  rawData.data = plainToInstance(ModelClass, rawData.data)
  
  return rawData
}