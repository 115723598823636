import { Type } from "class-transformer";
import { UUIDField } from ".";
import { XBaseModel } from "./base.model";
import { LocationModel } from "./location.model";

export class RouteModel extends XBaseModel {
  consumer_uuid!: UUIDField;
  name!: string;
  customer_tag!: string;
  @Type(() => LocationModel)
  locations?: LocationModel[] = []
}

export class CreateRouteModel {
  name!: string;
  customer_tag!: string;
  locations?: UUIDField[]
}

export class UpdateRouteModel {
  name?: string;
  customer_tag?: string;
  locations?: UUIDField[]
}