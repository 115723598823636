
export const getFileNameFromResponseHeader = (contentDisposition?: string | null) => {
  if(!contentDisposition) return;
    
  const fileNameTokens = contentDisposition.match(/filename="(.*?)"(\s|$)/)
  if(!(fileNameTokens && fileNameTokens[1])) return;
  return fileNameTokens[1];
}
export function downloadThroughAnchorLink(fileUrl: string, fileName?: string) {
  var a = document.createElement("a");
  a.href = fileUrl;
  if(fileName){
    a.setAttribute("download", fileName);
  }
  a.click();
}

