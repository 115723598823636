import { RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  CreateOrderScheduleModel,
  OrderScheduleModel,
  UpdateOrderScheduleModel,
} from "../../models";
import {
  OrderScheduleShopifyProductVariantModel,
  UpdateOrderScheduleShopifyProductVariantModel,
} from "../../models/order-schedule-product-variants.model";
import { SupportedRequestQueryParams } from "./api.types";
import { CrudApi } from "./crud.api";
import { requestHandler } from "./response-handler";

type OrderScheduleVariantsQueryParams =
  SupportedRequestQueryParams<OrderScheduleShopifyProductVariantModel>["GetMany"];
export class OrderSchedulesApi extends CrudApi<
  OrderScheduleModel,
  CreateOrderScheduleModel,
  UpdateOrderScheduleModel
> {
  async getVariants(
    orderScheduleId: string,
    queryParams?: OrderScheduleVariantsQueryParams
  ) {
    const query = RequestQueryBuilder.create(queryParams as any).query();
    const requestURL =
      `${
        this.config.endpoints[this.pathKey].path
      }/${orderScheduleId}/variants` + (query ? `?${query}` : "");

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "GET",
    };

    const data = await requestHandler(requestURL, requestOptions);

    return data;
  }

  async updateVariant(
    orderScheduleId: string,
    shopifyId: string,
    request: UpdateOrderScheduleShopifyProductVariantModel
  ) {
    const body = JSON.stringify(request);
    const requestURL = `${
      this.config.endpoints[this.pathKey].path
    }/${orderScheduleId}/variants/${shopifyId}`;

    const requestOptions: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        ...this.auth.authHeader(),
      },
      method: "PATCH",
      body: body,
    };
    const data = await requestHandler(requestURL, requestOptions);
    return data;
  }

  importVariantsDataEnvelope = (orderScheduleId: string) => {
    const requestURL = `${
      this.config.endpoints[this.pathKey].path
    }/${orderScheduleId}/variants/import`;
    return this.importDataEnvelope(
      requestURL
    )
  };

  downloadVariantsDataExport = (orderScheduleId: string) => {
    const requestURL = `${
      this.config.endpoints[this.pathKey].path
    }/${orderScheduleId}/variants/export`;
    return this.downloadExport(requestURL);
  };
}
