import { Button, message, Modal, notification } from "antd";
import { ModalFunc } from "antd/lib/modal/confirm";
import React, { ReactNode } from "react";
import styled from "styled-components";

const ImportResultsStyled = styled.ul`
  list-style: none;
  > li > ul {
    list-style: none;
  }
  .row-label {
    font-weight: bold;
  }
  .row-skipped {
    
  }
  .row-error {

  }
`

const triggerModal = (
  modal: ModalFunc,
  title: ReactNode,
  results: ReactNode,
  details: ReactNode
) => {
  modal({
    title: title,
    width: 800,
    content: (
      <>
        {results}
        <ImportResultsStyled>
          {details}
        </ImportResultsStyled>
      </>
    ),
  });
};



export const onUpload = (info: any, cb: () => void) => {
  if (info.file.status === "done") {
    const response = info.file.response as {
      errors: { error: string; index: number; message: string; step: string }[];
      skipped: { index: number; message: string; info: string }[];
      success: { index: number; message: string }[];
    };
    const skipped = response?.skipped;
    const errors = response?.errors;
    const success = response?.success;
    const updated = response.success.filter(
      (x) => x.message.toLowerCase() === "updated"
    );
    const created = response.success.filter(
      (x) => x.message.toLowerCase() === "created"
    );
    const deleted = response.success.filter(
      (x) => x.message.toLowerCase() === "deleted"
    );

    let modal = Modal.warn;
    let notifier = notification.success;
    let title = "Import completed with warnings";
    let joined: {
      [index: number]: {
        skipped: typeof response["skipped"];
        errors: typeof response["errors"];
      };
    } = {};

    if (skipped?.length > 0) {
      modal = Modal.warn;
      notifier = notification.info;
      skipped.forEach((x) => {
        if (!(x.index in joined)) {
          joined[x.index] = { skipped: [], errors: [] };
        }
        joined[x.index].skipped.push(x);
      });
    }
    if (errors?.length > 0) {
      modal = Modal.error;
      notifier = notification.error;
      errors.forEach((x) => {
        if (!(x.index in joined)) {
          joined[x.index] = { skipped: [], errors: [] };
        }
        joined[x.index].errors.push(x);
      });
      title = "Import aborted. Please ensure that dates are all formatted as 'YYYY-MM-DD.";
    }

    const details = Object.keys(joined).map((_index) => {
      const index = parseInt(_index);

      return (
        <li key={"result-" + index}>
          <div className="row-label">#{index + 1}</div>
          <ul>
            {joined[index].errors.map((e) => (
              <li key={e.message + e.index}>
                {e.error}: {e.message}
              </li>
            ))}
          </ul>
          <ul>
            {joined[index].skipped.map((s) => (
              <li key={s.message + s.index}>
                {s.message}: {s.info}
              </li>
            ))}
          </ul>
        </li>
      );
    });

    const results = (
      <p style={{ fontWeight: "bold" }}>        
        Updated: {updated.length}, Created: {created.length}, Deleted:{" "}
        {deleted.length}, Skipped: {skipped?.length ?? 0}, Errors:{" "}
        {errors?.length ?? 0}
      </p>
    );
    const importKey = `import-${Date.now()}`;
    notifier({
      duration: 0,
      message: "Import completed",
      description: <>{results}</>,
      key: importKey,
    });
    triggerModal(modal, title, results, details);
    cb();
  } else if (info.file.status === "error") {
    message.error("An error occured while uploading.'");
  }
};
