import 'reflect-metadata';
import './decorators';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/antd.less';
import './assets/styles/index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { APIConfigFactory } from './services/api/api.config';
import { AuthService } from './services/api/auth.service';
import { Api } from './services/api/api';
import Cookies from "js-cookie";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from 'styled-components/cssprop' 

(async () => {
  
  const apiConfig = APIConfigFactory(process.env.REACT_APP_API_ROOT);
  const token = Cookies.get("token");
  const auth = new AuthService(apiConfig, token);
  const api = Api(apiConfig, auth);
  return api;

})().then((api) => {
  
  ReactDOM.render(
    <React.StrictMode>
      <App api={api} />
    </React.StrictMode>,
    document.getElementById('root')
  );
  
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
