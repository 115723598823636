import moment from "moment";
import { UUIDField } from ".";
import { XBaseModel } from "./base.model";

export enum UserType {
  ADMIN = "ADMIN",
  DRIVER = "DRIVER",
  UNDEFINED = "UNDEFINED",
}

export class UserModel extends XBaseModel {
	consumer_uuid!: UUIDField;
	type!: UserType; 
}