import { PageAction } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { DeliveryItemModel, UpdateDeliveryItemModel, DeliveryModel, LocationModel } from "../../models/";
import { PageTemplate } from "./PageTemplate";
import { useContext } from "react";
import { AppContext } from "../hoc/app.hoc";
import { plainToInstance } from "class-transformer";
import { useDeliveryItems } from "../hooks/delivery-items.hook";
import { PageTag } from "../../components/PageTag";
import { withUUID } from "../../services/api/factories";
import { QueryParams } from "../../services/api/api.types";
import { Table, Checkbox, Select, Button, Modal, Input, Drawer, Divider, Row, Col, InputNumber } from "antd";
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const DeliveryItems = () => {

  const ctx = useDeliveryItems();
  const { state, dispatch } = ctx;

  const {
    deliveryItems,
    deliveriesOptions,
    locationOptions,
    customerOptions,
    productOptions,
    vendorOptions,
    isLoadingTable,
    isSavingItems,
    isLoadingFilters,
    isRescheduleOpen,
    editingTableEnabled,
    activeDelivery,
    activeLocation,
    activeCustomers,  
    activeVendor,  
    activeProduct,   
    activeRescheduleDelivery,
    notifyOnReschedule,  
    rescheduleDeliveriesOptions,
    itemDrawerOpen,
    drawerItem,
    selectedRowKeys,
    isRescheduleItemsOpen,
    noteText,
    isSavingDrawerItem
  } = state;

  const columns = [
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      render: (v: string, r: DeliveryItemModel) => r.location?.name,
      sorter: (a: DeliveryItemModel, b: DeliveryItemModel) => {
        const nameA = a.location?.name.toUpperCase();
        const nameB = b.location?.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },
    },
    {
      title: "Member",
      key: "member",
      dataIndex: "member",
      render: (v: string, r: DeliveryItemModel) => <>{
        r?.customer_confirmed === true ? 
          <>
            <span>{r.shopify_order?.shopify_customer_name}</span>
            <span className="dot-green"></span>
          </> : r.shopify_order?.shopify_customer_name
      }</>,
      sorter: (a: DeliveryItemModel, b: DeliveryItemModel) => {
        const nameA = a.shopify_order?.shopify_customer_name.toUpperCase();
        const nameB = b.shopify_order?.shopify_customer_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },       
    },    
    {
      title: "Vendor",
      key: "vendor",
      dataIndex: "vendor",
      render: (v: string, r: DeliveryItemModel) => r.vendor?.name,
      sorter: (a: DeliveryItemModel, b: DeliveryItemModel) => {
        const nameA = a.vendor.name.toUpperCase();
        const nameB = b.vendor.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },         
    },
    {
      title: "Qty",
      key: "quantity",
      dataIndex: "quantity",
      render: (v: string, r: DeliveryItemModel) => r.quantity,
    },
    {
      title: "Product",
      key: "product",
      dataIndex: "product",
      render: (v: string, r: DeliveryItemModel) => 
        <>
          {r.short && r.short > 0 ? 
            <>
              <span>{`${r.shopify_order_item?.title} ${r.shopify_order_item?.variant_title}`}</span>
              <span className="dot-brown"></span>
            </> 
            : `${r.shopify_order_item?.title} ${r.shopify_order_item?.variant_title}`}
        </>
      ,
      sorter: (a: DeliveryItemModel, b: DeliveryItemModel) => {
        const nameA = (`${a.shopify_order_item?.title} ${a.shopify_order_item?.variant_title}`).toUpperCase();
        const nameB = (`${b.shopify_order_item?.title} ${b.shopify_order_item?.variant_title}`).toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
      },      
    },  
    {
      title: "Lbs",
      key: "weight",
      dataIndex: "weight",
      render: (v: string, r: DeliveryItemModel) => "0.25",
    },      
    {
      title: "Delivered",
      key: "delivered",
      dataIndex: "delivered",
      render: (v: string, r: DeliveryItemModel) => 
        <Checkbox 
        disabled={editingTableEnabled ? false : true}
        onChange={(e) => {
          r.back_order = false;
          r.no_show = false;
          r.left_at_location = false;           
          r.delivered = e.target.checked;
          dispatch({ type: "set-delivery-item", payload: { updatedItem: r } });
        }}
        checked={r.delivered}
        key={`delivered_${r.uuid}`}
        ></Checkbox>        
      ,      
    },
    {
      title: "L.L",
      key: "left_at_location",
      dataIndex: "left_at_location",
      render: (v: string, r: DeliveryItemModel) => 
        <Checkbox 
        disabled={editingTableEnabled ? false : true}
        onChange={(e) => {
          r.back_order = false;
          r.no_show = false;
          r.delivered = false;              
          r.left_at_location = e.target.checked;
          dispatch({ type: "set-delivery-item", payload: { updatedItem: r } });
        }}
        checked={r.left_at_location}
        key={`left_at_location_${r.uuid}`}
        ></Checkbox>        
      ,      
    },
    {
      title: "N.S",
      key: "no_show",
      dataIndex: "no_show",
      render: (v: string, r: DeliveryItemModel) => 
        <Checkbox 
        disabled={editingTableEnabled ? false : true}
        onChange={(e) => {
          r.back_order = false;
          r.left_at_location = false;
          r.delivered = false;          
          r.no_show = e.target.checked;
          dispatch({ type: "set-delivery-item", payload: { updatedItem: r } });
        }}
        checked={r.no_show}
        key={`no_show_${r.uuid}`}
        ></Checkbox>        
      ,      
    },  
    {
      title: "B.O",
      key: "back_order",
      dataIndex: "back_order",
      render: (v: string, r: DeliveryItemModel) => 
        <Checkbox 
        disabled={editingTableEnabled ? false : true}
        onChange={(e) => {
          r.no_show = false;
          r.left_at_location = false;
          r.delivered = false;
          r.back_order = e.target.checked;
          r.customer_confirmed = false;
          dispatch({ type: "set-delivery-item", payload: { updatedItem: r } });
        }}
        checked={r.back_order}
        key={`back_order_${r.uuid}`}
        ></Checkbox>        
      ,      
    },  
    {
      title: "Short",
      key: "short",
      dataIndex: "short",
      render: (v: string, r: DeliveryItemModel) =>              
        <InputNumber 
          disabled={editingTableEnabled ? false : true} 
          min={0} 
          max={r ? r?.quantity : 0}
          defaultValue={r ? r?.short : 0} 
          onChange={(value) => {
            r.short = value;
            dispatch({type: "set-delivery-item", payload: { updatedItem: r }})
          }} 
        />            
      ,      
    },   
    {
      title: "Notes",
      key: "notes",
      dataIndex: "notes",
      render: (v: string, r: DeliveryItemModel) => 
        <>
          <Button 
            onClick={(e) => {dispatch({type: "open-notes-drawer", payload: { item: r }})}} 
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
            }}
            ><EditOutlined/>
          </Button> 
          {r.notes ? <span className="dot-green"></span> : ""}
        </>
      ,      
    },
    {
      title: "Rescheduled",
      key: "rescheduled",
      dataIndex: "rescheduled",
      render: (v: string, r: DeliveryItemModel) => 
      <>
        <div>
          <p style={{marginBottom: "0px"}}>{r.rescheduled}</p>
        </div> 
      </>
      ,      
    },              
  ];

  const activeDeliveryFull = deliveriesOptions?.find((delivery: DeliveryModel) => {
    if(delivery.uuid === activeDelivery){
      return delivery;
    }
  }, null);

  return (
    <>
      <PageTitle>Deliveries</PageTitle>
      <div className={'filter-header'}
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <div>
          {
            <Select
              onChange={(value) => dispatch({type: "set-active-delivery", payload: {delivery: value} })}
              loading={isLoadingFilters ? true : false}
              value={activeDelivery}
              style={{
                width: 200,
                marginRight: 20
              }}
              placeholder={"Choose Delivery"}
              showSearch={true}
              optionLabelProp={"label"}
              optionFilterProp={"label"}              
            >
              {deliveriesOptions?.map((delivery: DeliveryModel) => (
                <Option 
                  value={delivery.uuid} 
                  key={`filter-${delivery.uuid}`} 
                  label={`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                >
                  {`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}
                </Option>
              ))}            
            </Select>         
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-location", payload: {location: value} })}
                loading={isLoadingFilters ? true : false}
                defaultValue={activeLocation}
                value={activeLocation}
                style={{
                  width: 200,
                  marginRight: 20
                }}
                placeholder={"Choose Location"}
                allowClear={true}
              >           
                {locationOptions?.map((location: LocationModel) => (
                  <Option value={location.uuid} key={`filter-${location.uuid}`} >{location.name}</Option>
                ))}            
              </Select>        
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-customers", payload: {customers: value} })}
                loading={isLoadingFilters ? true : false}
                mode={"multiple"}
                value={activeCustomers}
                style={{
                  width: 200,
                  marginRight: 20
                }}
                placeholder={"Choose Customer"}
                allowClear={true}
              >           
                {customerOptions?.map((customer: string) => (
                  <Option value={customer} key={`filter-${customer}`} >{customer}</Option>
                ))}            
              </Select>         
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-vendor", payload: {vendor: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeVendor}
                style={{
                  width: 200,
                  marginRight: 20
                }}
                placeholder={"Choose Vendor"}
                allowClear={true}
              >           
                {vendorOptions?.map((vendor: string) => (
                  <Option value={vendor} key={`filter-${vendor}`} >{vendor}</Option>
                ))}            
              </Select>         
          }
          {
              <Select
                onChange={(value) => dispatch({type: "set-active-product", payload: {product: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeProduct}
                dropdownStyle={{
                  fontSize: "12px",
                  height: "500px"
                }}                
                style={{
                  width: 200,
                  fontSize: "12px"
                }}
                placeholder={"Choose Product"}
                allowClear={true}
                listHeight={500}
              >           
                {productOptions?.map((product: string) => (
                  <Option style={{ fontSize: "12px" }} value={product} key={`filter-${product}`} ><span style={{ fontSize: "12px" }}>{product}</span></Option>
                ))}            
              </Select>         
          }                    
        </div>
        <div>
          <Button 
            className="btn btn-light"
            disabled={!selectedRowKeys || selectedRowKeys.length < 1 ? true: false}
            onClick={() => {dispatch({type: "toggle-reschedule-items-modal", payload: { state: true }})}} 
            style={{ marginRight: "10px" }}
          >Reschedule Items</Button>        
          <Button 
            className="btn btn-light"
            onClick={() => {dispatch({type: "toggle-reschedule-modal", payload: { state: true }})}} 
          >Reschedule Delivery</Button>
        </div>
      </div>
      <div className="items-table"
      style={{
        background: "white",
        padding: "1rem",
        marginTop: "2rem"
      }}>
        <div style={{
          display: "flex",
          justifyContent: "flex-start"
        }}>
          <div style={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
          }}>
            <span className="dot-green" style={{    
                  borderRadius: "10px",
                  height: "14px",
                  width: "14px",
                  position: "relative",
                  top: "0px"
                }}></span>
            <span> Confirmed</span>
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
          }}>
            <span className="dot-brown" style={{    
                  borderRadius: "10px",
                  height: "14px",
                  width: "14px",
                  position: "relative",
                  top: "0px"
                }}></span>
            <span> Short</span>
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
          }}>
            <span className="dot-purple" style={{    
                  borderRadius: "10px",
                  height: "14px",
                  width: "14px",
                  position: "relative",
                  top: "0px"
                }}></span>
            <span> Discrepancy</span>
          </div>                    
        </div>
        <div style={{
          display: "flex",
          justifyContent: "space-between"
        }}>
          <h3>{activeDeliveryFull ? `${moment(activeDeliveryFull.delivery_date).format("MM/DD/YYYY")} ${activeDeliveryFull.route.name} Delivery` : ""}</h3>
          <div>
            <Button 
              className="btn btn-light"
              onClick={() => dispatch({type: "toggle-editable-table", payload: {}})}
            >EDIT</Button>
            <Button 
            className="btn btn-primary"
            onClick={() => dispatch({type: "update-delivery-items", payload: {}})}
            style={{
              marginLeft: "10px",               
            }}
            >UPDATE</Button>
          </div>
        </div>
        <Table 
        columns={columns} 
        dataSource={deliveryItems} 
        loading={isLoadingTable || isSavingItems ? true : false}
        scroll={{
          x: true,
        }}
        style={{
          marginTop: "1rem"
        }}
        rowSelection={{
          onChange: (selectedRowKeys) => dispatch({type: "set-selected-row-keys", payload: {selectedRowKeys: selectedRowKeys}}),
        }}
        /> 
      </div>    
      <Drawer 
        title="Order Notes" 
        placement="right" 
        onClose={() => dispatch({type: "close-notes-drawer", payload: {}})}
        headerStyle={{
          fontSize: "22px",
        }}
        visible={itemDrawerOpen}        
        footer={
          <>
          <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Button 
              className="btn btn-light"
              onClick={() => dispatch({type: "close-notes-drawer", payload: {}})}
              loading={isSavingDrawerItem}
            >CANCEL</Button>
            <Button 
            className="btn btn-primary"
            onClick={() => dispatch({type: "add-note-to-item", payload: { text: "some note" }})}
            loading={isSavingDrawerItem}
            style={{
              marginLeft: "10px",               
            }}
            >ADD NOTE</Button>          
          </div>
          </>
        }
      >
        <div>
          <h3>Order Information</h3>
          <p><b>Delivery: </b><span>{`${moment(drawerItem?.delivery.delivery_date).format("MM/DD/YYYY")} ${drawerItem?.route.name}
          ${drawerItem?.location.name}`}</span></p>
          <p><b>Customer: </b><span>{drawerItem?.shopify_order.shopify_customer_name}</span></p>
          <p><b>Product: </b><span>{`${drawerItem?.quantity} x ${drawerItem?.shopify_order_item?.title} ${drawerItem?.shopify_order_item?.variant_title} (${drawerItem?.vendor.name})`}</span></p>               
        </div>
        <Divider />
        <div>
          <h3>Order Notes</h3>
          {drawerItem?.notes !== "" ? drawerItem?.notes?.split(";", 1).map((item) => {
              return (
                <div style={{ marginBottom: "1rem" }}>
                  <p><b>{item.split(":")[0]}</b></p>
                  <p>{item.split(":")[1]}</p>
                </div>
              )
          }) : <p>None</p>}            
        </div> 
        <Divider />
        <div>
          <h3>Add Order Note</h3>
          <TextArea 
            key={"drawer-note-text"} 
            value={noteText}
            onChange={(e) => dispatch({type: "set-note-textarea", payload: { text: e.target.value }})} 
            rows={4} 
          />           
        </div>                
      </Drawer>      
      <Modal title="Reschedule Delivery" 
        visible={isRescheduleOpen} 
        key={"reschedule-delivery-modal"}
        onCancel={() => {dispatch({type: "toggle-reschedule-modal", payload: { state: false }})}} 
        onOk={() => {dispatch({type: "reschedule-delivery", payload: {}})}}
        okText={"Reschedule"}
        okButtonProps={{
          loading: isSavingItems
        }}
        >
        <div>
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={4}>
              <p>Delivery:</p>
            </Col>
            <Col span={14}>
              <Select
                onChange={(value) => dispatch({type: "set-active-delivery", payload: {delivery: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeDelivery}
                style={{
                  borderRadius: "3px",
                  width: "100%",
                  marginRight: 20
                }}
                placeholder={"Choose Delivery"}
              >
                {deliveriesOptions?.map((delivery: DeliveryModel) => (
                  <Option value={delivery.uuid} key={`res-opt-${delivery.uuid}`} >{`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}</Option>
                ))}            
              </Select>      
            </Col>
          </Row>
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={4}>
              <p>Location:</p>
            </Col>
            <Col span={14}>
                <Select
                  onChange={(value) => dispatch({type: "set-active-location", payload: {location: value} })}
                  loading={isLoadingFilters ? true : false}
                  defaultValue={activeLocation}
                  value={activeLocation}
                  style={{
                    borderRadius: "3px",
                    width: "100%",
                    marginRight: 20
                  }}
                  placeholder={"Choose Location"}
                >           
                  {locationOptions?.map((location: LocationModel) => (
                    <Option value={location.uuid} key={`res-opt-${location.uuid}`} >{location.name}</Option>
                  ))}            
                </Select>   
            </Col>
          </Row>                   
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={4}>
              <p>Member:</p>
            </Col>
            <Col span={14}>
              <Select
                onChange={(value) => dispatch({type: "set-active-customers", payload: {customers: value} })}
                loading={isLoadingFilters ? true : false}
                mode={"multiple"}
                value={activeCustomers}
                style={{
                  width: 200,
                  marginRight: 20
                }}
                placeholder={"Choose Customer"}
                allowClear={true}
              >           
                {customerOptions?.map((customer: string) => (
                  <Option value={customer} key={`filter-${customer}`} >{customer}</Option>
                ))}            
              </Select>             
            </Col>
          </Row> 
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={4}>
              <p>Vendor:</p>
            </Col>
            <Col span={14}>
              <Select
                onChange={(value) => dispatch({type: "set-active-vendor", payload: {vendor: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeVendor}
                style={{
                  borderRadius: "3px",
                  width: "100%",
                }}
                placeholder={"Choose Vendor"}
                allowClear={true}
              >           
                {vendorOptions?.map((vendor: string) => (
                  <Option value={vendor} key={`filter-${vendor}`} >{vendor}</Option>
                ))}            
              </Select>           
            </Col>
          </Row>  
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={4}>
              <p>Product:</p>
            </Col>
            <Col span={14}>
              <Select
                onChange={(value) => dispatch({type: "set-active-product", payload: {product: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeProduct}
                dropdownStyle={{
                  fontSize: "12px"
                }}
                style={{
                  borderRadius: "3px",
                  width: "100%",                  
                }}
                className=""
                placeholder={"Choose product"}
                allowClear={true}
              >           
                {productOptions?.map((product: string) => (
                  <Option style={{ fontSize: "12px" }} value={product} key={`filter-${product}`} ><span style={{ fontSize: "12px" }}>{product}</span></Option>
                ))}            
              </Select>            
            </Col>
          </Row>                                 
          <Divider />    
        </div>
        <div>
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={9}>
              <p>Current Delivery Date: </p>
            </Col>
            <Col span={9}>  
              <p>{activeDeliveryFull ? `${moment(activeDeliveryFull.delivery_date).format("MM/DD/YYYY")} ${activeDeliveryFull.route.name}` : ""}</p>
            </Col>
          </Row>                  
          <Row
            align={"middle"}
          >
            <Col span={18}>
              <Select
                onChange={(value) => dispatch({type: "set-reschedule-delivery", payload: {delivery: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeRescheduleDelivery}
                style={{
                  borderRadius: "3px",
                  width: "100%",
                  marginBottom: "10px"
                }}
                placeholder={"Choose Upcoming Delivery"}
              >
                {rescheduleDeliveriesOptions?.map((delivery: DeliveryModel) => (
                  moment(delivery.delivery_date).isAfter(moment()) ?
                    <Option value={delivery.uuid} key={`reschedule-${delivery.uuid}`} >{`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}</Option>
                  : <></>
                ))}            
              </Select>         
            </Col>
          </Row> 
        </div>         
      </Modal>      
      <Modal title="Reschedule Items" 
        key={"reschedule-items-modal"}
        visible={isRescheduleItemsOpen} 
        onCancel={() => {dispatch({type: "toggle-reschedule-items-modal", payload: { state: false }})}} 
        onOk={() => {dispatch({type: "reschedule-selected-items", payload: {}})}}
        okText={"Reschedule"}
        okButtonProps={{
          loading: isSavingItems
        }}
        >
        <div>
          <Row
            align={"middle"}
            style={{
              marginBottom: "10px"
            }}
          >
            <Col span={9}>
              <p>Current Delivery Date: </p>
            </Col>
            <Col span={9}>  
              <p>{activeDeliveryFull ? `${moment(activeDeliveryFull.delivery_date).format("MM/DD/YYYY")} ${activeDeliveryFull.route.name}` : ""}</p>
            </Col>
          </Row>                  
          <Row
            align={"middle"}
          >
            <Col span={18}>
              <Select
                onChange={(value) => dispatch({type: "set-reschedule-delivery", payload: {delivery: value} })}
                loading={isLoadingFilters ? true : false}
                value={activeRescheduleDelivery}
                style={{
                  borderRadius: "3px",
                  width: "100%",
                  marginBottom: "10px"
                }}
                placeholder={"Choose Upcoming Delivery"}
              >
                {rescheduleDeliveriesOptions?.map((delivery: DeliveryModel) => (
                  moment(delivery.delivery_date).isAfter(moment()) ?
                    <Option value={delivery.uuid} key={`reschedule-item-${delivery.uuid}`} >{`${moment(delivery.delivery_date).format("MM/DD/YYYY")} ${delivery.route.name}`}</Option>
                  : <></>
                ))}            
              </Select>         
            </Col>
          </Row> 
        </div>         
      </Modal>               
    </>
  );

};
export default DeliveryItems;  