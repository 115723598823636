import React, { useMemo } from "react";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { Button, Space, DatePicker, TableColumnType } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { TableRowData } from "../../utils";
import { DataIndex } from "rc-table/lib/interface";
import { EditableTableColumnType } from "../../app/hooks/editable-table.hook";
import moment, { isMoment, Moment } from 'moment';
// import { RangeValue } from 'moment';
const { RangePicker } = DatePicker;

export interface ColumnSearchDatesOptions {
  setSearchValue?: (
    dataIndex: DataIndex,
    selectedKeys: React.Key[] | null,
    done?: () => void
  ) => void;
  clearSearchValue?: (dataIndex: string, done?: () => void) => void;
}

// export type GetColumnSearchProps = <T>(dataIndex:string, options: ColumnSearchOptions) =>
export type ColumnDateSearchProps<TEntity> = Pick<
  TableColumnType<TableRowData<TEntity>>,
  | "filterDropdown"
  | "filterIcon"
  | "onFilter"
  | "onFilterDropdownVisibleChange"
  | "render"
  | "filtered"
>  | Pick<EditableTableColumnType<TEntity>, "filterKey">;
// | Pick<EditableTableColumnType<TEntity>, "filterDropdownWrapper">;

export type GetColumnSearchProps<TEntity> = (
  dataIndex: string,
  options: ColumnSearchDatesOptions
) => ColumnDateSearchProps<TEntity>;

const getVal = (k:any) => {
  if(!k) return null;
  if(isMoment(k)){
    return k
  }
  if(moment(k).isValid()){
    return moment(k)
  }
  throw new Error("Missing type");
}
export const ColumnDateSearch = (props:FilterDropdownProps) => {
  const {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    visible,
  } = props;

  const vals = useMemo(() => {
    const inner = [
      getVal((selectedKeys && selectedKeys[0])),
      getVal((selectedKeys && selectedKeys[1]))
    ] as [Moment | null, Moment | null]
    return inner;
  },[selectedKeys])

  return (
    <div style={{ padding: 8 }}>
    <RangePicker 
      open={visible} 
      autoFocus={true}
      value={vals}
      onCalendarChange={(values, formatString, info) => {
        console.log({method: 'onCalendarChange', values, formatString, info })
        setSelectedKeys(formatString);
        if(info.range === 'end' && formatString[0] && formatString[1]){
          confirm({ closeDropdown: true })
        } else if (info.range === 'end'){
          confirm({ closeDropdown: false })
        }
      }}   
      renderExtraFooter={() => (
      <Space>
        <Button
          onClick={() => {
            if(clearFilters){
              clearFilters()
            }
            setTimeout(() => confirm({ closeDropdown: true }),100);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
      )}
     />
  </div>
  )
}

export function getColumnSearchDatesProps<TEntity>(
  dataIndex: DataIndex, options?: ColumnSearchDatesOptions
): ColumnDateSearchProps<TEntity> {
  return {
    filterDropdown: (filterDropdownProps) => <ColumnDateSearch {...filterDropdownProps }/>,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#b1816e" : undefined }} />
    ),
    onFilter: (value, record) => {
      /** Filter on the server */
      return true;
    },
    
  };
}
