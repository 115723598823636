import { RouteModel, UUIDField, XBaseModel } from ".";
import { DateStrToMoment, MomentToDateStr } from "../decorators";
import moment from 'moment';

export class DeliveryModel extends XBaseModel {
  consumer_uuid!: string;
  @DateStrToMoment()
  delivery_date!: moment.Moment;
  route!: RouteModel;
  
  // This is not part of the model 
  delivery_date_formatted?: string;
}

export class UpdateDeliveryModel {
  consumer!: UUIDField;
  @MomentToDateStr()
  delivery_date!: string;
}


export class CreateDeliveryModel {
  consumer!: UUIDField;
  @MomentToDateStr()
  delivery_date!: string;
  route!: UUIDField
}
