import { Moment, isMoment } from 'moment'

export interface DateCellProps {
  date: Moment;
  format?:string;
}
export const DateCell = (props:DateCellProps) => {
  const { date } = props
  const format = props?.format ?? 'MM-DD-YYYY'
  if (!(date && isMoment(date))){
    return <></>
  }
  return <>{date.format(format)}</>
}