import { ClassConstructor } from "class-transformer";
import { CreateDeliveryModel, CreateLocationModel, CreateRouteModel, DeliveryModel, DeliveryItemModel, LocationModel, OrderScheduleModel, RouteModel, ShopifyProductVariantModel, UpdateDeliveryModel, UpdateDeliveryItemModel, UpdateLocationModel, UpdateRouteModel, UpdateVendorModel, VendorModel, DiscrepancyModel, CreateDiscrepancyModel, UpdateDiscrepancyModel } from "../../models";
import { APIConfig } from "./api.config";
import { AuthService } from "./auth.service";
import { CrudApi } from "./crud.api";
import { OrderSchedulesApi } from "./order-schedules.api";

export const Api = (config:APIConfig, auth?:AuthService) => {
  auth = auth ? auth : new AuthService(config);
  return {
    config: config,
    auth: auth ? auth : new AuthService(config),
    orderSchedules: new OrderSchedulesApi(config, auth, "orderSchedules"),
    vendors: new CrudApi<VendorModel,undefined,UpdateVendorModel>(config,auth,"vendors"),
    locations: new CrudApi<LocationModel,CreateLocationModel,UpdateLocationModel>(config, auth, "locations"),
    routes: new CrudApi<RouteModel,CreateRouteModel,UpdateRouteModel>(config,auth,"routes"),
    deliveryDates: new CrudApi<DeliveryModel,CreateDeliveryModel, UpdateDeliveryModel>(config,auth, "deliveryDates"),
    deliveryItems: new CrudApi<DeliveryItemModel, undefined, UpdateDeliveryItemModel>(config,auth, "deliveryItems"),
    discrepancies: new CrudApi<DiscrepancyModel, CreateDiscrepancyModel, UpdateDiscrepancyModel>(config, auth, "discrepancies"),
    variants: new CrudApi<ShopifyProductVariantModel,undefined,undefined>(config,auth,'variants'),
  }
}

export type API = ReturnType<typeof Api>

export type APIResources = Omit<ReturnType<typeof Api>, | "config" | "auth">

export type Await<T> = T extends Promise<infer U> ? U : T
export type Resources = APIResources[keyof APIResources]
export type GetRequests = { [key in keyof APIResources]: APIResources[key]['getMany'] }
export type GetRequesParams = { [key in keyof APIResources]: Parameters<APIResources[key]['getMany']> }
export type GetResponses = { [key in keyof APIResources]: Await<ReturnType<APIResources[key]['getMany']>>['data'][0] }
export type GetManyResponses<K extends keyof APIResources> = GetResponses[K]
export type APIModels = {[K in keyof GetResponses ]: GetResponses[K]}[keyof GetResponses]


export const getClassFromInstance = (instance: APIModels): ClassConstructor<APIModels> | undefined => {
  if(instance instanceof OrderScheduleModel){
    return OrderScheduleModel
  }
  if(instance instanceof VendorModel){
    return VendorModel
  }
  if(instance instanceof LocationModel){
    return LocationModel
  }
  if(instance instanceof RouteModel){
    return RouteModel
  }
  if(instance instanceof DeliveryModel){
    return DeliveryModel
  }
  if(instance instanceof DeliveryItemModel){
    return DeliveryItemModel
  }
  if(instance instanceof DiscrepancyModel){
    return DiscrepancyModel
  }    
  if(instance instanceof ShopifyProductVariantModel){
    return ShopifyProductVariantModel
  }
}


export const getClassFromResource = (resource: keyof APIResources) => {
  switch(resource){
    case 'orderSchedules':
      return OrderScheduleModel
    case 'vendors':
      return VendorModel
    case 'locations':
      return LocationModel
    case 'routes':
      return RouteModel
    case 'deliveryDates':
      return DeliveryModel
    case 'deliveryItems':
      return DeliveryItemModel
    case 'discrepancies':
      return DiscrepancyModel      
    case 'variants':
      return ShopifyProductVariantModel
    
  }
}

export type t = GetRequesParams['orderSchedules']