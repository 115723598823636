import { Layout, Menu, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

import logo from "../assets/images/pawradise-admin-logo.png";
import logoMobile from "../assets/images/pawradise-admin-logo.png";
import { PoweredBy } from "../components/PoweredBy";
import poweredByQCM from "../assets/images/qcm.png";
import { useLayout } from "./hooks/layout.hook";
import { Suspense, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "./hoc/app.hoc";
import { AnimatedSwitch } from 'react-router-transition';
import { UserType } from '../models';
import { LoginPage } from "./pages/Login"

const { Header, Content, Sider } = Layout;

const BASE_FONT_SIZE = '16px';
const LayoutStyled = styled(Layout)`

  .trigger.ant-btn.ant-btn-icon-only {
    border: none;
    cursor: pointer;
    transition: color 0.3s;
    height: 100%;

    &:hover {
      color: #b1816e;
    }
  }
  .logo {
    margin: 16px;
  }

  .powered-by-container {
    img {
      max-width: 35px;
    }
  }

  
  .site-layout .site-layout-header {
    background: #fff;
    padding-left: 16px;
  }

  .site-layout .site-layout-content {
    padding: 24px;
    min-height: 280px;
  }
  
  .site-layout-content,
  .site-layout-navigation,
  .site-layout-navigation .ant-menu-item .ant-menu-title-content {
    font-size: ${BASE_FONT_SIZE};
  }
  .switch-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .switch-wrapper > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

export const AppTemplate = () => {
  const appCtx = useContext(AppContext);
  const userType = (appCtx?.me?.type || "UNDEFINED") as UserType;
  const layout = useLayout(userType);

  return (
    appCtx.me ?
    <LayoutStyled className={`${appCtx.shouldShowGuides ? 'show-guides' : ''}`}>
        <Sider className="site-layout-navigation" trigger={null} collapsible collapsed={layout.state.collapsed} width={260} theme="dark" >
          <div className="logo" >
            <img
              alt="company logo"
              src={layout.state.collapsed ? logoMobile : logo}
              style={{ height: "auto", width: "100%" }}
            />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={[layout.state.selectedMenuKey]}>
            {layout.menuItems}
            <Button 
            style={{
              paddingLeft: "24px",
              border: "none",
              marginTop: "50px"              
            }}
            onClick={() => appCtx.logout()}
            >Logout</Button>
            <PoweredBy className="powered-by-container">
              <div>
                <img alt="" src={poweredByQCM} />
              </div>
            </PoweredBy>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-header" style={{ height: layout.state.collapsed ? '60px' : '105px' }} >
            <Button
              className="trigger" 
              onClick={() => layout.dispatch({ type: 'toggle-collapsed' })}
              icon={layout.state.collapsed ? <MenuUnfoldOutlined style={{ fontSize: '30px'}} /> : <MenuFoldOutlined style={{ fontSize: '30px'}} />}  />
          </Header>
          <Content
            className="site-layout-content"
          >
            <Suspense fallback={<>Loading...</>}>
              <AnimatedSwitch
                wrapperComponent='div'
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
              > 
                {layout.routes}
              </AnimatedSwitch>
            </Suspense>
          </Content>
        </Layout>
      </LayoutStyled>
      : <LoginPage></LoginPage>
  )
}