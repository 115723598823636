import { ReactNode } from "react"
import styled from "styled-components"



export interface IGridProps {
  columns: number;
  gap?: number | string;
  children?: ReactNode;
}

export const defaultGridProps = {
  columns: 2,
  gap: '10px',
}

const GridStyled = styled.div<IGridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  gap: ${props => props.gap};

`
export const Grid = (props: IGridProps) => {
  return <GridStyled {...{...defaultGridProps, ...props}} />
}

export type IGrid2ColProps = Omit<IGridProps,"columns">;
export const Grid2Col = (props: IGrid2ColProps) => {
  return <Grid columns={2} {...props} />
}