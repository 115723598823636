import { Moment } from 'moment'
import { DateTimeStrToMoment } from '../decorators';
export type UUIDField = string;
export type ShopifyIDField = string;
export type DateStringField = string;
export class XTimestampModelMixin {
  @DateTimeStrToMoment()
  date_created!: Moment;
  @DateTimeStrToMoment()
  date_updated!: Moment;
}

export class XBaseModel extends XTimestampModelMixin {
  uuid!:UUIDField;
  version!: number;
}

export abstract class BaseShopifyModel extends XTimestampModelMixin {
  shopify_id!: ShopifyIDField;
  consumer_uuid!: UUIDField;
}
