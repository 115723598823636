import React from "react"

export interface ErrorNotificationDescriptionProps {
  message:string;
  errors?:string | string[];
}
export const ErrorNotificationDescription = ({message, errors}:ErrorNotificationDescriptionProps) => {
  return (
    <>
      <div>{message}</div>
      {!errors ? <></> : <div>{errors}</div>}
    </>
  )
}

export const ErrorNotificationDescriptionFactory = React.createFactory(ErrorNotificationDescription)