import { PageAction } from "../../components/Actions";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { VendorModel, RouteModel, DeliveryModel } from "../../models/";
import { PageTemplate } from "./PageTemplate";
import { useContext } from "react";
import { useReports } from "../hooks/reports.hook";
import { Table, Checkbox, Select, Button, Modal, Input, Drawer, Divider, Row, Col, InputNumber, Layout, Menu, DatePicker, Tabs } from "antd";
import { EditOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const DeliveryItems = () => {

  const ctx = useReports();
  const { state, dispatch } = ctx;

  const {
    vendors,
    routes,
    deliveries,
    activeVendors,
    activeRoute,
    activeDelivery,
    loading,
    reportLoading,
    selectableColumns,
    deliveryDetailsColumns
  } = state;

  return (
    <>
      <PageTitle>Reports</PageTitle>
      <Layout>
        <Row
          align={"top"}
          justify={"space-between"}
          style={{
            marginBottom: "10px"
          }}
        >
          <Tabs defaultActiveKey="unresolved"
          style={{
            marginTop: "20px",
            marginBottom: "0"
          }}>
            <TabPane tab="Order Details" key="order-details">
              <Layout>
                <>
                  <div style={{
                    background: "white",
                    padding: "1rem"
                  }}>
                    <h2>Order Details</h2>
                    <div>
                      <Row
                        align={"top"}
                        justify={"space-between"}
                        style={{ marginTop: "10px" }}
                      >     
                        <Col span={11}>               
                          <Select
                            onChange={(value) => dispatch({type: "set-active-vendors", payload: {vendors: value} })}
                            loading={loading}
                            mode={"multiple"}
                            value={activeVendors}
                            placeholder={"Choose Vendors"}
                            showSearch={true}
                            optionLabelProp={"label"}
                            optionFilterProp={"label"}                             
                            style={{
                              width: "100%",
                            }}                          
                            allowClear={true}
                          >           
                            {vendors?.map((vendor: VendorModel) => (
                              <Option 
                              value={vendor.uuid} 
                              key={`vendor-${vendor.uuid}`} 
                              label={vendor.name}                              
                              >{vendor.name}</Option>
                            ))}            
                          </Select> 
                        </Col>
                        <Col span={11}>               
                          <RangePicker 
                            onChange={(values) => dispatch({type: 'set-date-ranges', payload: { values: values }})}
                           />
                         </Col>
                       </Row>     
                    </div>     
                    <div>   
                      <Row
                        align={"top"}
                        justify={"start"}
                        style={{ marginTop: "10px" }}
                      >
                        <h4>Columns</h4>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
                        <Col span={24} id="order-details-checkboxes">   
                          <Checkbox.Group style={{ display: "flex", flexWrap: "wrap" }} options={selectableColumns} defaultValue={selectableColumns.map((col) => {return col.key})} onChange={(values) => dispatch({type: 'set-report-columns', payload: { columns: values }})} />
                        </Col>
                      </Row>
                    </div> 
                    <Divider />    
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>           
                      <Button 
                      className="ant-btn ant-btn-primary"
                      onClick={() => dispatch({type: "generate-report", payload: {} })}
                      loading={reportLoading}
                      style={{              
                      }}
                      >{ reportLoading ? "Generating Report" : "Pull Report" }</Button> 
                    </div> 
                  </div>
                </>
              </Layout>  
            </TabPane>
            <TabPane tab="Delivery Details" key="delivery-details">
              <Layout>
                <>
                  <div style={{
                    background: "white",
                    padding: "1rem"
                  }}>
                    <h2>Delivery Details</h2>
                    <div>
                      <Row
                        align={"top"}
                        justify={"space-between"}
                        style={{ marginTop: "10px" }}
                      >     
                        <Col span={11}>               
                          <Select
                            onChange={(value) => dispatch({type: "set-active-route", payload: {route: value} })}
                            loading={loading}
                            value={activeRoute}
                            placeholder={"Choose Route"}
                            style={{
                              width: "100%",
                            }}                          
                            allowClear={true}
                          >           
                            {routes?.map((route: RouteModel) => (
                              <Option value={route.uuid} key={`route-${route.uuid}`} >{route.name}</Option>
                            ))}            
                          </Select> 
                        </Col>
                        <Col span={11}>               
                          <Select
                            onChange={(value) => dispatch({type: "set-active-delivery", payload: {delivery: value} })}
                            loading={loading}
                            value={activeDelivery}
                            placeholder={"Choose Delivery"}
                            style={{
                              width: "100%",
                            }}                          
                            allowClear={true}
                          >           
                            {deliveries?.map((delivery: DeliveryModel) => (
                              <Option value={delivery.uuid} key={`delivery-${delivery.uuid}`} >{delivery.delivery_date_formatted}</Option>
                            ))}            
                          </Select> 
                         </Col>
                       </Row>     
                    </div>     
                    <div>   
                      <Row
                        align={"top"}
                        justify={"start"}
                        style={{ marginTop: "10px" }}
                      >
                        <h4>Columns</h4>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
                        <Col span={24} id="delivery-details-checkboxes">   
                          <Checkbox.Group style={{ display: "flex", flexWrap: "wrap" }} options={deliveryDetailsColumns} defaultValue={deliveryDetailsColumns.map((col) => {return col.key})} onChange={(values) => dispatch({type: 'set-delivery-report-columns', payload: { columns: values }})} />
                        </Col>
                      </Row>
                    </div> 
                    <Divider />    
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>    
                      <Button 
                      className="ant-btn ant-btn-primary"
                      onClick={() => dispatch({type: "generate-delivery-report", payload: {} })}
                      loading={reportLoading}
                      style={{              
                      }}
                      >{ reportLoading ? "Generating Report" : "Pull Report" }</Button> 
                    </div> 
                  </div>
                </>
              </Layout>  
            </TabPane>            
          </Tabs>       
        </Row>
      </Layout>     
    </>
  );

};
export default DeliveryItems;  