import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { ReactNode } from "react";
import styled from "styled-components";
import { Grid2Col } from "../../components/layout/Grid";
// import { ErrorBoundary } from "../../components/ErrorBoundary";
import { IfDefined } from "../../components/utils/Rendering";

export interface IPageTemplateProps {
  children: {
    header?: {
      breadCrumbs?: ReactNode;
      actions?: ReactNode[];
      additional?: ReactNode;
      mainAction?: ReactNode;
    };
    title: ReactNode;
    content: ReactNode;
  };
}
const PageTemplateStyled = styled.div`
  .pt-slot {
    /* padding: 12px 0; */
  }
  .pt-header {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 20% 80%;
    min-height: 120px;
  }

  .pt-actions {
    display: flex;
    justify-content: flex-end;
    > div > button.base-action.ant-btn:last-of-type {
      padding-right: 0;
    }
  }
  
  .pt-action {
    display:flex;
    justify-content: flex-end;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .pt-additional {
    display:flex;
    justify-content: flex-start;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    > div > button.base-action.ant-btn:first-of-type {
      padding-left: 0;
    }

  }
  .pt-content-container {
    background-color: white;
    /* margin: 12px 8px; */
    margin-top: 24px;
    padding: 12px;
  }
  .pt-content {
    
  }
`;
const Slot = (props: { children?: ReactNode; className?: string }) => (
  <div className={`pt-slot ${props.className}`}>
    <div>
      <IfDefined load={props.children} />
    </div>
  </div>
);

export const PageTemplate = (props: IPageTemplateProps) => {
  const { header, title, content } = props.children;

  return (
    <PageTemplateStyled className="page-template--container pt-container">
      <div className="pt-header">
        <Slot className="pt-breadcrumbs">{header?.breadCrumbs}</Slot>
        <Slot className="pt-actions">
          <Grid2Col>
            {header?.actions}
          </Grid2Col>
        </Slot>
        <Slot className="pt-additional">{header?.additional}</Slot>
        <Slot className="pt-action">{header?.mainAction}</Slot>
      </div>
      <div className="pt-content-container">
        <Slot className="pt-title">{title}</Slot>
        <Slot className="pt-content">
          <ErrorBoundary>{content}</ErrorBoundary>
        </Slot>
      </div>
    </PageTemplateStyled>
  );
};
