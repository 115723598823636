import { Switch, SwitchProps } from "antd"
import { createFactory, ReactNode } from "react"
import styled from "styled-components"

const LabeledSwitchStyled = styled.div`
    .ant-switch {
      margin: 0 20px;
    }
`
export interface LabeledSwitchProps extends SwitchProps {
  before: ReactNode | string;
  after: ReactNode | string;
}

export const LabeledSwitch = (props:LabeledSwitchProps) => {
  const { before, after,...restOfProps } = props
  return (
    <LabeledSwitchStyled>
      <span>{before}</span>
      <Switch 
        {...restOfProps}
      />
      <span>{after}</span>
    </LabeledSwitchStyled>
  )
}

export const LabeledSwitchFactory = createFactory(LabeledSwitch);