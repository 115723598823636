import {
  Table,
  Form,
} from "antd";
import React, { useContext } from "react";
import { EditableTableContext, IEditableTableContext } from "../app/hooks/editable-table.hook";
import { EditableCell } from "./EditableTableCell";
import styled from "styled-components";

export const EditableTableStyled = styled.div`
    .ant-table-container > {
      .ant-table-body {
        min-height: 650px;
      }
    }
`

export interface EditableTableContentProps {
  className?: string;
  rowClassName?: string;
  isLoading?: boolean;
  scrollX?: number;
  scrollY?: number;
}

function EditableTableContent<TRecord>(
  props: EditableTableContentProps
){
  const ctx = useContext<IEditableTableContext<TRecord>>(EditableTableContext);
  const {
    state: { data, isLoading: loading, page, limit, total},
    form,
    handlers: {
      cancelEditRecord, onEditingRowValuesChange,onTableChange
    },
    props: {
      columns, rowSelection, rowKey
    },
  } = ctx
  const { isLoading: isLoadingExternal, scrollX, scrollY } = props;
  
  return (
    <EditableTableStyled>
      <Form form={form} component={false} onValuesChange={onEditingRowValuesChange}>
        <Table
          bordered
          sticky
          scroll={{
            x: scrollX ?? 1000,
            y: scrollY ?? 770,
          }}
          rowKey={rowKey}
          {...props}
          loading={loading || isLoadingExternal}
          columns={columns}
          dataSource={data}
          className={`pc-table ${props?.className ?? ""}`}
          rowClassName={(record, i) =>
            `editable-row pc-row ${props?.rowClassName ?? ""}`
          }
          onChange={onTableChange}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowSelection={rowSelection}
          pagination={{
            onChange: cancelEditRecord,
            current: page,
            pageSize: limit,
            total: total,
            showSizeChanger: true,
            defaultPageSize: limit,
          }}
        />
      </Form>
    </EditableTableStyled>
  );
}

export interface EditableTableProps<TRecord> extends EditableTableContentProps {
  ctx: IEditableTableContext<TRecord>
}
export function EditableTable<TRecord>(props:EditableTableProps<TRecord> ){
  const {ctx,...restOfProps } = props;
  return (
    <EditableTableContext.Provider value={ctx}>
      <EditableTableContent<TRecord> {...restOfProps} />
    </EditableTableContext.Provider>
  )
}
