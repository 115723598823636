import { notification } from "antd";
import { ErrorNotificationDescriptionFactory } from "../components/ErrorNotificationDescription";
import { APIError } from "../infrastructure/exceptions";

export const handleError = (error: any) => {
  if (error instanceof APIError) {
    console.warn(error);
    notification.error({
      message: error.name,
      description: ErrorNotificationDescriptionFactory({
        message: error.message,
        errors: error.errors,
      })
    });
    return;
  }
  console.error("This error is not handled.");
  console.error(error);
};

export const safelyFireCallback = (
  success?: () => void,
  error?: (e: any) => void
) => {
  success = success ?? (() => {});
  error = error ?? (() => {});
  try {
    success();
  } catch (e) {
    error(e);
  }
};
