import { useContext } from "react";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import { VendorModel } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import {
  useEditableTable,
} from "../hooks/editable-table.hook";
import { PageTemplate } from "./PageTemplate";

export const Vendors = () => {
  const { api } = useContext(AppContext);

  const editableTableCtx = useEditableTable({
    rowKeyValueField: 'uuid',
    createEmptyRecord: () => {
      const record = new VendorModel();
      return record;
    },
    defaultQuery: {
      page: 1,
      limit: 10,
      sort: { field: 'name', order: "ASC" },
    },
    loadRecords: async ({ page, limit, sort, filter }) => {
      const result = await api.vendors.getMany({
        page,
        limit,
        sort,
        filter,
      });
      return result
    },
    updateRecord: async (record, tableRow) => {
    return api.vendors.updateOne(tableRow.uuid, record);
    },
    createRecord: async (record, tableRow) => {},
    deleteRecord: async (record, tableRow) => {},
    isEditable: true,
    isDeletable: false,
    columns: [
      {
        title: "Collection ID",
        dataIndex: "shopify_collection_id",
        key: "shopify_collection_id",
        width: 300,
      },
      {
        title: "Vendor Name",
        dataIndex: "name",
        key: "name",
        isSearchable: true,
        isSortable: true,
      },
      {
        title: "Automatic Notification",
        dataIndex: "auto_notify",
        key: "auto_notify",
        fixed: 'right',
        width: 250,
        editable: true,
        inputType: 'switch',
      },
    ],
  });

  return (
    <PageTemplate>
      {{
        title: <PageTitle>Vendors</PageTitle>,
        content: (
          <EditableTable ctx={editableTableCtx} scrollX={1200}  />
        ),
      }}
    </PageTemplate>
  );
};
export default Vendors;
