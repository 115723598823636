import { useContext, useEffect, useState } from "react";
import { VendorModel, DeliveryItemModel, DeliveryModel, RouteModel, ShopifyOrderModel } from "../../models";
import { AppContext } from "../hoc/app.hoc";
import { useStateEnhanced } from './enhanced-state.hook';
import { plainToInstance } from "class-transformer";
import { SupportedRequestQueryParams, QueryFilter } from "../../services/api/api.types";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { message } from "antd";
import moment from 'moment';

export interface UseReportsProps {  
  load?: SupportedRequestQueryParams<VendorModel>["GetMany"]
}

export interface IReportsState {
  vendors: VendorModel[];
  routes: RouteModel[];
  deliveries: DeliveryModel[];
  activeVendors: string[];
  activeRoute: string;
  activeDelivery?: string;
  loading: boolean;
  reportLoading: boolean;

  reportStartDate?: string;
  reportEndDate?: string;

  selectableColumns: any[];
  deliveryDetailsColumns: any[];
  selectedColumns: CheckboxValueType[];
  selectedDeliveryDetailsColumns: CheckboxValueType[];
}

export type ReportsAction = 
| { type: 'generate-report'; payload: { } }
| { type: 'generate-delivery-report'; payload: { } }
| { type: 'set-active-vendors'; payload: { vendors: string[] } }
| { type: 'set-active-route'; payload: { route: string } }
| { type: 'set-active-delivery'; payload: { delivery: string } }
| { type: 'set-report-columns'; payload: { columns: CheckboxValueType[] } }
| { type: 'set-delivery-report-columns'; payload: { columns: CheckboxValueType[] } }
| { type: 'set-date-ranges'; payload: { values: any } };

export const useReports = () => {
  const { api } = useContext(AppContext);

  const [state, { setProperty, setProperties }] = useStateEnhanced<IReportsState>({
    vendors: [],
    routes: [],
    deliveries: [],
    activeVendors: [],
    activeRoute: "",
    loading: true,
    reportLoading: false,
    selectableColumns: [
      {
        label: "Member Name",
        value: "col-member-name",
        key: "col-member-name",
      },
      {
        label: "Delivery Location",
        value: "col-delivery-location",
        key: "col-delivery-location",
      }, 
      {
        label: "City",
        value: "col-city",
        key: "col-city",
      }, 
      {
        label: "State",
        value: "col-state",
        key: "col-state",
      }, 
      {
        label: "Email",
        value: "col-email",
        key: "col-email",
      }, 
      {
        label: "Phone Number",
        value: "col-phone-number",
        key: "col-phone-number",
      }, 
      {
        label: "Lbs",
        value: "col-weight",
        key: "col-weight",
      }, 
      {
        label: "Label",
        value: "col-label",
        key: "col-label",
      }, 
      {
        label: "Type",
        value: "col-type",
        key: "col-type",
      }, 
      {
        label: "Ext Price",
        value: "col-ext-price",
        key: "col-ext-price",
      }, 
      {
        label: "Qty",
        value: "col-quantity",
        key: "col-quantity",
      }, 
      {
        label: "Product Name",
        value: "col-product-name",
        key: "col-product-name",
      }, 
      {
        label: "Delivery Route",
        value: "col-route",
        key: "col-route",
      }, 
      {
        label: "Delivery Date",
        value: "col-delivery-date",
        key: "col-delivery-date",
      }, 
    ],
    deliveryDetailsColumns: [
      {
        label: "Vendor",
        value: "delivery-col-vendor",
        key: "delivery-col-vendor",
      }, 
      {
        label: "Delivery Time",
        value: "delivery-col-delivery-time",
        key: "delivery-col-delivery-time",
      }, 
      {
        label: "Delivery Location",
        value: "delivery-col-delivery-location",
        key: "delivery-col-delivery-location",
      },
      {
        label: "Member Name",
        value: "delivery-col-member-name",
        key: "delivery-col-member-name",
      }, 
      {
        label: "Confirmed",
        value: "delivery-col-confirmed",
        key: "delivery-col-confirmed",
      }, 
      {
        label: "Label",
        value: "delivery-col-product-name",
        key: "delivery-col-product-name",
      },       
      {
        label: "Sum of Lbs",
        value: "delivery-col-weight",
        key: "delivery-col-weight",
      }, 
    ],
    selectedColumns: [],
    selectedDeliveryDetailsColumns: [],    
  });

  const dispatch = (action: ReportsAction) => {
    switch (action.type) {
      case 'generate-report':
        return generateReport();  
      case 'generate-delivery-report':
        return generateDeliveryReport();          
      case 'set-active-vendors':
        return setActiveVendors(action.payload.vendors); 
      case 'set-active-route':
        return setActiveRoute(action.payload.route); 
      case 'set-active-delivery':
        return setActiveDelivery(action.payload.delivery);                 
      case 'set-date-ranges':
        return setDateRanges(action.payload.values); 
      case 'set-report-columns':
        return setReportColumns(action.payload.columns);
      case 'set-delivery-report-columns':
        return setDeliveryReportColumns(action.payload.columns);        
    }
  };  

  const setActiveVendors = (vendor: string[]) => {
    setProperty({ activeVendors: vendor });
  }  

  const setActiveRoute = (route: string) => {
    setProperty({ activeRoute: route });    
  }

  const setActiveDelivery = (delivery: string) => {
    setProperty({ activeDelivery: delivery });
  }      

  const setReportColumns = (columns: CheckboxValueType[] ) => {
    setProperty({ selectedColumns: columns });
  } 

  const setDeliveryReportColumns = (columns: CheckboxValueType[] ) => {
    setProperty({ selectedDeliveryDetailsColumns: columns });
  }   

  const setDateRanges = (values: any) => {
    try{
      setProperties({ reportStartDate: values[0], reportEndDate: values[1] });
    }catch(e){
      console.log(`unable to set range ${JSON.stringify(e)}`);
    }
  }

  const generateReport = async () => {

    setProperty({ reportLoading: true });   
    
    if(!state.reportStartDate || !state.reportEndDate){
      message.error('Please select a start and end date for your report');
      setProperty({ reportLoading: false });   
      return;
    }

    console.log(`start ${state.reportStartDate}`)

    let dlRequestParams: SupportedRequestQueryParams<DeliveryModel>["GetMany"] = {
      filter: [       
        {
          field: "delivery_date",
          operator: "$lte",
          value: moment(state.reportEndDate).format("YYYY-MM-DD")
        },      
      ],
    };  

    const dlRes = await api.deliveryDates.getMany(dlRequestParams);  

    let dhRequestParams: SupportedRequestQueryParams<DeliveryModel>["GetMany"] = {
      filter: [
        {
          field: "delivery_date",
          operator: "$gte",
          value: moment(state.reportStartDate).format("YYYY-MM-DD")
        },      
      ],
    };  

    const dhRes = await api.deliveryDates.getMany(dhRequestParams);  

    const dls = dlRes.data.map((delivery) => {
      return delivery.uuid;
    }); 

    const dhs = dhRes.data.map((delivery) => {
      return delivery.uuid;
    });       

    const deliveries = dhs.filter(value => dls.includes(value));    

    console.log(`deliveries ${JSON.stringify(deliveries)}`);

    let requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
      filter: [
        {
          field: "delivery",
          operator: "$notin",
          value: deliveries
        },             
      ],
      sort: [{ field: "vendor.name", order: "ASC" }, { field: "route.name", order: "ASC" }, { field: "location.name", order: "ASC" }, { field: "shopify_order.shopify_customer_name", order: "ASC" }, { field: "shopify_order_item.product_type", order: "ASC" }, { field: "shopify_order_item.name", order: "ASC" }],
    };      

    if(state.activeVendors.length > 0){
      requestParams = {
        filter: [
          {
            field: "delivery",
            operator: "$notin",
            value: deliveries
          },
          {
            field: "vendor",
            operator: "$in",
            value: state.activeVendors,
          }      
        ],
        sort: [{ field: "vendor.name", order: "ASC" }, { field: "route.name", order: "ASC" }, { field: "location.name", order: "ASC" }, { field: "shopify_order.shopify_customer_name", order: "ASC" }, { field: "shopify_order_item.product_type", order: "ASC" }, { field: "shopify_order_item.name", order: "ASC" }],
      };        
    }

    let filteredItems = null;

    try{

      filteredItems = await api.deliveryItems.getMany(requestParams);

    }catch(e){
      console.log(`failed to get report items ${JSON.stringify(e)}`);    
      return;
    }

    try{

      console.log(`filtered items ${JSON.stringify(filteredItems)}`);

      let rowTitles: string[] = [
        "Vendor",
        "Start Date",
        "End Date"
      ]

      state.selectedColumns.forEach((col) => {
        const foundCol = state.selectableColumns.find((selectable) => col === selectable.key);
        if(!foundCol){
          return null
        }
        rowTitles.push(foundCol.label);  
      })    

      let rows: string[] = [rowTitles.join(",")];

      if(filteredItems.data.length < 1){
        message.error('There are no matching items for your criteria');
        setProperty({ reportLoading: false });   
        return;        
      }

      for (let r = 0; r < filteredItems.data.length; r++) {
        const reportRow = filteredItems.data[r];
        const row = [
          String(reportRow.vendor.name) ?? '""',
          String(moment(state.reportStartDate).format("MM-DD-YYYY")) ?? '"1/1/2022"',
          String(moment(state.reportEndDate).format("MM-DD-YYYY")) ?? '"1/1/2022"',
        ];

        if(rows[0].indexOf("Member Name") > -1){
          row.push(String(reportRow.shopify_order.shopify_customer_name) ?? '""')
        }
        if(rows[0].indexOf("Delivery Location") > -1 ){
          row.push(String(reportRow.location.name) ?? '""')
        }
        if(rows[0].indexOf("City") > -1 ){
          row.push(String(reportRow.shopify_order.shopify_customer_city) ?? '""')
        }
        if(rows[0].indexOf("State") > -1 ){
          //row.push(String(reportRow.shopify_customer.state) ?? '"Washington"')
          row.push('"Washington"')
        }
        if(rows[0].indexOf("Email") > -1 ){
          row.push(String(reportRow.shopify_order.email) ?? '""')
        }
        if(rows[0].indexOf("Phone Number") > -1 ){
          row.push(String(reportRow.shopify_order.shopify_customer_phone) ?? '""')
          //row.push(String('""'))
        }
        if(rows[0].indexOf("Lbs") > -1 ){
          const lbs = gramsToPounds(reportRow?.shopify_order_item?.grams);
          row.push(lbs)
        }
        if(rows[0].indexOf("Label") > -1 ){
          row.push(`${reportRow.quantity} - ${reportRow.shopify_order_item.name}`)
        }
        if(rows[0].indexOf("Type") > -1 ){
          row.push(String(reportRow.shopify_order_item.product_type) ?? '""')
        }
        if(rows[0].indexOf("Ext Price") > -1 ){
          row.push(`$${String(reportRow.shopify_order_item.price)}`)
        }
        if(rows[0].indexOf("Qty") > -1 ){
          row.push(String(reportRow.quantity) ?? '""')
        }
        if(rows[0].indexOf("Product Name") > -1 ){
          row.push(String(reportRow.shopify_order_item.name) ?? '""')
        }
        if(rows[0].indexOf("Delivery Route") > -1 ){
          row.push(String(reportRow.route.name) ?? '""')
        }
        if(rows[0].indexOf("Delivery Date") > -1 ){
          if(!reportRow.delivery){
            row.push("TBD");
          }else{
            row.push(reportRow.delivery.delivery_date.format("MM-DD-YYYY"))
          }          
        }


        rows.push(row.join(','));
      }

      const content = rows.join('\n') + '\n';

      const csvContent = 'data:text/csv;charset=utf-8,' + content;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Order Details Report - ${moment().format('MM-DD-YYYY HH:mm A')}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      document.body.removeChild(link);
    } catch (e) {
      console.log(`failed to build report ${JSON.stringify(e)}`);      
    }    
    setProperty({ reportLoading: false });
  }

  const generateDeliveryReport = async () => {

    setProperty({ reportLoading: true }); 

    const reportDelivery = state.deliveries.find((del) => { return del.uuid === state.activeDelivery });
    const reportRoute = state.routes.find((route) => { return route.uuid === state.activeRoute });

    if(!reportDelivery || !reportRoute){
      message.error("Please select both a route and delivery to generate a report");
      setProperty({ reportLoading: false }); 
    }    

    let requestParams: SupportedRequestQueryParams<DeliveryItemModel>["GetMany"] = {
      filter: [{
        field: "delivery",
        operator: "$eq",
        value: reportDelivery?.uuid
      }],
      sort: [{ field: "vendor.name", order: "ASC" }, { field: "location.pickup_time", order: "ASC" }, { field: "shopify_order.shopify_customer_name", order: "ASC" }, { field: "customer_confirmed", order: "DESC" }, { field: "shopify_order_item.name", order: "ASC" }],
    };  

    try{

      const response = await api.deliveryItems.getMany(requestParams);

      console.log(`columns active ${JSON.stringify(state.selectedDeliveryDetailsColumns)}`);

      let rowTitles: string[] = [];

      state.selectedDeliveryDetailsColumns.forEach((col) => {
        const foundCol = state.deliveryDetailsColumns.find((selectable) => col === selectable.key);
        if(!foundCol){
          return null
        }
        rowTitles.push(foundCol.label);  
      })    

      let rows: string[] = [rowTitles.join(",")];

      if(response.data.length < 1){
        message.error('There are no matching items for your criteria');
        setProperty({ reportLoading: false });   
        return;        
      }

      for (let r = 0; r < response.data.length; r++) {
        const reportRow = response.data[r];
        const row = [];

        if(rows[0].indexOf("Vendor") > -1 ){
          row.push(String(reportRow.vendor.name) ?? '""')
        } 
        if(rows[0].indexOf("Delivery Time") > -1 ){
          row.push(String(moment(reportRow.location.pickup_time).format("HH:mm A")) ?? '""')
        }        
        if(rows[0].indexOf("Delivery Location") > -1 ){
          row.push(String(reportRow.location.name) ?? '""')
        } 
        if(rows[0].indexOf("Member Name") > -1){
          row.push(String(reportRow.shopify_order.shopify_customer_name) ?? '""')
        }
        if(rows[0].indexOf("Confirmed") > -1){
          row.push(String(reportRow.customer_confirmed) ?? '""')
        }        
        if(rows[0].indexOf("Label") > -1 ){
          row.push(`${reportRow.quantity} - ${reportRow.shopify_order_item.name}`)
        }   
        if(rows[0].indexOf("Lbs") > -1 ){
          const lbs = gramsToPounds(reportRow?.shopify_order_item?.grams);
          row.push(lbs)
        }                   

        rows.push(row.join(','));
      }

      const content = rows.join('\n') + '\n';

      const csvContent = 'data:text/csv;charset=utf-8,' + content;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Delivery Details Report - ${moment().format('MM-DD-YYYY HH:mm A')}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      document.body.removeChild(link);
    } catch (e) {
      console.log(`failed to build report ${JSON.stringify(e)}`);      
    }    
    setProperty({ reportLoading: false });
  }  

  const gramsToPounds = (grams: number | undefined) => {
    if(!grams){
      return "";
    }
    return String(Math.round((grams * 0.00220462) * 100) / 100);
  }

  const loadVendors = async () => {
    setProperty({ loading: true });   

    const response = await api.vendors.getMany();

    setProperties({ loading: false, vendors: response.data });
  }

  const loadRoutes = async () => {
    setProperty({ loading: true });   

    const response = await api.routes.getMany();

    setProperties({ loading: false, routes: response.data });
  } 


  const loadDeliveries = async () => {
    setProperty({ loading: true });   

    if(state.activeRoute === ""){
      setProperty({ loading: false });   
      return;
    }

    let requestParams: SupportedRequestQueryParams<DeliveryModel>["GetMany"] = {
      filter: [{
        field: "route",
        operator: "$eq",
        value: state.activeRoute
      }],
      sort: [{ field: "delivery_date", order: "ASC" }],
    };    

    console.log(`requestParams ${JSON.stringify(requestParams)}`);

    const response = await api.deliveryDates.getMany(requestParams);

    setProperties({ loading: false, deliveries: response.data });
  }    

  //initial render only
  useEffect(() => {

    loadVendors();
    loadRoutes();

    const selectedColumns = state.selectableColumns.map((col) => col.key as CheckboxValueType);
    setReportColumns(selectedColumns);

    const reportColumns = state.deliveryDetailsColumns.map((col) => col.key as CheckboxValueType);
    setDeliveryReportColumns(reportColumns);    

  }, []);


  useEffect(() => {

    loadDeliveries();      

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.activeRoute])


  return {
    state,
    dispatch,
  }
};
