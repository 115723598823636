import {
  Input,
  InputNumber,
  Form,
  Select,
  TimePicker,
  DatePicker,
} from "antd";
import { useMemo } from "react";
import { LabeledSwitch } from "./LabeledSwitch";

export interface EditableCellProps<TRecord>
  extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  isRequired?: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "tag" | "time" | "date" | "switch" | "select" | "multi-select";
  record: TRecord;
  index: number;
  children: React.ReactNode;
  select?: {
    allowClear?: boolean;
    options: any[];
    fieldNames: {
      label: string;
      value: string;
      key: string;
    };
    filterOption?: (option: any) => boolean,
  };
}

// Allow everything to pass
// const defaultFilterOption = (option: any) => true;

type VType = { key?: string; label: React.ReactNode; value: string | number }
function getSelectOpts<TRecord>(
  selectOpts: EditableCellProps<TRecord>["select"]
) {
  const labelField = selectOpts?.fieldNames?.label ?? "label";
  const valueField = selectOpts?.fieldNames?.value ?? "value";
  const keyField = selectOpts?.fieldNames?.key ?? valueField ?? "key";

  const renderedOptions = (selectOpts?.options ?? [])
  .map((o) => ({
      key: o[keyField],
      label: o[labelField],
      value: o[valueField]
    }))

  return { labelField, valueField, keyField, renderedOptions };
}

export function EditableCell<TRecord>(props: EditableCellProps<TRecord>) {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    select,
    isRequired,
    ...restProps
  } = props;
  // let inputNode = inputType === 'number' ? <InputNumber /> : <Input />
  let isSwitch = inputType === 'switch'
  
  const inputNode = useMemo(() => {
      switch (inputType) {
        case "number":
          return <InputNumber />;
        case "switch":
          return <LabeledSwitch before="off" after="on" />;
        case "time":
          return <TimePicker use12Hours format="h:mm a" />;
        case "date":
          return <DatePicker format="MM-DD-YYYY" />;
        case "select":
            const {renderedOptions: selectOptions} = getSelectOpts(select)
            return (
              <Select<VType>  
                style={{ zIndex: 1 }} 
                dropdownMatchSelectWidth 
                filterOption={true}
                optionFilterProp="label"
                options={selectOptions}
                showSearch={true}
                allowClear={select?.allowClear}
              />
            );
        case "multi-select":
          const {renderedOptions: multiSelectOptions} = getSelectOpts(select)
          return (
            <Select<VType> 
              mode="multiple" 
              style={{ zIndex: 1 }} 
              dropdownMatchSelectWidth 
              filterOption={true}
              optionFilterProp="label"
              options={multiSelectOptions}
              allowClear={select?.allowClear}
            />
          );
        case "tag":
          const {renderedOptions: tagOptions} = getSelectOpts(select)
          return (
            <Select<VType> 
              mode="tags" 
              style={{ zIndex: 1 }} 
              dropdownMatchSelectWidth 
              filterOption={true}
              optionFilterProp="label"
              options={tagOptions}
              allowClear={select?.allowClear}
            />
          );
        default:
          return <Input />
      }
  }, [inputType, select])

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          valuePropName={isSwitch ? "checked" : "value"}
          className={`et-form-item et-form-item--${inputType}`}
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
