import { message, Modal, notification, Upload } from "antd";
import { plainToInstance } from "class-transformer";
import { useContext } from "react";
import { Action, PageAction } from "../../components/Actions";
import { DateCell } from "../../components/DateCell";
import { EditableTable } from "../../components/EditableTable";
import { PageTitle } from "../../components/PageTitle";
import {
  CreateDeliveryModel,
  DeliveryModel,
  UpdateDeliveryModel,
} from "../../models";
import { QueryParams } from "../../services/api/api.types";
import { withUUID } from "../../services/api/factories";
import { AppContext } from "../hoc/app.hoc";
import { useDeliveryDates } from "../hooks/delivery-dates.hook";
import { useEditableTable } from "../hooks/editable-table.hook";
import { useRoutes } from "../hooks/routes.hook";
import { PageTemplate } from "./PageTemplate";
import { getColumnSearchDatesProps } from "../../components/table/get-column-date-search-props";
import { onUpload } from "../../components/utils/upload-handler";

const defaultRouteQuery: QueryParams<DeliveryModel> = {
  page: 1,
  limit: 10,
  sort: [{ field: "route.name", order: "ASC" }],
};

export const DeliveryDates = () => {
  const { api } = useContext(AppContext);

  const [deliveryDatesState, _deliveryDatesDispatch] = useDeliveryDates();
  const [routesState] = useRoutes({
    shouldLoad: true,
    queryParams: {
      page: 1,
      limit: 50,
    },
  });
  const {
    filterData: routesFilterData,
    api: { rawResponseData: routesData, isLoading: isLoadingRoutes },
  } = routesState;

  const editableTableCtx = useEditableTable({
    rowKeyValueField: "uuid",
    createEmptyRecord: () => {
      const record = withUUID(() => new DeliveryModel());
      return record;
    },
    defaultQuery: defaultRouteQuery,
    loadRecords: async ({ page, limit, sort, filter }) => {
      const result = await api.deliveryDates.getMany({
        page,
        limit,
        sort,
        filter,
      });
      return result;
    },
    updateRecord: async (record, tableRow) => {      
      const data = plainToInstance(UpdateDeliveryModel, record);
      data.consumer = "2fbc08de-e095-46dd-b40c-b40692f35e2d";
      return api.deliveryDates.updateOne(tableRow.uuid, data);
    },
    createRecord: async (record, tableRow) => {      
      const data = plainToInstance(CreateDeliveryModel, record);
      data.consumer = "2fbc08de-e095-46dd-b40c-b40692f35e2d";
      return api.deliveryDates.createOne(data);
    },
    deleteRecord: async (record, tableRow) => {
      return api.deliveryDates.deleteOne(record.uuid);
    },
    isEditable: true,
    isDeletable: true,
    columns: [
      {
        title: "Route",
        key: "route.name",
        dataIndex: "route",
        render: (value, record) => record?.route?.name,

        filters: routesFilterData,
        filterKey: "route.uuid",
        filterMultiple: true,

        isSortable: true,

        editable: true,
        isEditableOnlyWhenNew: true,
        inputType: "select",
        select: {
          options: routesData?.data ?? [],
          allowClear: true,
          fieldNames: {
            label: "name",
            value: "uuid",
          },
        },
      },
      {
        title: "Delivery Date",
        key: "delivery_date",
        dataIndex: "delivery_date",

        isSortable: true,
        // isSearchable: true,

        editable: true,
        inputType: "date",
        render: (v, r) => <DateCell date={r.delivery_date} />,
        filterSearchOperator: "$between",
        ...getColumnSearchDatesProps("delivery_date"),
      },
    ],
  });

  const { dispatch } = editableTableCtx;
  const {
    api: { importParams, isExporting },
  } = deliveryDatesState;

  return (
    <PageTemplate>
      {{
        title: <PageTitle>Dates</PageTitle>,
        header: {
          actions: [
            <Upload
              disabled={isExporting}
              {...importParams}
              showUploadList={false}
              onChange={(info) =>
                onUpload(info, () => dispatch({ type: "load-data" }))
              }
            >
              <Action>Import</Action>
            </Upload>,
            <Action
              loading={isExporting}
              onClick={() => _deliveryDatesDispatch({ type: "load-export" })}
            >
              Export
            </Action>,
          ],
          mainAction: (
            <PageAction onClick={() => dispatch({ type: "add-row" })}>
              Add New
            </PageAction>
          ),
        },
        content: (
          <>
            <EditableTable
              ctx={editableTableCtx}
              isLoading={isLoadingRoutes || isExporting}
              scrollX={600}
            />
          </>
        ),
      }}
    </PageTemplate>
  );
};
export default DeliveryDates;
