import { Transform, TransformFnParams, Type } from "class-transformer"
import moment from "moment"
import { ArgumentTypes } from "../utils";

/**
 *  Composes class-transformer methods Type and Transform with custom inputs.
 * @param transformOptions Transform inputs
 * @param typeFunction  Type inputs
 * @returns PropertyDecorator
 */
export function TypedTransform(transformOptions: ArgumentTypes<typeof Transform>, typeFunction: ArgumentTypes<typeof Type>): PropertyDecorator {
  const transformFn = Transform(...transformOptions);
  const typeFn = Type(...typeFunction);

  return function (target: any, key: string | symbol) {
    typeFn(target, key);
    transformFn(target, key);
  }
}

/**
 * Decorates a time string property and converts it into a Moment based on the timeFormat
 * @param timeFormat 
 * @returns PropertyDecorator
 */
 export function TimeStrToMoment(timeFormat?:string):PropertyDecorator {
  timeFormat = timeFormat ?? 'HH:mm:ss'
  return Transform(({ value }) => moment(value, timeFormat), { toClassOnly: true });
}


/**
 * Decorates a Moment property and converts it into a formatted time string
 * @param timeFormat 
 * @returns PropertyDecorator
 */
export function MomentToTimeStr(timeFormat?:string):PropertyDecorator {
  timeFormat = timeFormat ?? 'HH:mm:ss'
  return TypedTransform(
    [({ value }) => moment(value).format(timeFormat), { toClassOnly:true  }],
    [() => moment],
  )
}

/**
 * Decorates a date string property and converts it into a Moment based on the dateFormat
 * @param dateFormat like YYYY-MM-DD 
 * @returns PropertyDecorator
 */
export function DateStrToMoment(dateFormat?:string):PropertyDecorator {
  dateFormat = dateFormat ?? 'YYYY-MM-DD'
  return Transform(({ value }) => moment(value, dateFormat), { toClassOnly: true });
}

/**
 * Decorates a Moment property and converts it into a formatted date string
 * @param dateFormat 
 * @returns PropertyDecorator
 */
 export function MomentToDateStr(dateFormat?:string):PropertyDecorator {
  dateFormat = dateFormat ?? 'YYYY-MM-DD'
  return TypedTransform(
    [({ value }) => moment(value).format(dateFormat), { toClassOnly:true  }],
    [() => moment],
  )
}


/**
 * Decorates a datetime string property and converts it into a Moment
 * @param dateFormat like 2022-01-30 04:13:29 
 * @returns PropertyDecorator
 */
 export function DateTimeStrToMoment():PropertyDecorator {
  return Transform(({ value }) => moment(value), { toClassOnly: true });
}

/**
 * Decorates a Moment property and converts it into a formatted datetime string
 * @param dateFormat 
 * @returns PropertyDecorator
 */
 export function MomentToDateTimeStr(dateFormat?:string):PropertyDecorator {
  dateFormat = dateFormat ?? 'YYYY-MM-DD'
  return TypedTransform(
    [({ value }) => moment(value).toISOString(), { toClassOnly:true }],
    [() => moment],
  )
}

const toFieldValueTransformer = (field?:string, strictContains?: boolean, defaultValue?:any) => ({ value }: TransformFnParams) => {
  if(value === undefined || value === null) {
    return defaultValue
  }
 if(typeof value === 'string'){
   return value
 }
 field = field ?? 'uuid'
 if(field in value){
   if(strictContains &&  (value[field] === null || value[field] === undefined)){
     return value
   }
   return value[field]
 }
 return value;
}

/**
 * Decorates an object property and pulls out a field
 * @param idKey string like uuid
 * @param strictContains boolean whether we should transform if Object[Key] is null or undefined
 * @returns PropertyDecorator
 */
 export function ToFieldValue(field?:string, strictContains?: boolean, defaultValue?:any):PropertyDecorator {
   return Transform(toFieldValueTransformer(field, strictContains, defaultValue), { toClassOnly: true });
}


export function ExtractUUID(): PropertyDecorator {
  return ToFieldValue("uuid", true)
}

export function ExtractUUIDOrNull(): PropertyDecorator {
  return ToFieldValue("uuid", true, null)
}