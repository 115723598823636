import { ReactNode } from "react"
import styled from "styled-components"

export interface PageTitleProps {
  children: string | ReactNode
}
const PageTitleStyled = styled.h2`

`

export const PageTitle = ({children}:PageTitleProps) => <PageTitleStyled >{children}</PageTitleStyled>