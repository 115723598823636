import { DeliveryItemModel, UUIDField, XBaseModel } from ".";
import { DateStrToMoment, MomentToDateStr, ExtractUUID, } from "../decorators";
import moment from 'moment';
import {  Type } from "class-transformer";

export enum DiscrepancyStatus {
  RESOLVED,
  UNRESOLVED,
}

export enum DiscrepancyIssuer {
  ADMIN,
  CUSTOMER,
}

export enum DiscrepancyReason {
  SHORT,
  INCORRECT_ITEM,
  DAMAGED_ITEMS,
  MISSING_ITEM
}

export enum DiscrepancyOrderStatus {
  DELIVERED,
  PARTIALLY_DELIVERED
}

export class DiscrepancyModel extends XBaseModel {
  consumer_uuid!: UUIDField;

  @Type(() => DeliveryItemModel)
  delivery_item!: DeliveryItemModel;

  issue_status!: DiscrepancyStatus;
  issuer!: DiscrepancyIssuer;  
  issue_reason!: DiscrepancyReason;    
  note?: string;  
  email_customer?: boolean;
  email_customer_note?: string;    
  email_customer_name?: string;
  email_customer_email?: string;
}

export class UpdateDiscrepancyModel {
  @Type(() => DeliveryItemModel)
  delivery_item!: DeliveryItemModel;

  issue_status!: DiscrepancyStatus;
  issuer!: DiscrepancyIssuer;  
  issue_reason!: DiscrepancyReason;    
  note?: string;  
  email_customer?: boolean;
  email_customer_note?: string;  
  email_customer_name?: string;
  email_customer_email?: string;
}


export class CreateDiscrepancyModel {

  @ExtractUUID()
  delivery_item!: DeliveryItemModel;

  issue_status!: DiscrepancyStatus;
  issuer!: DiscrepancyIssuer;  
  issue_reason!: DiscrepancyReason;  

  note?: string; 
}