import { ReactNode } from "react";
import styled from "styled-components";

export const LabeledPageFieldStyled = styled.div`
  .field-label {
    font-weight: bold;
    &:after {
      content: ": "
    }
  }
`
export interface ILabeledPageFieldProps {
  label:ReactNode;
  text:ReactNode;
}
export const LabeledPageField = (props:ILabeledPageFieldProps) => {
  return (
    <LabeledPageFieldStyled>
      <span className="field-label">{props.label}</span>
      <span className="field-text">{props.text}</span>
    </LabeledPageFieldStyled>
  )
}