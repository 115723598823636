import { CreateDeliveryModel, CreateOrderScheduleModel, CreateRouteModel, DeliveryModel, DeliveryItemModel, LocationModel, OrderScheduleModel, RouteModel, ShopifyProductVariantModel, UpdateDeliveryModel, UpdateDeliveryItemModel, UpdateLocationModel, UpdateOrderScheduleModel, UpdateRouteModel, UpdateVendorModel, VendorModel, DiscrepancyModel, CreateDiscrepancyModel, UpdateDiscrepancyModel } from "../../models";
import { UpdateOrderScheduleShopifyProductVariantModel } from "../../models/order-schedule-product-variants.model";

export const APIConfigFactory = (apiRoot?:string) => ({
  rootURL: apiRoot ?? '',
  endpoints: {
    auth:  { 
      path: `${apiRoot}/api/auth/login/pub`,
      model: undefined,
      updateModel: undefined,
      createModel: undefined,
    },
    orderSchedules:  { 
      path: `${apiRoot}/api/order-schedules`,
      model: OrderScheduleModel,
      updateModel: UpdateOrderScheduleModel,
      createModel: CreateOrderScheduleModel,
    },
    vendors:  { 
      path: `${apiRoot}/api/vendors`,
      model: VendorModel,
      updateModel: UpdateVendorModel,
      createModel: undefined,
    },
    locations:  { 
      path: `${apiRoot}/api/locations`,
      model: LocationModel,
      updateModel: UpdateLocationModel,
      createModel: undefined,
    },
    routes:  { 
      path: `${apiRoot}/api/routes`,
      model: RouteModel,
      updateModel: UpdateRouteModel,
      createModel: CreateRouteModel,
    },
    deliveryDates:  { 
      path: `${apiRoot}/api/deliveries`,
      model: DeliveryModel,
      updateModel: UpdateDeliveryModel,
      createModel: CreateDeliveryModel,
    },
    deliveryItems:  { 
      path: `${apiRoot}/api/delivery-items`,
      model: DeliveryItemModel,
      updateModel: UpdateDeliveryItemModel,
      createModel: undefined
    },
    discrepancies:  { 
      path: `${apiRoot}/api/discrepancies`,
      model: DiscrepancyModel,
      updateModel: UpdateDiscrepancyModel,
      createModel: CreateDiscrepancyModel
    },        
    variants:  { 
      path: `${apiRoot}/api/variants`,
      model: ShopifyProductVariantModel,
      updateModel: undefined,
      createModel: undefined,
    },
  } as const
})
export type APIConfig = ReturnType<typeof APIConfigFactory>
export type PathKeys = keyof APIConfig["endpoints"];
export type APIModelTypes = OrderScheduleModel| VendorModel| LocationModel| RouteModel| DeliveryModel | DeliveryItemModel | ShopifyProductVariantModel | DiscrepancyModel | undefined;
export type APIUpdateModelTypes = UpdateOrderScheduleModel | UpdateVendorModel | UpdateLocationModel | UpdateRouteModel | UpdateDeliveryModel | UpdateDeliveryItemModel | UpdateOrderScheduleShopifyProductVariantModel | UpdateDiscrepancyModel | undefined
export type APICreateModelTypes = CreateOrderScheduleModel | CreateRouteModel  | CreateDeliveryModel | CreateDiscrepancyModel | undefined