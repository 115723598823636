import { BaseShopifyModel } from ".";

export class ShopifyOrderItemModel extends BaseShopifyModel {
  title!: string;
  vendor!: string;
  grams?: number;
  name?: string;
  product_type?: string;
  price?: number;
  variant_title?: string;
  quantity?: number;
}
