import { APIConfig } from "./api.config";
import { ConsumerModel } from "../../models/consumer.model"

export class AuthService {
  constructor(private config: APIConfig, private token?:string) {
    this.token = token;
    this.config = config;
  }
  public authHeader(): Record<string, string> {
    if (this.token) {
      return { authorization: `Bearer ${this.token}` };
    } else {
      return {};
    }
  }

  public async login(
    id: string,
    password:string,
  ): Promise<{consumer: any}> {
    const requestOptions = {
      body: JSON.stringify({ id, password }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    };

    let me;

    const response = await fetch(this.config.endpoints.auth.path, requestOptions);    
    me = await response.json();

    this.token = me.token;
        
    return me;
  }
}