import { ShopifyCollectionModel, XBaseModel } from ".";

export class VendorModel extends XBaseModel {
  consumer_uuid!: string;
  name!: string;
  auto_notify!: boolean;
  shopify_collection!: ShopifyCollectionModel;
}

export class UpdateVendorModel {
  auto_notify!: boolean;
}
