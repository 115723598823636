import { BaseShopifyModel, ShopifyIDField, ShopifyProductModel } from ".";
import { ShopifyProductImageModel } from "./shopify-product-image.model";

export class ShopifyProductVariantModel extends BaseShopifyModel {
  shopify_product?: ShopifyProductModel;
  image?: ShopifyProductImageModel;
  image_shopify_id?: ShopifyIDField;
  sku?: string;
  barcode?: string;
  price!: number;
  compare_at_price?: number;
  weight!: number;
  weight_unit!: 'g' | 'kg' | 'oz' | 'lb' ;
  fulfillment_service!: 'manual' | string ;
  inventory_quantity?: number;
  inventory_policy!: 'deny' | 'continue' ;
  inventory_management?: 'shopify' | string;
  shopify_inventory_item_id?: number;
  requires_shipping!:boolean;
  taxable!: boolean;
  title!: string;
}