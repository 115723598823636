import { Menu } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  RouteBasePath,
  RouteBasePathKeys,
  RouteBasePaths,
} from "../route-base-paths";
import { UserType } from '../../models';

export interface ILayoutState {
  collapsed: boolean;
  selectedMenuKey: RouteBasePathKeys;
}

const getDefaultMenuKey = (): RouteBasePathKeys | "" => {
  const foundMenuItem = [
    { key: RouteBasePath.Dates, path: "/dates" },
    { key: RouteBasePath.Vendors, path: "/vendors" },
    { key: RouteBasePath.Locations, path: "/locations" },
    { key: RouteBasePath.Routes, path: "/routes" },
    { key: RouteBasePath.Schedules, path: "/schedules" },
    { key: RouteBasePath.Deliveries, path: "/deliveries" },
    { key: RouteBasePath.Discrepancies, path: "/discrepancies" },  
    { key: RouteBasePath.Reports, path: "/reports" },    
  ].find(({ key, path }) => window.location.pathname.startsWith(path));
  return foundMenuItem?.key ?? "";
};

export type LayoutActions =
  | { type: "toggle-collapsed" }
  | { type: "select-menu-item"; payload: { itemKey: RouteBasePathKeys } };
export const useLayout = (appUserType: UserType) => {

  const history = useHistory();
  const [state, setState] = useState<ILayoutState>({
    collapsed: false,
    selectedMenuKey: getDefaultMenuKey() as any,
  });

  const selectMenuItem = (key: RouteBasePathKeys) => {
    const foundRoute = RouteBasePaths[key];
    if (!foundRoute) {
      console.log(`Mapping for ${key} doesnt exist.`);
    }
    setState((p) => ({ ...p, selectedMenuKey: key }));
    history.push(foundRoute.path);
  };

  const dispatch = (action: LayoutActions) => {
    switch (action.type) {
      case "toggle-collapsed":
        return setState((p) => ({ ...p, collapsed: !p.collapsed }));
      case "select-menu-item":
        return selectMenuItem(action.payload.itemKey);
    }
  };

  const routes = useMemo(
    () =>
      Object.keys(RouteBasePath).map((key) => {
        const route = RouteBasePaths[key as RouteBasePathKeys];
        const fuzzyMatch = !route.exact ? "*" : "";
        const childRoutePrefix = `${route.path}${
          !route.resourceId ? "" : `/${route.resourceId}`
        }`;

        const childRoutes = (route?.children ?? []).map((childRoute) => (
          <Route
            key={`${key}-${childRoute.path}`}
            component={() => childRoute.component}
            path={`${childRoutePrefix}/${childRoute.path}`}
            exact={route.exact}
          />
        ));
        
        return (            
              <Route
                key={key}
                path={`${route.path}${fuzzyMatch}`}
                exact={route.exact}
              >
                <Switch>
                  {childRoutes}
                    <Route
                      component={() => route.component}
                      path={`${route.path}${fuzzyMatch}`}
                      exact={route.exact}
                    />
                  </Switch>
              </Route>
        );
      }),
    []
  );

  const menuItems = Object.keys(RouteBasePath).map((val) => {
    const key = val as RouteBasePathKeys;
    const route = RouteBasePaths[key as RouteBasePathKeys];
    return (
      route.allowedForUsers.includes(appUserType) ?
        <Menu.Item key={key} icon={route.icon}>
          <Link to={route.path}>{route.label}</Link>
        </Menu.Item>
      : ""
    );
  });

  useEffect(() => {
    setState((p) => ({
      ...p,
      selectedMenuKey: getDefaultMenuKey() as any,
    }));
  }, []);

  return {
    dispatch,
    state,
    routes,
    menuItems,
  };
};
