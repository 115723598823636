import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InboxOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import styled from "styled-components";
import { TableRowData } from "../utils";

const EditableTableRowControlsStyled = styled.div`
  display: flex;
  justify-content: space-evenly;
  .ant-btn {
    border-color: transparent; 
  }

`
export interface EditableTableRowControlsProps<TRecord> {
  isEditingRecord: (record: TRecord) => boolean;
  saveRecord: (record: TRecord) => void;
  cancelEditRecord: () => void;
  deleteRecord: (record: TRecord) => void;
  startEditRecord: (record: TRecord) => void;
  additionalAction?: (record: TRecord) => void;
  isDeletable?: boolean;
  isEditingARow?:boolean;
}
export function EditableTableRowControls<TRecord>({
  isEditingRecord,
  saveRecord,
  cancelEditRecord,
  deleteRecord,
  startEditRecord,
  isDeletable,
  isEditingARow,
  additionalAction,
}: EditableTableRowControlsProps<TRecord>) {
  return (value: any, record: TableRowData<TRecord>) => {
    const isEditingThisRecord = isEditingRecord(record);
    return (
      <EditableTableRowControlsStyled >
        {isEditingThisRecord ? (
          <>
            <Button
              icon={<SaveOutlined />}
              onClick={() => saveRecord(record)}
            />
            <Button icon={<CloseOutlined />} onClick={cancelEditRecord} />
            {!isDeletable ? (
              <></>
            ) : (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteRecord(record)}
              >
                <Button icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </>
        ) : (
          <>
          { 
            !additionalAction ? (<></>) :(
              <Button 
                icon={<InboxOutlined />}
                onClick={() => additionalAction(record)}
                disabled={isEditingARow}
              />
            )
          }
            <Button
              icon={<EditOutlined />}
              onClick={() => startEditRecord(record)}
              disabled={isEditingARow}
            />
          </>
        )}
      </EditableTableRowControlsStyled>
    );
  };
}
